import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import AdminNavBar from "./AdminNavBar";
import Lottie from "lottie-react";
import { motion } from "framer-motion";
import { Helmet } from "react-helmet";

import loadingAnimation from "../../img/json-animation/Animation-notification.json";

import { TrendingUp } from "lucide-react";
import { Area, AreaChart, CartesianGrid, XAxis } from "recharts";

import {
  Card,
  CardContentDashboard,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "../../components/ui/card";

import {
  ChartConfig,
  ChartContainer,
  ChartTooltip,
  ChartTooltipContent,
} from "../../components/ui/chart";

import {
  FaUserTie,
  FaUsers,
  FaHome,
  FaBuilding,
  FaPaintBrush,
} from "react-icons/fa";

import { MdPendingActions } from "react-icons/md";

const DashboardCard = ({
  title,
  value,
  icon,
  gradient,
  additionalInfo,
  pending = false,
  children,
}) => (
  <motion.div
    whileHover={{ scale: 1.03 }}
    className={`2xl:p-6 p-3 rounded-2xl shadow-xl ${gradient}`}
  >
    <div className="flex items-center justify-between mb-4">
      <div className="text-xl lg:text-2xl 2xl:text-5xl text-white">{icon}</div>
      {pending && (
        <div className=" lg:h-7 lg:w-7 2xl:w-10 2xl:h-10">
          {" "}
          {/* Container for Lottie */}
          <Lottie
            animationData={loadingAnimation}
            loop={true}
            autoplay={true}
            style={{ width: "100%", height: "100%" }}
          />
        </div>
      )}
    </div>
    <h3 className="text-lg  2xl:text-xl  font-semibold text-white mb-2">
      {title}
    </h3>
    <span className=" lg:text-2xl 2xl:text-3xl font-bold text-white">{value}</span>
    {additionalInfo && (
      <span className="text-sm text-white ml-4">{additionalInfo}</span>
    )}
    {children}
  </motion.div>
);

function Dashboard() {
  const navigate = useNavigate();
  const [DashboardData, setDashboardData] = useState(null);
  const [isLoadingLocal, setIsLoadingLocal] = useState(true);

  const { t, i18n } = useTranslation();



  useEffect(() => {
    const fetchPrivateData = async () => {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("ad-t")}`,
        },
      };

      try {
        const { data } = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/admin/dashboard`,
          config
        );
        setDashboardData(data.DashboardInfo);
        setIsLoadingLocal(false);
      } catch (error) {
        localStorage.removeItem("ad-t");
        localStorage.removeItem("ad-n");
        navigate("/admin/login"); // Redirect to login on error
      }
    };

    fetchPrivateData();
  }, []); // Removed unnecessary dependencies

  const description = "A stacked area chart";

  const chartData = [
    { month: "January", desktop: 186, mobile: 80 },
    { month: "February", desktop: 305, mobile: 200 },
    { month: "March", desktop: 237, mobile: 120 },
    { month: "April", desktop: 73, mobile: 190 },
    { month: "May", desktop: 209, mobile: 130 },
    { month: "June", desktop: 214, mobile: 140 },
  ];

  const chartConfig = {
    desktop: {
      label: "Desktop",
      color: "hsl(var(--chart-1))",
    },
    mobile: {
      label: "Mobile",
      color: "hsl(var(--chart-2))",
    },
  };

  // new user
  const chartDataNewUser = [
    { month: "January", desktop: 186 },
    { month: "February", desktop: 305 },
    { month: "March", desktop: 237 },
    { month: "April", desktop: 73 },
    { month: "May", desktop: 209 },
    { month: "June", desktop: 214 },
  ];

  //new user
  const chartConfigNewUser = {
    desktop: {
      label: "Accounts",
      color: "hsl(var(--chart-1))",
    },
  };

  return (
    <div
      className={`MainBody w-full min-h-screen rounded-[20px] duration-200 `}
    >
      <Helmet>
        <title>{t("Dashboard - Admin Panel")}</title> {/* Dynamic title */}
      </Helmet>

      <div className="md:flex md:my-5 my-1 ">
        <AdminNavBar activePage="Dashboard" />

        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.5 }}
          className="w-full"
        >
          <div className="">
            {isLoadingLocal ? (
              <div className="flex justify-center items-center h-64">
                <motion.div
                  animate={{ rotate: 360 }}
                  transition={{
                    duration: 1,
                    repeat: Infinity,
                    ease: "linear",
                  }}
                  className="w-16 h-16 border-t-4 border-blue-500 border-solid rounded-full"
                />
              </div>
            ) : (
              <motion.div
                initial={{ opacity: 0, y: -20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5 }}
                className="p-8 bg-gray-100"
              >
                <h1 className="text-3xl font-bold mb-8 text-gray-800">
                  Dashboard Overview
                </h1>

                {/* <div className=" w-full mb-5 grid grid-cols-1 md:grid-cols-3 gap-8">

                  <Card className="  w-full rounded-3xl ">
                    <CardHeader>
                      <CardTitle>Website Traffic</CardTitle>
                      <CardDescription>
                        Showing total visitors for the last 6 months
                      </CardDescription>
                    </CardHeader>
                    <CardContentDashboard>
                      <ChartContainer config={chartConfig}>
                        <AreaChart
                          accessibilityLayer
                          data={chartData}
                          margin={{
                            left: 12,
                            right: 12,
                          }}
                        >
                          <CartesianGrid vertical={false} />
                          <XAxis
                            dataKey="month"
                            tickLine={false}
                            axisLine={false}
                            tickMargin={8}
                            tickFormatter={(value) => value.slice(0, 3)}
                          />
                          <ChartTooltip
                            cursor={false}
                            content={<ChartTooltipContent indicator="dot" />}
                          />
                          <Area
                            dataKey="mobile"
                            type="natural"
                            fill="var(--color-mobile)"
                            fillOpacity={0.4}
                            stroke="var(--color-mobile)"
                            stackId="a"
                          />
                          <Area
                            dataKey="desktop"
                            type="natural"
                            fill="var(--color-desktop)"
                            fillOpacity={0.4}
                            stroke="var(--color-desktop)"
                            stackId="a"
                          />
                        </AreaChart>
                      </ChartContainer>
                    </CardContentDashboard>
                    <CardFooter>
                      <div className="flex w-full items-start gap-2 text-sm">
                        <div className="grid gap-2">
                          <div className="flex items-center gap-2 font-medium leading-none">
                            Trending up by 5.2% this month{" "}
                            <TrendingUp className="h-4 w-4" />
                          </div>
                          <div className="flex items-center gap-2 leading-none text-muted-foreground">
                            January - June 2024
                          </div>
                        </div>
                      </div>
                    </CardFooter>
                  </Card>


                  <Card className="  w-full  rounded-3xl ">
                    <CardHeader>
                      <CardTitle>Sell & Rent Posts</CardTitle>
                      <CardDescription>
                        Showing total Sell and Rent Posts for the last 6 months
                      </CardDescription>
                    </CardHeader>
                    <CardContentDashboard>
                      <ChartContainer config={chartConfig}>
                        <AreaChart
                          accessibilityLayer
                          data={chartData}
                          margin={{
                            left: 12,
                            right: 12,
                          }}
                        >
                          <CartesianGrid vertical={false} />
                          <XAxis
                            dataKey="month"
                            tickLine={false}
                            axisLine={false}
                            tickMargin={8}
                            tickFormatter={(value) => value.slice(0, 3)}
                          />
                          <ChartTooltip
                            cursor={false}
                            content={<ChartTooltipContent indicator="dot" />}
                          />
                          <Area
                            dataKey="mobile"
                            type="natural"
                            fill="var(--color-mobile)"
                            fillOpacity={0.4}
                            stroke="var(--color-mobile)"
                            stackId="a"
                          />
                          <Area
                            dataKey="desktop"
                            type="natural"
                            fill="var(--color-desktop)"
                            fillOpacity={0.4}
                            stroke="var(--color-desktop)"
                            stackId="a"
                          />
                        </AreaChart>
                      </ChartContainer>
                    </CardContentDashboard>
                    <CardFooter>
                      <div className="flex w-full items-start gap-2 text-sm">
                        <div className="grid gap-2">
                          <div className="flex items-center gap-2 font-medium leading-none">
                            Trending up by 5.2% this month{" "}
                            <TrendingUp className="h-4 w-4" />
                          </div>
                          <div className="flex items-center gap-2 leading-none text-muted-foreground">
                            January - June 2024
                          </div>
                        </div>
                      </div>
                    </CardFooter>
                  </Card>


                  <Card className="  w-full  rounded-3xl ">
                    <CardHeader>
                      <CardTitle>New User's Joining State</CardTitle>
                      <CardDescription>
                        Showing New User's for the last 6 months
                      </CardDescription>
                    </CardHeader>
                    <CardContentDashboard>
                      <ChartContainer config={chartConfigNewUser}>
                        <AreaChart
                          accessibilityLayer
                          data={chartDataNewUser}
                          margin={{
                            left: 12,
                            right: 12,
                          }}
                        >
                          <CartesianGrid vertical={false} />
                          <XAxis
                            dataKey="month"
                            tickLine={false}
                            axisLine={false}
                            tickMargin={8}
                            tickFormatter={(value) => value.slice(0, 3)}
                          />
                          <ChartTooltip
                            cursor={false}
                            content={<ChartTooltipContent indicator="dot" />}
                          />
                          <Area
                            dataKey="mobile"
                            type="natural"
                            fill="var(--color-mobile)"
                            fillOpacity={0.4}
                            stroke="var(--color-mobile)"
                            stackId="a"
                          />
                          <Area
                            dataKey="desktop"
                            type="natural"
                            fill="var(--color-desktop)"
                            fillOpacity={0.4}
                            stroke="var(--color-desktop)"
                            stackId="a"
                          />
                        </AreaChart>
                      </ChartContainer>
                    </CardContentDashboard>
                    <CardFooter>
                      <div className="flex w-full items-start gap-2 text-sm">
                        <div className="grid gap-2">
                          <div className="flex items-center gap-2 font-medium leading-none">
                            Trending up by 5.2% this month{" "}
                            <TrendingUp className="h-4 w-4" />
                          </div>
                          <div className="flex items-center gap-2 leading-none text-muted-foreground">
                            January - June 2024
                          </div>
                        </div>
                      </div>
                    </CardFooter>
                  </Card>
                </div> */}

                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6">
                  <Link to="/admin/admininfo">
                    <DashboardCard
                      title="Total Admins"
                      value={DashboardData.TotalAdmin}
                      icon={<FaUserTie />}
                      gradient="bg-gradient-to-br from-purple-600 to-blue-500"
                    />
                  </Link>
                  <Link to="/admin/usermanage">
                    <DashboardCard
                      title="Total Users"
                      value={DashboardData.TotalUser}
                      icon={<FaUsers />}
                      gradient="bg-gradient-to-br from-pink-500 to-orange-400"
                    />
                  </Link>
                  <Link to="/admin/sellpostmanage">
                    <DashboardCard
                      title="Total Sell Posts"
                      value={DashboardData.TotalSellPost}
                      icon={<FaHome />}
                      gradient="bg-gradient-to-br from-green-500 to-teal-400"
                    />
                  </Link>
                  <Link to="/admin/rentpostmanage">
                    <DashboardCard
                      title="Total Rent Posts"
                      value={DashboardData.TotalRentPost}
                      icon={<FaBuilding />}
                      gradient="bg-gradient-to-br from-blue-500 to-indigo-500"
                    />
                  </Link>

                  <Link to="/admin/sellpostmanage">
                    <DashboardCard
                      title="Pending Sell Posts"
                      value={DashboardData.TotalPendingSellPost}
                      icon={<MdPendingActions />}
                      gradient="bg-gradient-to-br from-red-500 to-purple-500"
                      pending={
                        DashboardData.TotalPendingSellPost > 0 ? true : false
                      }
                    />
                  </Link>

                  <Link to="/admin/rentpostmanage">
                    <DashboardCard
                      title="Pending Rent Posts"
                      value={DashboardData.TotalPendingRentPost}
                      icon={<MdPendingActions />}
                      gradient="bg-gradient-to-br from-yellow-400 to-orange-500"
                      pending={
                        DashboardData.TotalPendingRentPost > 0 ? true : false
                      }
                    />
                  </Link>

                  <Link to="/admin/Group-Building-Manage">
                    <DashboardCard
                      title="Group Building Posts"
                      value={DashboardData.TotalGroupBuildingPost}
                      icon={<FaBuilding />}
                      gradient="bg-gradient-to-br from-indigo-500 to-purple-500"
                    />
                  </Link>
                  <Link to="/admin/interior">
                    <DashboardCard
                      title="Total Interior Posts"
                      value={DashboardData.TotalInteriorPost}
                      icon={<FaPaintBrush />}
                      gradient="bg-gradient-to-br from-teal-400 to-green-500"
                      additionalInfo={`Enabled: ${DashboardData.TotalEnabledInteriorPost} | Disabled: ${DashboardData.TotalDisabledInteriorPost}`}
                    ></DashboardCard>
                  </Link>
                </div>
              </motion.div>
            )}
          </div>
        </motion.div>
      </div>
    </div>
  );
}

export default Dashboard;
