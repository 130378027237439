import React from "react";
import cc from "../img/customer-service.png";
import { Helmet } from "react-helmet";
const CustomerCare = () => {
  return (
    <div className="MainSection h-[72vh]  w-screen  flex justify-center text-center">
      	<Helmet>
        <title>Customer Care - Loko Property</title>
      </Helmet>
      <div className="innerMainSection md:mt-20  h-64 w-3/5  ">
        <img src={cc} className=" h-32   m-auto block" alt="dd" />
        <p className=" text-2xl ">Contact Us</p>
        <p className=" mt-3 text-xl font-TiroBangla">
          হয়া  ওদাজ দাহদ আজ দ
        </p>

        <div className="border-b-2 border-sky-600 my-4"></div>

        <p className="text-2xl f">You can call or email us</p>
        <p className="text-2xl f">Every day from 09:00 AM to 08:00 PM</p>



      </div>
    </div>
  );
};
export default CustomerCare;
