import React from "react";
import { Link, useLocation } from "react-router-dom";
import { motion } from "framer-motion";
import { PlusSquareIcon, FileText } from "lucide-react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";

import bg from "../../img/fastSellRent.jpg";
import info from "../../img/info.png";
import hdupload from "../../img/hdupload.png";

function FastSellRent() {
  const location = useLocation();
  const source = location.state?.source;

  const { t } = useTranslation();

  // Determine the target route based on source
  const targetRoute = source === "rent" ? "/rentpost" : "/sellpost";

  return (
    <div className="min-h-[100vh] w-[98%] md:w-[90%] xl:w-[85%] mx-auto py-8">
      <Helmet>
        <title>{t("fast_sell_rent.title")} - Loko Property</title>
      </Helmet>

      {/* Hero Section with Image Overlay */}
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        className="relative h-[500px] mb-12 rounded-xl overflow-hidden"
      >
        {/* Background Image */}
        <img className="w-full h-full object-cover" src={bg} alt="Background" />

        {/* Dark Overlay */}
        <div className="absolute inset-0 bg-black/50" />

        {/* Text Content */}
        <div className="absolute inset-0 flex flex-col items-center justify-center text-center p-4">
          <h1 className="text-3xl md:text-5xl font-bold text-white mb-4 max-w-3xl">
            {t("fast_sell_rent.heading_1")}
          </h1>
          <p className="text-lg md:text-xl text-white/90 max-w-2xl">
            {t("fast_sell_rent.sub_heading_1")}
          </p>
        </div>
      </motion.div>

      {/* Cards Section */}
      <div className="  mb-2">
        {/* Information Card */}
        <motion.div
          initial={{ opacity: 0, x: -20 }}
          animate={{ opacity: 1, x: 0 }}
          className="p-6  mb-2 "
        >
          <div className="flex flex-col md:flex-row gap-6 items-center">
            <div className="w-full md:w-1/2 order-2 md:order-1">
              <div className="flex items-center mb-4">
                <FileText className="w-8 h-8 text-blue-600 mr-3 flex-shrink-0" />
                <h2 className="text-xl md:text-2xl font-semibold text-gray-800">
                  {t("fast_sell_rent.heading_2")}
                </h2>
              </div>
              <p className="text-gray-600 leading-relaxed">
                {t("fast_sell_rent.sub_heading_2")}
              </p>
            </div>
            <div className="w-full md:w-1/2 order-1 md:order-2 flex justify-center">
              <img
                src={info}
                alt="info"
                className="w-full max-w-[450px] h-auto object-contain"
              />
            </div>
          </div>
        </motion.div>

        {/* Photos Card */}

        <motion.div
          initial={{ opacity: 0, x: 20 }}
          animate={{ opacity: 1, x: 0 }}
          className="p-6  mb-2 "
        >
          <div className="flex flex-col md:flex-row gap-6  items-center">
            <div className="w-full md:w-1/2 order-1 md:order-1 flex justify-center">
              <img
                src={hdupload}
                alt="info"
                className="w-full max-w-[450px] h-auto object-contain"
              />
            </div>

            <div className="w-full md:w-1/2 order-2 md:order-2">
              <div className="flex items-center mb-4">
                <FileText className="w-8 h-8 text-blue-600 mr-3 flex-shrink-0" />
                <h2 className="text-xl md:text-2xl font-semibold text-gray-800">
                  {t("fast_sell_rent.heading_3")}
                </h2>
              </div>
              <p className="text-gray-600 leading-relaxed">
                {t("fast_sell_rent.sub_heading_3")}
              </p>
            </div>
          </div>
        </motion.div>
      </div>

      {/* Call to Action */}
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        className="mt-12 text-center"
      >
        <Link
          to={targetRoute}
          className="inline-flex items-center px-6 py-3 bg-orange-300 hover:bg-orange-400 text-black font-semibold rounded-lg  transition-colors"
        >
          <PlusSquareIcon className="w-5 h-5 mr-2" />
          {source === "rent" ? "Add Rent Post" : " Add Sell Post"}
        </Link>
      </motion.div>
    </div>
  );
}

export default FastSellRent;
