import { React, useRef, useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import locationData from "../../../../Location.json";
import { useParams } from "react-router-dom";
import axios from "axios";
import { Helmet } from "react-helmet";

import { useUser } from "../../../../context/UserContext";
import { CgSpinner } from "react-icons/cg";
import { useTranslation } from "react-i18next";
import { toast } from "react-hot-toast";
import PostLoading from "../../../../components/LocalUi/PostLoading";
import { Switch } from "../../../../components/ui/switch";
//icon
import premium from "../../../../img/icn/premium.png";
import locations from "../../../../img/icn/location.png";
import call from "../../../../img/icn/call.png";

const InteriorAddPost = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const token = localStorage.getItem("ad-t");

  ///Language
  const { t, i18n } = useTranslation();




  // fetch Initial old data
  const [interiorPost, setInteriorPost] = useState(null);
  useEffect(() => {
    const fetchInteriorPostDetails = async () => {
      try {
        const apiUrl = `${process.env.REACT_APP_API_URL}/api/InteriorPostDetails/${id}`;
        const response = await axios.get(apiUrl);
        setInteriorPost(response.data.interiorPost);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchInteriorPostDetails();
  }, [id]);

  //set data from form
  const [loading, setLoading] = useState(false);

  // const [companyName, setCompanyName] = useState("");
  const [isPremium, setIsPremium] = useState("");
  const [companySlogan, setCompanySlogan] = useState("");
  const [postDetails, setPostDetails] = useState("");
  const [number, setNumber] = useState("");
  const [moreNumber, setMoreNumber] = useState("");
  const [coverPic, setCoverPic] = useState(null);

  const [divisionId, setDivisionId] = useState("");
  const [district, setDistrict] = useState([]);
  const [subDistrict, setSubDistrict] = useState([]);
  const [districtId, setDistrictId] = useState("");
  const [subDistrictId, setSubDistrictId] = useState("");

  useEffect(() => {
    if (interiorPost) {
      setIsPremium(interiorPost.isPremium);
      setCompanySlogan(interiorPost.companySlogan);
      setPostDetails(interiorPost.postDetails);
      setNumber(interiorPost.number);
      setMoreNumber(interiorPost.moreNumber);
      setDivisionId(interiorPost.division);
      setDistrictId(interiorPost.district);
      setSubDistrictId(interiorPost.subDistrict);

    }
  }, [interiorPost]);

  const handleDivision = (e) => {
    const getDivisionId = e.target.value;
    // Check if "Select Division" is selected
    if (getDivisionId === "") {
      // Reset the district state
      setDistrict([]);
    } else {
      // Find the selected division and set the district data
      const getDistrictData = locationData.find(
        (division) => division.division_id === getDivisionId
      ).district;
      setDistrict(getDistrictData);
    }
    // Set the divisionId
    setDivisionId(getDivisionId);
  };

  //Modify the handleDistrict function to set sub-districts
  const handleDistrict = (e) => {
    const districtId = e.target.value;
    setDistrictId(districtId);

    // Find the selected district in the locationData
    const selectedDistrict = locationData
      .find((division) => division.division_id === divisionId)
      .district.find((district) => district.district_id === districtId);

    // Extract and set the sub-districts for the selected district
    if (selectedDistrict && selectedDistrict.sub_district) {
      setSubDistrict(selectedDistrict.sub_district);
    } else {
      // If there are no sub-districts, reset the subDistrict state
      setSubDistrict([]);
    }
  };

  // Define the handleSubDistrict function to set the sub-district ID
  const handleSubDistrict = (e) => {
    const subDistrictId = e.target.value;
    setSubDistrictId(subDistrictId);
  };

  const [selections, setSelections] = useState({
    interior: false,
    exterior: false,
    furniture: false,
    home: false,
    duplexHouse: false,
    office: false,
    villa: false,
    restaurant: false,
    cafe: false,
    hotel: false,
    hospital: false,
    bank: false,
    showroom: false,
    gym: false,
    dentalClinic: false,
  });

  const [images, setImages] = useState({
    interior: null,
    exterior: null,
    furniture: null,
    home: null,
    duplexHouse: null,
    office: null,
    villa: null,
    restaurant: null,
    cafe: null,
    hotel: null,
    hospital: null,
    bank: null,
    showroom: null,
    gym: null,
    dentalClinic: null,
  });

  // Modified useEffect to properly handle server images
  useEffect(() => {
    if (interiorPost) {
      setSelections({
        ...selections,
        ...interiorPost.selections,
      });

      // Create a new object to store formatted image data
      const formattedImages = {};

      // Iterate through each image key in interiorPost.images
      Object.keys(interiorPost.images || {}).forEach((key) => {
        if (interiorPost.images[key]) {
          formattedImages[key] = {
            url: interiorPost.images[key], // Store the server URL
            preview: interiorPost.images[key], // Use server URL as preview
            file: null, // No file initially
          };
        }
      });

      setImages(formattedImages);
    }
  }, [interiorPost]);

  const [coverPreview, setCoverPreview] = useState(null);

  // Modified setCoverPic handler to include preview
  // Resize and create preview for cover image
  const handleCoverPicChange = async (e) => {
    const file = e.target.files[0];
    if (file) {
      try {
        const resizedBlob = await resizeImage(file);
        setCoverPic(resizedBlob);

        // Create preview from resized image
        const reader = new FileReader();
        reader.onloadend = () => {
          setCoverPreview(reader.result);
        };
        reader.readAsDataURL(resizedBlob);
      } catch (error) {
        console.error("Error resizing cover image:", error);
        toast.error("Error processing cover image");
      }
    }
  };

  // Modified handleImageChange to preserve the structure
  const handleImageChange = async (e) => {
    const { name, files } = e.target;
    if (files && files[0]) {
      try {
        const file = files[0];
        const resizedBlob = await resizeImage(file);

        const reader = new FileReader();
        reader.onloadend = () => {
          setImages((prevImages) => ({
            ...prevImages,
            [name]: {
              file: resizedBlob,
              preview: reader.result,
              url: null, // Clear the server URL when new file is selected
            },
          }));
        };
        reader.readAsDataURL(resizedBlob);
      } catch (error) {
        console.error(`Error processing ${name} image:`, error);
        toast.error(`Error processing ${name} image`);
      }
    }
  };

  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;
    setSelections({
      ...selections,
      [name]: checked,
    });
  };

  const [showConfirmation, setShowConfirmation] = useState(false);

  const handleModalSubmit = (e) => {
    e.preventDefault();
    setShowConfirmation(true);
  };
  const handleContinue = () => {
    setShowConfirmation(false);
    handleSubmit();
  };
  const handleCancel = () => {
    setShowConfirmation(false);
  };

  // Universal function to resize an image
  const resizeImage = async (imageFile, maxWidth = 1080, maxHeight = 1920) => {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.src = URL.createObjectURL(imageFile);
      img.onload = () => {
        let width = img.width;
        let height = img.height;

        if (width > maxWidth || height > maxHeight) {
          if (width / maxWidth > height / maxHeight) {
            height *= maxWidth / width;
            width = maxWidth;
          } else {
            width *= maxHeight / height;
            height = maxHeight;
          }

          const canvas = document.createElement("canvas");
          canvas.width = width;
          canvas.height = height;
          const ctx = canvas.getContext("2d");
          ctx.drawImage(img, 0, 0, width, height);

          canvas.toBlob((blob) => {
            resolve(blob);
          }, imageFile.type);
        } else {
          resolve(imageFile);
        }
      };

      img.onerror = (error) => {
        reject(error);
      };
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const formData = new FormData();
      formData.append("isPremium", isPremium);
      formData.append("companySlogan", companySlogan);
      formData.append("postDetails", postDetails);
      formData.append("number", number);
      formData.append("moreNumber", moreNumber);
      formData.append("division", divisionId);
      formData.append("district", districtId);
      formData.append("subDistrict", subDistrictId);

      // Add cover pic if it exists (already resized)
      if (coverPic) {
        formData.append("coverPic", coverPic, "cover.jpg");
      }

      // Append selections
      formData.append("selections", JSON.stringify(selections));

      // Append resized service images
      for (const key of Object.keys(images)) {
        if (images[key]?.file instanceof Blob) {
          formData.append(key, images[key].file, `${key}.jpg`);
        }
      }

      const response = await axios.put(
        `${process.env.REACT_APP_API_URL}/api/admin/UpdateInteriorPostAd/${id}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status !== 200) {
        toast.error(response.data.error);
      } else {
        toast.success("Post updated successfully");
        navigate(-1);
      }
    } catch (error) {
      console.error("Failed to update post:", error);
      toast.error("Failed to update post");
    } finally {
      setLoading(false);
    }
  };

  if (!interiorPost) {
    return (
      <div className="w-full h-[90vh] flex justify-center">
        <div className="w-[70%]">
          <PostLoading />
        </div>
      </div>
    );
  }

  return (
    <div
      className={`  w-full flex justify-center items-center duration-200 `}
    >
      <Helmet>
        <title>Interior Edit Post - Admin Panel</title>
      </Helmet>
      <div className="h-full w-full 2xl:max-w-[85%] xl:max-w-[90%] max-w-[99%] lg:p-0 p-2  duration-200 ">
        <div className="  ">
          <p></p>
        </div>

        <form
          onSubmit={handleModalSubmit}
          encType="multipart/form-data "
          className=" mt-5 "
        >
          <div className="  lg:flex justify-center md:space-y-0 space-y-5 lg:space-x-10 ">
            {/* Left Side*/}
            <div className="leftSide  lg:w-[50%]  mb-5  ">
              {/* Company Name*/}
              <div className=" ">
                <p className=" text-xl">Interior Company Name</p>

                <div className=" border-2 border-gray-400 rounded-xl bg-gray-200 p-2">
                  {" "}
                  <p> {interiorPost.companyName}</p>
                </div>
              </div>

              {/* Company CompanySlogan*/}
              <div className=" ">
                <p className=" text-xl">Company Slogan</p>
                <textarea
                  className=" border-2 border-gray-300 rounded-xl p-2 lg:w-[100%]  w-[330px]"
                  type="text"
                  rows="1"
                  required
                  defaultValue={interiorPost.companySlogan}
                  placeholder="Company Tagline/Slogan (Optional)"
                  onChange={(e) => setCompanySlogan(e.target.value)}
                ></textarea>
              </div>

              {/* Description */}
              <div className="my-3">
                <p className=" text-xl">Description </p>

                <textarea
                  className=" border-2 border-gray-300 rounded-xl p-2 lg:w-[100%] w-[330px]"
                  type="text"
                  rows="8"
                  required
                  defaultValue={interiorPost.postDetails}
                  placeholder="less than 500 characters"
                  onChange={(e) => setPostDetails(e.target.value)}
                ></textarea>
              </div>

              {/* contact number*/}
              <div className="mt-4  ">
                <div className="mt-2">
                  <p className="inline mr-2">Contact Number </p>
                  <input
                    required
                    className="border-2 h-8 w-[165px] rounded-lg border-gray-300 px-1 pl-2"
                    placeholder="1xxxxxxxx"
                    type="number"
                    defaultValue={interiorPost.number}
                    onChange={(e) => setNumber(e.target.value)}
                  ></input>
                </div>
              </div>

              {/* contact number secondery*/}
              <div className="mt-4  ">
                <div className="mt-2">
                  <p className="inline mr-2">More Contact Number </p>
                  <input
                    className="border-2 h-8 w-[165px] rounded-lg border-gray-300 px-1 pl-2"
                    placeholder="1xxxxxxxx"
                    type="number"
                    defaultValue={interiorPost.moreNumber}
                    onChange={(e) => setMoreNumber(e.target.value)}
                  ></input>
                </div>
              </div>

              {/*Cover photo  Image upload*/}
              <div className="mt-2 mr-2">
                <div className="text-xl mb-3">Cover Picture</div>
                <div className="mb-3">
                  <img
                    src={coverPreview || interiorPost.coverPhoto}
                    className="h-[10rem] w-[15rem] object-cover rounded-lg"
                    alt="Cover preview"
                  />
                </div>
                <p> Choose new cover image (Optional) </p>
                <input
                  id="coverImage"
                  type="file"
                  className="mt-1 block w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 cursor-pointer focus:outline-none"
                  accept=".jpg, .jpeg, .png"
                  onChange={handleCoverPicChange}
                />
              </div>

              {/* Location*/}
              <div className=" mt-5">
                <p>
                  Previous Location: {interiorPost.subDistrict},{" "}
                  {interiorPost.district}, {interiorPost.division}
                </p>

                <p>
                  Select Location:
                  <span className="text-red-500 ml-1">*</span>
                </p>
                <div className="flex lg:space-x-5 mt-2">
                  <div className="relative h-12 lg:w-40 w-32">
                    <select
                      name="Division"
                      className="peer h-full w-full rounded-[7px] border-2 border-blue-gray-600 border-t-transparent bg-transparent px-1 text-[15px] text-blue-gray-700 outline outline-0 transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200 empty:!bg-red-500 focus:border-2 focus:border-pink-500 focus:border-t-transparent focus:outline-0 disabled:border-0 disabled:bg-blue-gray-50"
                      onChange={(e) => handleDivision(e)}
                    >
                      <option value="" className="text-pink-600">
                        -Select Division-
                      </option>
                      {locationData.map((getDivision, index) => (
                        <option value={getDivision.division_id} key={index}>
                          {getDivision.division_name}
                        </option>
                      ))}
                    </select>
                    <label className="before:content[' '] after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none text-[12px] font-normal leading-tight text-blue-gray-400 transition-all before:pointer-events-none before:mt-[6.5px] before:mr-1 before:box-border before:block before:h-1.5 before:w-2.5 before:rounded-tl-md before:border-t before:border-l before:border-blue-gray-200 before:transition-all after:pointer-events-none after:mt-[6.5px] after:ml-1 after:box-border after:block after:h-1.5 after:w-2.5 after:flex-grow after:rounded-tr-md after:border-t after:border-r after:border-blue-gray-200 after:transition-all peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[3.75] peer-placeholder-shown:text-blue-gray-500 peer-placeholder-shown:before:border-transparent peer-placeholder-shown:after:border-transparent peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-pink-500 peer-focus:before:border-t-2 peer-focus:before:border-l-2 peer-focus:before:border-pink-500 peer-focus:after:border-t-2 peer-focus:after:border-r-2 peer-focus:after:border-pink-500 peer-disabled:text-transparent peer-disabled:before:border-transparent peer-disabled:after:border-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500">
                      Division
                    </label>
                  </div>

                  {district.length > 0 && (
                    <div className="relative h-12 w-40">
                      <select
                        name="states"
                        className="peer h-full w-full rounded-[7px] border-2 border-blue-gray-600 border-t-transparent bg-transparent px-1 text-[15px] text-blue-gray-700 outline outline-0 transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200 empty:!bg-red-500 focus:border-2 focus:border-pink-500 focus:border-t-transparent focus:outline-0 disabled:border-0 disabled:bg-blue-gray-50"
                        onChange={(e) => handleDistrict(e)}
                      >
                        <option value="" className="text-pink-600">
                          -Select District-
                        </option>
                        {district.map((getDistrict, index) => (
                          <option value={getDistrict.district_id} key={index}>
                            {getDistrict.district_name}
                          </option>
                        ))}
                      </select>

                      <label className="before:content[' '] after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none text-[12px] font-normal leading-tight text-blue-gray-400 transition-all before:pointer-events-none before:mt-[6.5px] before:mr-1 before:box-border before:block before:h-1.5 before:w-2.5 before:rounded-tl-md before:border-t before:border-l before:border-blue-gray-200 before:transition-all after:pointer-events-none after:mt-[6.5px] after:ml-1 after:box-border after:block after:h-1.5 after:w-2.5 after:flex-grow after:rounded-tr-md after:border-t after:border-r after:border-blue-gray-200 after:transition-all peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[3.75] peer-placeholder-shown:text-blue-gray-500 peer-placeholder-shown:before:border-transparent peer-placeholder-shown:after:border-transparent peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-pink-500 peer-focus:before:border-t-2 peer-focus:before:border-l-2 peer-focus:before:border-pink-500 peer-focus:after:border-t-2 peer-focus:after:border-r-2 peer-focus:after:border-pink-500 peer-disabled:text-transparent peer-disabled:before:border-transparent peer-disabled:after:border-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500">
                        District
                      </label>
                    </div>
                  )}

                  {subDistrict.length > 0 && (
                    <div className="relative h-12 w-40">
                      <select
                        name="subDistrict"
                        className="peer h-full w-full rounded-[7px] border-2 border-blue-gray-600 border-t-transparent bg-transparent px-1 text-[15px] text-blue-gray-700 outline outline-0 transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200 empty:!bg-red-500 focus:border-2 focus:border-pink-500 focus:border-t-transparent focus:outline-0 disabled:border-0 disabled:bg-blue-gray-50"
                        onChange={(e) => handleSubDistrict(e)}
                      >
                        <option value="" className="text-pink-600">
                          -Select Sub-District-
                        </option>
                        {subDistrict.map((getSubDistrict, index) => (
                          <option
                            value={getSubDistrict.sub_district_id}
                            key={index}
                          >
                            {getSubDistrict.sub_district_name}
                          </option>
                        ))}
                      </select>
                      <label className="before:content[' '] after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none text-[12px] font-normal leading-tight text-blue-gray-400 transition-all before:pointer-events-none before:mt-[6.5px] before:mr-1 before:box-border before:block before:h-1.5 before:w-2.5 before:rounded-tl-md before:border-t before:border-l before:border-blue-gray-200 before:transition-all after:pointer-events-none after:mt-[6.5px] after:ml-1 after:box-border after:block after:h-1.5 after:w-2.5 after:flex-grow after:rounded-tr-md after:border-t after:border-r after:border-blue-gray-200 after:transition-all peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[3.75] peer-placeholder-shown:text-blue-gray-500 peer-placeholder-shown:before:border-transparent peer-placeholder-shown:after:border-transparent peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-pink-500 peer-focus:before:border-t-2 peer-focus:before:border-l-2 peer-focus:before:border-pink-500 peer-focus:after:border-t-2 peer-focus:after:border-r-2 peer-focus:after:border-pink-500 peer-disabled:text-transparent peer-disabled:before:border-transparent peer-disabled:after:border-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500">
                        Sub-District
                      </label>
                    </div>
                  )}
                </div>
              </div>

              <div className=" mt-5 flex items-center space-x-2">
                <Switch
                  id="premium"
                  checked={isPremium}
                  onCheckedChange={setIsPremium}
                />
                <img src={premium} alt="premium" className="w-6 h-6" />
                <p className=" font-semibold text-gray-600"> Premium </p>
              </div>
            </div>

            {/* Right Side*/}
            <div className="rightSide  lg:w-[50%]  ">
              <p className="text-xl">Property Specifications</p>

              <div className="mt-4">
                {Object.keys(selections).map((key) => (
                  <div
                    className="border rounded-md mb-4 p-4 duration-200"
                    key={key}
                  >
                    <div className="flex items-center mb-2">
                      <input
                        className="mr-2"
                        type="checkbox"
                        name={key}
                        checked={selections[key]}
                        onChange={handleCheckboxChange}
                      />
                      <label>
                        {key.charAt(0).toUpperCase() + key.slice(1)}
                      </label>
                    </div>

                    {selections[key] && (
                      <div className="mt-2">
                        <div className="mb-2">
                          {(images[key]?.preview || images[key]?.url) && (
                            <div>
                              <p className="text-sm text-gray-600 mb-1">
                                Current Image:
                              </p>
                              <img
                                src={images[key]?.preview || images[key]?.url}
                                alt={`${key} preview`}
                                className="h-[10rem] w-[15rem] object-cover rounded-lg"
                              />
                            </div>
                          )}
                        </div>
                        <input
                          type="file"
                          name={key}
                          accept=".jpg, .jpeg, .png"
                          onChange={handleImageChange}
                          className="w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 cursor-pointer focus:outline-none"
                        />
                      </div>
                    )}
                  </div>
                ))}
              </div>
            </div>
          </div>

          <button
            type="submit"
            className="w-[250px] m-auto lg:mt-3 mt-5 flex gap-1 items-center justify-center py-2.5 text-white rounded-xl bg-[#F59E0B] hover:bg-[#fd7200] duration-200"
          >
            {loading && <CgSpinner size={20} className="mt-1 animate-spin" />}
            <span className="text-md">Submit</span>
          </button>
        </form>
      </div>

      {/* Confirmation Overlay Modal*/}

      {showConfirmation && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white m-4 h-[300px] lg:h-[350px] lg:w-[500px] p-6 rounded-2xl  relative">
            <h3 className="text-2xl  mb-4 text-green-500 font-bold">
              Confirmation
            </h3>

            <div className=" text-justify ">
              <p className="mt-6">
                {t("group_wise_building.confirmation_message")}
              </p>
              <p className="mt-6 text-right">
                {" "}
                {t("group_wise_building.confirmation_message_thank_you")}
              </p>
              <p className="mt-6 text-right">
                {" "}
                {t("group_wise_building.confirmation_message_end")}
              </p>
            </div>

            <div className="mt-4 flex absolute bottom-0 right-0 p-5">
              <button
                className="mr-2 px-4 py-2 bg-red-300 hover:bg-red-400 rounded-md duration-200"
                onClick={handleCancel}
              >
                Cancel
              </button>

              <button
                className=" px-4 py-2 bg-green-500 hover:bg-green-600 text-white rounded-md duration-200"
                onClick={handleSubmit}
              >
                Continue
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default InteriorAddPost;
