import React from "react";
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import AdminNavBar from "../admin/AdminNavBar";
import { Helmet } from "react-helmet";

function Notifications() {
  const navigate = useNavigate();

  const [error, setError] = useState("");
  const [adminData, setAdminData] = useState("");
  const [isLoading, setIsLoading] = useState(true);


  ///Setting font style
  const { t, i18n } = useTranslation();



  useEffect(() => {
    if (!localStorage.getItem("ad-t")) {
      navigate("/admin");
    }

    //getting admin info
    const fetchPrivateDate = async () => {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("ad-t")}`,
        },
      };

      try {
        const { data } = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/admin/adminpanel`,
          config
        );
        setAdminData(data.admin);
        setIsLoading(false);
      } catch (error) {
        localStorage.removeItem("ad-t");
        setError("You are not authorized, please login");
      }
    };

    fetchPrivateDate();
  }, []);
  return (
    <div className={` MainBody   w-full  min-h-screen duration-200  `}>
       <Helmet>
        <title>Notification - Admin Panel</title>
      </Helmet>
      <div className="flex my-5">
        <AdminNavBar activePage="Notifications" />

        <div className="w-full">
          {isLoading ? (
            <div className=" flex justify-center items-center  ">
              <div className=" text-center text-zinc-200 lg:text-[70px] text-[40px]">
                <p> Loading...</p>
              </div>
            </div>
          ) : (
            <div className="flex">
              <div>
                <div className=" ">Notifications here</div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default Notifications;
