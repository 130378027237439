import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";


import appstore from "../../img/AppStore.png";
import playstore from "../../img/PlayStore.png";
import top from "../../img/icn/top.png";
import Logo from "../../img/icn/Logo.png";

const Footer = () => {


  // Setting font style
  const font = localStorage.getItem("lng");
  let setFont = font === "bn" ? "font-SolaimanLipi" : "font-Poppins-Regular";
  let setFontSize = font === "bn" ? "text-xl" : "";

  const [showTopButton, setShowTopButton] = useState(false);

  // Show "Back to Top" button when scrolled down
  useEffect(() => {
    const handleScroll = () => {
      setShowTopButton(window.scrollY > 200);
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <div className="mt-5 relative">
      <footer className={`${setFont} bg-slate-900 py-8 px-4 sm:px-6 lg:px-8`}>
        <div className=" md:max-w-[85%]   mx-auto mt-10">
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-5 gap-8">
            <div className="col-span-1 md:col-span-2 lg:col-span-2">
              <Link to="https://lokoproperty.com" className="inline-block mb-4">
                <div className="flex items-center space-x-2">
                  <img
                    className="h-8 sm:h-10"
                    src={Logo}
                    alt="Loko Property Logo"
                  />
                  <p className="text-xl sm:text-2xl  font-bold text-[#f5882b]">
                    LOKO PROPERTY
                  </p>
                </div>
              </Link>
              <p
                className={`text-xs sm:text-sm text-justify font-thin text-gray-200 ${setFontSize}`}
              >
                LokoProperty is your trusted platform for buying, selling, and
                renting properties. Whether you're looking for a home,
                investment property, or want to participate in building a house,
                LokoProperty offers a seamless experience to meet your real
                estate needs.
              </p>
            </div>

            <div>
              <h3
                className={`text-lg font-semibold text-gray-200 mb-4 ${setFontSize}`}
              >
                Download our app
              </h3>
              <div className="">
                <img
                  className="h-10 cursor-pointer"
                  src={appstore}
                  alt="App Store"
                />
                <img
                  className="h-10 cursor-pointer"
                  src={playstore}
                  alt="Play Store"
                />
              </div>
            </div>

            <div>
              <h3
                className={`text-lg font-semibold text-gray-200 mb-4 ${setFontSize}`}
              >
                Help and support
              </h3>
              <ul className="space-y-2">
                {["FAQ", "Stay Safe", "Contact Us"].map((item) => (
                  <li key={item}>
                    <a
                      href="#"
                      className="text-gray-300 hover:text-orange-400 text-sm"
                    >
                      {item}
                    </a>
                  </li>
                ))}
              </ul>
            </div>

            <div>
              <h3
                className={`text-lg font-semibold text-gray-200 mb-4 ${setFontSize}`}
              >
                Follow Loko Property
              </h3>
              <ul className="space-y-2">
                {["Facebook", "Twitter", "Youtube"].map((item) => (
                  <li key={item}>
                    <a
                      href="#"
                      className="text-gray-300 hover:text-orange-400 text-sm"
                    >
                      {item}
                    </a>
                  </li>
                ))}
              </ul>
            </div>
          </div>

          <div className="mt-8 pt-8 border-t border-gray-700">
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
              <div>
                <h3
                  className={`text-lg font-semibold text-gray-200 mb-4 ${setFontSize}`}
                >
                  About Loko Property
                </h3>
                <ul className="space-y-2">
                  {["About us", "Terms and conditions", "Privacy policy"].map(
                    (item) => (
                      <li key={item}>
                        <a
                          href="https://lokoproperty.com/AboutUs"
                          className="text-gray-300 hover:text-orange-400 text-sm"
                        >
                          {item}
                        </a>
                      </li>
                    )
                  )}
                </ul>
              </div>
              <div>
                <h3
                  className={`text-lg font-semibold text-gray-200 mb-4 ${setFontSize}`}
                >
                  More about service
                </h3>
                <Link
                  to="/FastSellRent"
                  className="text-gray-300 hover:text-orange-400 text-sm"
                >
                  Fast sell / Rent
                </Link>
              </div>
            </div>
          </div>

          <div className="mt-8 pt-8 border-t border-gray-700">
            <div className=" text-center">
              <p className={`text-sm text-gray-200  ${setFontSize}`}>
                © 2024 LokoProperty. All Rights Reserved.
              </p>
            </div>
          </div>
        </div>
      </footer>

      <motion.button
        initial={{ opacity: 0, scale: 0.5 }}
        animate={
          showTopButton ? { opacity: 1, scale: 1 } : { opacity: 0, y: 50 }
        }
        transition={{ duration: 0.3 }}
        className="fixed right-4 bottom-4 bg-orange-500 text-white p-3 rounded-full shadow-lg hover:bg-orange-600 focus:outline-none focus:ring-2 focus:ring-orange-500 focus:ring-opacity-50"
        onClick={scrollToTop}
        aria-label="Scroll to top"
      >
        <img src={top} alt="Scroll to top" className="w-6 h-6" />
      </motion.button>
    </div>
  );
};

export default Footer;
