import { Navigate, useLocation } from "react-router-dom";
import { useEffect, useState } from "react";

export default function AdRoute({ children }) {
  const currentAd = localStorage.getItem("ad-t");
  const location = useLocation();
  const [shouldRedirect, setShouldRedirect] = useState(false);

  useEffect(() => {
    if (!currentAd) {
      const timer = setTimeout(() => {
        setShouldRedirect(true);
      }, 100);

      return () => clearTimeout(timer);
    }
  }, [currentAd]);

  if (!currentAd) {
    if (shouldRedirect) {
      localStorage.setItem("AdRedirectUrl", location.pathname);
      return <Navigate to="/admin" state={{ from: location }} replace />;
    }
    return null;
  }

  return children;
}
