import { React, useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { useUser } from "../../context/UserContext";
import { Helmet } from "react-helmet";
import { Box } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import { CgSpinner } from "react-icons/cg";

import { toast, Toaster } from "react-hot-toast";

import { Mail, Key, Eye, EyeOff, RotateCw } from "lucide-react";

import Logo from "../../img/icn/Logo.png";
import signBg from "../../img/signbg.jpg";

const Register = () => {
  const { refreshUserData } = useUser();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  ///Language
  const { t, i18n } = useTranslation();




  const [step, setStep] = useState(1);

  // Form states
  const [formData, setFormData] = useState({
    email: "",
    verificationCode: "",
    verificationId: "",
    username: "",
    number: "",
    birthDate: null,
    gender: "",
    password: "",
    confirmPassword: "",
  });

  // UI states
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  // Check for existing session
  useEffect(() => {
    if (localStorage.getItem("userToken")) {
      navigate("/profile");
    }
  }, [navigate]);

  // Handle input changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  // Handle phone number input
  const handlePhoneChange = (e) => {
    const value = e.target.value;

    // If it's the first digit and it's not 0, prevent the input
    if (value.length === 1 && value !== "0") {
      e.target.value = ""; // Clear the input
      e.target.setCustomValidity("Phone number must start with 0");
      e.target.reportValidity();
      return;
    }

    // If it's not the first digit and the first digit is not 0, prevent the input
    if (value.length > 1 && !value.startsWith("0")) {
      e.target.value = value.slice(0, -1); // Remove the last entered digit
      e.target.setCustomValidity("Phone number must start with 0");
      e.target.reportValidity();
      return;
    }

    e.target.setCustomValidity(""); // Clear any previous validation message

    setFormData((prev) => ({
      ...prev,
      number: value,
    }));
  };

  // Handle date change
  const handleDateChange = (newValue) => {
    const formattedDate = dayjs(newValue).format("YYYY-MM-DD");
    setFormData((prev) => ({
      ...prev,
      birthDate: formattedDate,
    }));
  };

  // Validate email format
  const validateEmail = (email) => {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  };

  // Step 1: Send verification email
  const handleEmailSubmit = async (e) => {
    e.preventDefault();
    if (!validateEmail(formData.email)) {
      toast.error("Please enter a valid email address");
      return;
    }

    setLoading(true);
    try {
      const { data } = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/auth/send-verification`,
        { email: formData.email }
      );
      setFormData((prev) => ({
        ...prev,
        verificationId: data.verificationId,
      }));
      setStep(2);
      toast.success("Verification code sent to your email");
    } catch (error) {
      toast.error(
        error.response?.data?.error || "Failed to send verification code"
      );
    } finally {
      setLoading(false);
    }
  };

  // Step 2: Verify email code
  const handleVerifyCode = async (e) => {
    e.preventDefault();
    if (!formData.verificationCode) {
      toast.error("Please enter the verification code");
      return;
    }

    setLoading(true);
    try {
      await axios.post(
        `${process.env.REACT_APP_API_URL}/api/auth/verify-email`,
        {
          verificationId: formData.verificationId,
          verificationCode: formData.verificationCode,
        }
      );
      setStep(3);
      toast.success("Email verified successfully");
    } catch (error) {
      toast.error(error.response?.data?.error || "Invalid verification code");
    } finally {
      setLoading(false);
    }
  };

  // Step 3: Complete registration
  const handleCompleteRegistration = async (e) => {
    e.preventDefault();

    // Validation checks

    if (!formData.gender) {
      toast.error("Please select your Gender");
      return;
    }

    if (!formData.birthDate) {
      toast.error("Please select your Birth Date");
      return;
    }

    if (
      !formData.username ||
      !formData.number ||
      !formData.password ||
      !formData.confirmPassword
    ) {
      toast.error("Please fill out all fields");
      return;
    }

    if (!/^[a-zA-Z\s]{4,}$/.test(formData.username)) {
      toast.error(
        "Name must be at least 4 characters and contain only letters"
      );
      return;
    }

    if (formData.password.length < 6) {
      toast.error("Password must be at least 6 characters");
      return;
    }

    if (formData.password !== formData.confirmPassword) {
      toast.error("Passwords do not match");
      return;
    }

    setLoading(true);
    try {
      const { data } = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/auth/register`,
        {
          username: formData.username,
          number: formData.number,
          email: formData.email,
          birthDate: formData.birthDate,
          gender: formData.gender,
          password: formData.password,
          verificationId: formData.verificationId,
        }
      );

      localStorage.setItem("userToken", data.token);
      toast.success("Registration successful!");
      await refreshUserData();
      navigate("/profile");
    } catch (error) {
      toast.error(error.response?.data?.error || "Registration failed");
    } finally {
      setLoading(false);
    }
  };

  // Render different steps
  const renderStep = () => {
    switch (step) {
      case 1:
        return (
          <form onSubmit={handleEmailSubmit} className="space-y-4">
            <input
              type="email"
              name="email"
              className="h-12 w-80 text-black border rounded-xl border-cyan-900 placeholder-gray-900 p-3"
              placeholder="Enter email"
              value={formData.email}
              onChange={handleInputChange}
              required
            />
            <button
              type="submit"
              className="w-full bg-[#F59E0B] hover:bg-[#fd7200] py-2 text-white rounded-xl flex items-center justify-center gap-2"
              disabled={loading}
            >
              {loading && <CgSpinner className="animate-spin" />}
              {loading ? "Sending..." : "Send Verification Code"}
            </button>
          </form>
        );

      case 2:
        return (
          <form onSubmit={handleVerifyCode} className="space-y-4">
            <input
              type="text"
              name="verificationCode"
              className="h-12 w-80 text-black border rounded-xl border-cyan-900 placeholder-gray-900 p-3"
              placeholder="Enter verification code"
              value={formData.verificationCode}
              onChange={handleInputChange}
              required
            />
            <button
              type="submit"
              className="w-full bg-[#F59E0B] hover:bg-[#fd7200] py-2 text-white rounded-xl flex items-center justify-center gap-2"
              disabled={loading}
            >
              {loading && <CgSpinner className="animate-spin" />}
              {loading ? "Verifying..." : "Verify Code"}
            </button>
          </form>
        );

      case 3:
        return (
          <form
            onSubmit={handleCompleteRegistration}
            className=" mt-2 space-y-3 w-full max-w-md mx-auto"
          >
            <input
              type="text"
              name="username"
              className="h-12 w-full text-black border rounded-xl border-cyan-900 placeholder-gray-900 p-3"
              placeholder="Enter full name"
              value={formData.username}
              onChange={handleInputChange}
              required
            />

            <div className="w-full rounded-[15px]">
              <input
                type="tel"
                name="number"
                className="h-12 w-full text-black border rounded-xl border-cyan-900 placeholder-gray-900 p-3"
                onChange={handlePhoneChange}
                placeholder="Enter phone number"
                maxLength="11"
                required
              />
            </div>

            <Box className="w-full">
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="Select Birth Date"
                  value={formData.birthDate ? dayjs(formData.birthDate) : null}
                  onChange={handleDateChange}
                  sx={{
                    width: "100%",
                    borderRadius: "15px",
                    background: "white",
                    "& .MuiOutlinedInput-root": {
                      borderRadius: "15px",
                    },
                  }}
                />
              </LocalizationProvider>
            </Box>

            <div className="relative">
              <input
                type={showPassword ? "text" : "password"}
                name="password"
                className="h-12 w-full text-black border rounded-xl border-cyan-900 placeholder-gray-900 p-3"
                placeholder="Enter password"
                value={formData.password}
                onChange={handleInputChange}
                required
              />
              <button
                type="button"
                className="absolute right-3 top-1/2 transform -translate-y-1/2"
                onClick={() => setShowPassword(!showPassword)}
              >
                {showPassword ? (
                  <EyeOff className="h-5 w-5 text-gray-500" />
                ) : (
                  <Eye className="h-5 w-5 text-gray-500" />
                )}
              </button>
            </div>

            <div className="relative">
              <input
                type={showConfirmPassword ? "text" : "password"}
                name="confirmPassword"
                className="h-12 w-full text-black border rounded-xl border-cyan-900 placeholder-gray-900 p-3"
                placeholder="Confirm password"
                value={formData.confirmPassword}
                onChange={handleInputChange}
                required
              />
              <button
                type="button"
                className="absolute right-3 top-1/2 transform -translate-y-1/2"
                onClick={() => setShowConfirmPassword(!showConfirmPassword)}
              >
                {showConfirmPassword ? (
                  <EyeOff className="h-5 w-5 text-gray-500" />
                ) : (
                  <Eye className="h-5 w-5 text-gray-500" />
                )}
              </button>
            </div>

            <div className="flex flex-col sm:flex-row items-start sm:items-center space-y-3 sm:space-y-0 sm:space-x-2">
              <span className="text-lg">Gender:</span>
              <div className="flex flex-wrap gap-2">
                {["Male", "Female", "Others"].map((option) => (
                  <label key={option} className="cursor-pointer">
                    <input
                      type="radio"
                      name="gender"
                      value={option}
                      checked={formData.gender === option}
                      onChange={handleInputChange}
                      className="peer sr-only"
                    />
                    <div className="w-24 sm:w-20 text-center p-1 rounded-md bg-white ring-2 ring-transparent transition-all peer-checked:ring-blue-400 peer-checked:bg-[#caffb1]">
                      <p className="text-sm font-semibold text-gray-500">
                        {option}
                      </p>
                    </div>
                  </label>
                ))}
              </div>
            </div>

            <button
              type="submit"
              className="w-full bg-[#F59E0B] hover:bg-[#fd7200] py-3 text-white rounded-xl flex items-center justify-center gap-2"
              disabled={loading}
            >
              {loading && <CgSpinner className="animate-spin" />}
              {loading ? "Registering..." : "Complete Registration"}
            </button>

            <p className="text-center mt-4 text-sm sm:text-base">
              Already have an account?{" "}
              <Link to="/SignIn" className="text-blue-500 font-bold italic">
                Login
              </Link>
            </p>
          </form>
        );

      default:
        return null;
    }
  };

  return (
    <div className=" min-h-[90vh] w-full flex justify-center items-center duration-200 ">
      <Helmet>
        <title>Register - Loko Property</title>
      </Helmet>

      <div className=" mt-5 lg:h-[600px] h-[105vh] xl:w-[70%]  lg:w-[80%]  md:w-[80%]  w-[95%] bg-gradient-to-b lg:bg-gradient-to-r from-black via-red-300 to-orange-200 lg:flex justify-center items-center rounded-[20px] duration-300">
        <div className="lg:h-full lg:w-[50%] lg:flex h-[150px] relative justify-center items-center">
          <img
            className=" opacity-30 h-full w-full lg:object-fill absolute lg:rounded-l-[20px] rounded-t-[20px]"
            src={signBg}
            alt="bg"
          />

          <div className="absolute lg:w-[400px] w-full lg:mt-0 mt-3">
            <img
              class=" m-auto block lg:h-[150px] h-[60px] lg:w-[150px]  w-[60px]  duration-200 mb-5"
              src={Logo}
              alt="logo"
            />

            <p
              className={`px-6 lg:text-2xl text-lg text-white text-center duration-200`}
            >
              {t("home.heading_1")}
            </p>
          </div>

          <div className="leftItem "></div>
        </div>

        <div className="lg:h-full lg:w-[50%] h-[350px]  p-10  ">
          <div className=" h-full w-full">
            <div className="   ">
              <p className=" lg:text-2xl  text-xl text-center lg:text-gray-700 text-white font-semibold">
                Registration
              </p>
            </div>

            <div className=" h-full w-full flex items-center justify-center">
              <div className=" mt-5">
                <p className="lg:text-xl mb-3 text-lg lg:text-gray-700 text-white font-semibold">
                  {step === 1
                    ? "Enter your email"
                    : step === 2
                    ? "Verify your email"
                    : "Complete Registration"}
                </p>
                {renderStep()}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Register;
