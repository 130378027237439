import { React, useRef, useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import locationData from "../../Location.json";
import Multiselect from "multiselect-react-dropdown";
import axios from "axios";
import { useUser } from "../../context/UserContext";
import { Helmet } from "react-helmet";
import { CgSpinner } from "react-icons/cg";
import { useTranslation } from "react-i18next";
import { toast } from "react-hot-toast";
import remove from "../../img/icn/remove.png";
import PostLoading from "../../components/LocalUi/PostLoading";

const EditRentPost = () => {
  const navigate = useNavigate();
  const { id: PostID } = useParams();
  const [loading, setLoading] = useState(false);
  const [btnLoading, setBtnLoading] = useState(false);
  const [post, setPost] = useState(null);

  ///Setting font style
  const font = localStorage.getItem("lng");
  let setFont;
  if (font == "bn") {
    setFont = "font-TrioBangla";
  } else if (font == "en") {
    setFont = "font-Poppins-Regular";
  }
  const token = localStorage.getItem("userToken");

  //MultiOptions
  const [multiOptions, setMultiOptions] = useState([
    "1",
    "2",
    "3",
    "4",
    "5",
    "6",
    "7",
    "8",
    "9",
    "10",
    "11",
    "12",
    "13",
    "14",
    "15",
    "16",
    "17",
    "18",
    "19",
    "20",
    "21",
    "22",
    "23",
    "24",
    "25",
    "26",
    "27",
    "28",
    "29",
    "30",
  ]);
  const [availableFloor, setAvailableFloor] = useState([]);

  // Function to handle selection of options
  const handleSelect = (selectedList) => {
    setAvailableFloor(selectedList);
  };

  // Function to handle removal of options
  const handleRemove = (selectedList, removedItem) => {
    setAvailableFloor(selectedList);
  };

  const [error, setError] = useState("");
  const [postTitle, setPostTitle] = useState("");
  const [postDetails, setPostDetails] = useState("");

  //previous images
  const [images, setImages] = useState([]);
  //new image files from image
  const [files, setFiles] = useState([]);

  const [secondaryNumber, setSecondaryNumber] = useState("");
  const [price, setPrice] = useState("");
  const [exPrice, setExPrice] = useState("");
  const [landPrice, setLandPrice] = useState("");
  const [location, setLocation] = useState("");
  const [size, setSize] = useState("");
  const [landSize, setLandSize] = useState("");

  //dropDown
  const [propertyType, setPropertyType] = useState("Apartment/Flats");
  const [construction, setConstruction] = useState("Ready");
  const [rooms, setRooms] = useState("1");
  const [unit, setUnit] = useState("1");
  const [condition, setCondition] = useState("New");
  const [bedroom, setBedroom] = useState("1");
  const [bathroom, setBathroom] = useState("1");
  const [balconies, setBalconies] = useState("1");
  const [lift, setLift] = useState("No Lift");
  const [drawingRooms, setDrawingRooms] = useState("Yes");
  const [garages, setGarages] = useState("Yes");
  const [totalFloor, setTotalFloor] = useState("1");

  const [furnishing, setFurnishing] = useState("Furnished");
  const [facing, setFacing] = useState("North Facing");

  //checkBox
  const [mosque, setMosque] = useState(false);
  const [security, setSecurity] = useState(false);
  const [fireExit, setFireExit] = useState(false);
  const [fireProtection, setFireProtection] = useState(false);
  const [securityAlarm, setSecurityAlarm] = useState(false);
  const [electronicSecurity, setElectronicSecurity] = useState(false);
  const [gymnasium, setGymnasium] = useState(false);
  const [conversation, setConversation] = useState(false);
  const [wasaConnection, setWasaConnection] = useState(false);
  const [hotWater, setHotWater] = useState(false);
  const [cylinderGas, setCylinderGas] = useState(false);
  const [generator, setGenerator] = useState(false);
  const [intercom, setIntercom] = useState(false);
  const [wifiConnection, setWiFiConnection] = useState(false);
  const [satelliteCableTv, setSatelliteCableTv] = useState(false);
  const [solarPanel, setSolarPanel] = useState(false);
  const [servantRoom, setServantRoom] = useState(false);
  const [pool, setPool] = useState(false);
  const [garden, setGarden] = useState(false);
  const [selfWaterSupply, setSelfWaterSupply] = useState(false);
  const [guestParking, setGuestParking] = useState(false);

  const [divisionId, setDivisionId] = useState("");
  const [district, setDistrict] = useState([]);
  const [subDistrict, setSubDistrict] = useState([]);
  const [districtId, setDistrictId] = useState("");
  const [subDistrictId, setSubDistrictId] = useState("");

  // Adjusted setup for state management and API call
  useEffect(() => {
    if (!PostID) {
      console.error("Post ID is undefined.");
      toast.error("Post ID is not provided in the URL.");
      return;
    }

    setLoading(true);

    const apiUrl = `${process.env.REACT_APP_API_URL}/api/profile/getPropertyRentDetailsForEdit/${PostID}`;
    axios
      .get(apiUrl, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        if (response.data.error === "User not authorized to edit this post") {
          toast.error(response.data.error);
          navigate(-1);
          return;
        }
        setPost(response.data.propertyRentPost);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Failed to fetch post", error);
        setLoading(false);
        if (error.response && error.response.data.error) {
          toast.error(error.response.data.error);
        } else {
          toast.error("Failed to load post data.");
        }
      });
  }, [PostID]);

  useEffect(() => {
    if (post) {
      setPostTitle(post.postTitle);
      setPostDetails(post.postDetails);
      setPrice(post.price);
      setExPrice(post.exPrice);
      setImages(post.imageUrls);
      setLandPrice(post.landPrice);
      setSecondaryNumber(post.secondaryNumber)
      setDivisionId(post.division);
      setDistrictId(post.district);
      setSubDistrictId(post.subDistrict);
      setSize(post.size);
      setLandSize(post.landSize);

      const floorArray = post.availableFloor
        ? post.availableFloor.split(",").map((floor) => floor.trim().toString())
        : [];

      setAvailableFloor(floorArray);
      //dropdown
      setPropertyType(post.propertyType);
      setConstruction(post.construction);
      setUnit(post.unit);
      setCondition(post.condition);
      setBedroom(post.bedroom);
      setBathroom(post.bathroom);
      setBalconies(post.balconies);
      setLift(post.lift);
      setDrawingRooms(post.drawingRooms);
      setGarages(post.garages);
      setTotalFloor(post.totalFloor);
      setFurnishing(post.furnishing);
      setFacing(post.facing);

      //checkBox
      setMosque(post.mosque);
      setSecurity(post.security);
      setFireExit(post.fireExit);
      setFireProtection(post.fireProtection);
      setSecurityAlarm(post.securityAlarm);
      setElectronicSecurity(post.electronicSecurity);
      setGymnasium(post.gymnasium);
      setConversation(post.conversation);
      setWasaConnection(post.wasaConnection);
      setHotWater(post.hotWater);
      setCylinderGas(post.cylinderGas);
      setGenerator(post.generator);
      setIntercom(post.intercom);
      setWiFiConnection(post.wifiConnection);
      setSatelliteCableTv(post.satelliteCableTv);
      setSolarPanel(post.solarPanel);
      setServantRoom(post.servantRoom);
      setPool(post.pool);
      setGarden(post.garden);
      setSelfWaterSupply(post.selfWaterSupply);
      setGuestParking(post.guestParking);
    }
  }, [post]);

  const handleDivision = (e) => {
    const getDivisionId = e.target.value;
    // Check if "Select Division" is selected
    if (getDivisionId === "") {
      // Reset the district state
      setDistrict([]);
    } else {
      // Find the selected division and set the district data
      const getDistrictData = locationData.find(
        (division) => division.division_id === getDivisionId
      ).district;
      setDistrict(getDistrictData);
    }
    // Set the divisionId
    setDivisionId(getDivisionId);
  };

  // Modify the handleDistrict function to set sub-districts
  const handleDistrict = (e) => {
    const districtId = e.target.value;
    setDistrictId(districtId);

    // Find the selected district in the locationData
    const selectedDistrict = locationData
      .find((division) => division.division_id === divisionId)
      .district.find((district) => district.district_id === districtId);

    // Extract and set the sub-districts for the selected district
    if (selectedDistrict && selectedDistrict.sub_district) {
      setSubDistrict(selectedDistrict.sub_district);
    } else {
      // If there are no sub-districts, reset the subDistrict state
      setSubDistrict([]);
    }
  };

  // Define the handleSubDistrict function to set the sub-district ID
  const handleSubDistrict = (e) => {
    const subDistrictId = e.target.value;
    setSubDistrictId(subDistrictId);
  };

  //remove previous images
  const handleRemoveImage = (index) => {
    setImages((prevImages) => prevImages.filter((_, i) => i !== index));
  };

  const handleCheckboxChange = (e, checkboxType) => {
    const isChecked = e.target.checked;

    if (checkboxType === "mosque") {
      setMosque(isChecked);
    } else if (checkboxType === "security") {
      setSecurity(isChecked);
    } else if (checkboxType === "fireExit") {
      setFireExit(isChecked);
    } else if (checkboxType === "fireProtection") {
      setFireProtection(isChecked);
    } else if (checkboxType === "securityAlarm") {
      setSecurityAlarm(isChecked);
    } else if (checkboxType === "electronicSecurity") {
      setElectronicSecurity(isChecked);
    } else if (checkboxType === "wasaConnection") {
      setWasaConnection(isChecked);
    } else if (checkboxType === "hotWater") {
      setHotWater(isChecked);
    } else if (checkboxType === "conversation") {
      setConversation(isChecked);
    } else if (checkboxType === "cylinderGas") {
      setCylinderGas(isChecked);
    } else if (checkboxType === "generator") {
      setGenerator(isChecked);
    } else if (checkboxType === "gymnasium") {
      setGymnasium(isChecked);
    } else if (checkboxType === "intercom") {
      setIntercom(isChecked);
    } else if (checkboxType === "wifiConnection") {
      setWiFiConnection(isChecked);
    } else if (checkboxType === "satelliteCableTv") {
      setSatelliteCableTv(isChecked);
    } else if (checkboxType === "solarPanel") {
      setSolarPanel(isChecked);
    } else if (checkboxType === "servantRoom") {
      setServantRoom(isChecked);
    } else if (checkboxType === "pool") {
      setPool(isChecked);
    } else if (checkboxType === "garden") {
      setGarden(isChecked);
    } else if (checkboxType === "selfWaterSupply") {
      setSelfWaterSupply(isChecked);
    } else if (checkboxType === "guestParking") {
      setGuestParking(isChecked);
    }
  };

  ////Getting image from from ------------------
  const [dragActive, setDragActive] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const inputRef = useRef(null);

  function handleChange(e) {
    e.preventDefault();
    console.log("File has been added");

    const newFiles = [...files];

    if (e.target.files && e.target.files[0]) {
      for (let i = 0; i < e.target.files.length; i++) {
        const file = e.target.files[i];
        if (newFiles.length + images.length < 5) {
          newFiles.push(file);
        } else {
          setErrorMessage("Maximum 5 images allowed.");
          setTimeout(() => {
            setErrorMessage("");
          }, 5000);
          break;
        }
      }
    }
    setFiles(newFiles);
  }
  function handleDrop(e) {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);

    const newFiles = [...files];

    if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
      for (let i = 0; i < e.dataTransfer.files.length; i++) {
        const file = e.dataTransfer.files[i];
        if (newFiles.length + images.length < 5) {
          newFiles.push(file);
        } else {
          setErrorMessage("Maximum 5 images allowed.");
          setTimeout(() => {
            setErrorMessage("");
          }, 5000);
          break;
        }
      }
      setFiles(newFiles);
    }
  }

  function handleDragLeave(e) {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
  }

  function handleDragOver(e) {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(true);
  }

  function handleDragEnter(e) {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(true);
  }

  function removeFile(fileName, idx) {
    const newArr = [...files];
    newArr.splice(idx, 1);
    setFiles(newArr);
  }

  function openFileExplorer() {
    inputRef.current.value = "";
    inputRef.current.click();
  }

  // Function to count words
  const wordCount = (text) => {
    return text.trim().split(/\s+/).length;
  };

  // Handle input change with real-time validation
  const handleInputChange = (e) => {
    const newValue = e.target.value;
    setPostTitle(newValue);

    const words = wordCount(newValue);

    if (words > 15) {
      setError(
        `Title exceeds the 15-word limit. Current word count: ${words}.`
      );
      setBtnLoading(false);
    } else {
      setError(""); // Clear error if within limit
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setBtnLoading(true);

    if (error) {
      toast.error(error);
      setBtnLoading(false);
      return;
    }

    const formData = new FormData();

    formData.append("postTitle", postTitle);
    formData.append("postDetails", postDetails);

    formData.append("secondaryNumber", secondaryNumber);

    formData.append("price", price);
    formData.append("exPrice", exPrice);
    formData.append("landPrice", landPrice);

    //formData.append("location", location);
    formData.append("division", divisionId);
    formData.append("district", districtId);
    formData.append("subDistrict", subDistrictId);

    formData.append("size", size);
    formData.append("landSize", landSize);

    //dropdown
    formData.append("propertyType", propertyType);
    formData.append("construction", construction);
    formData.append("condition", condition);
    formData.append("rooms", rooms);
    formData.append("bedroom", bedroom);
    formData.append("bathroom", bathroom);
    formData.append("balconies", balconies);
    formData.append("drawingRooms", drawingRooms);
    formData.append("lift", lift);
    formData.append("garages", garages);
    formData.append("furnishing", furnishing);
    formData.append("facing", facing);
    formData.append("unit", unit);
    formData.append("totalFloor", totalFloor);
    formData.append("availableFloor", availableFloor);

    //checkBox
    formData.append("mosque", mosque);
    formData.append("security", security);
    formData.append("fireExit", fireExit);
    formData.append("fireProtection", fireProtection);
    formData.append("securityAlarm", securityAlarm);
    formData.append("electronicSecurity", electronicSecurity);
    formData.append("conversation", conversation);
    formData.append("wasaConnection", wasaConnection);
    formData.append("selfWaterSupply", selfWaterSupply);
    formData.append("hotWater", hotWater);
    formData.append("cylinderGas", cylinderGas);
    formData.append("gymnasium", gymnasium);
    formData.append("pool", pool);
    formData.append("garden", garden);
    formData.append("generator", generator);
    formData.append("intercom", intercom);
    formData.append("wifiConnection", wifiConnection);
    formData.append("satelliteCableTv", satelliteCableTv);
    formData.append("solarPanel", solarPanel);
    formData.append("servantRoom", servantRoom);
    formData.append("guestParking", guestParking);

    // Append previous images as a JSON string
    formData.append("previousImages", JSON.stringify(images));

    // Append new image files
    files.forEach((file, index) => {
      formData.append(`newImages`, file);
    });

    // Log form data for debugging
    for (let [key, value] of formData.entries()) {
      console.log(`${key}: ${value}`);
    }

    // Include the post ID in the request for updating the specific post

    try {
      const response = await axios.put(
        `${process.env.REACT_APP_API_URL}/api/profile/editRentPost/${PostID}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      toast.success("Post updated successfully!");
      navigate(-1);
    } catch (error) {
      console.error("Failed to update post", error);
      toast.error("Failed to update post.");
    } finally {
      setBtnLoading(false);
    }
  };
  if (loading) {
    return (
      <div className="w-full h-[90vh] flex justify-center">
        <div className="w-[80%]">
          <PostLoading />
        </div>
      </div>
    );
  }

  if (!post) {
    return (
      <div className="h-[100vh] w-full flex text-center justify-center items-center duration-200">
        <p className="text-xl xl:text-3xl 2xl:text-5xl  font-bold text-gray-400">
          No post Found
        </p>
      </div>
    );
  }

  // Form fields setup similar to  Rent Post, but with values set from the fetched post data

  return (
    <div
    className={` ${setFont} min-h-[100vh] w-full flex justify-center duration-200 `}
    >
      <div className="h-full xl:max-w-[95%] 2xl:max-w-[85%] lg:p-0 p-2   duration-200 ">
        <div className="  ">
          <Helmet>
            <title>Edit Rent Post - Loko Property</title>
          </Helmet>
        </div>

        <form
          onSubmit={handleSubmit}
          encType="multipart/form-data "
          className=" mt-5 "
        >
          <div className=" lg:flex justify-center lg:space-x-10 mt-5 lg:mt-0">
            <div className="leftSide  lg:w-[40%] w-full  ">

                <p className="text-2xl lg:text-2xl font-bold text-[#666666] mb-5">
                  Edit Rent Post.
                </p>


              {/* Post title*/}
              <div className=" ">
                <p className=" text-xl">
                  Rent Post title
                  <span className="text-red-500 ml-1">*</span>
                </p>
                {error && (
                  <p className="text-red-500 font-semibold text-sm mt-1">
                    {error}
                  </p>
                )}
                {!error && (
                  <p className="text-sm text-gray-500 mt-1">
                    {`Word count: ${wordCount(postTitle)} / 15`}
                  </p>
                )}

                <textarea
                  className=" border-2 border-gray-300 rounded-xl p-2 lg:w-[530px]  w-[330px]"
                  type="text"
                  rows="1"
                  required
                  placeholder="Post Title"
                  defaultValue={post.postTitle}
                  //onChange={(e) => setPostTitle(e.target.value)}
                  onChange={handleInputChange}
                ></textarea>
              </div>

              {/* Post details*/}
              <div className="my-3">
                <p className=" text-xl">
                  Post details
                  <span className="text-red-500 ml-1">*</span>
                </p>

                <textarea
                  className=" border-2 border-gray-300 rounded-xl p-2 lg:w-[530px] w-[330px]"
                  type="text"
                  rows="3"
                  required
                  placeholder="less than 500 characters"
                  defaultValue={post.postDetails}
                  onChange={(e) => setPostDetails(e.target.value)}
                ></textarea>
              </div>

              {/* Previous Image */}

              <div>
                <p>Previous Images</p>
                <div className="grid grid-cols-3  md:grid-cols-4 lg:grid-cols-5 gap-1 ">
                  {images.map((image, index) => (
                    <div key={index} className="relative">
                      <img
                        src={image}
                        alt={`Image ${index + 1}`}
                        className="w-full h-16 object-cover rounded-lg"
                      />
                      <button
                              onClick={(e) => {
                                e.preventDefault();
                                handleRemoveImage(index);
                              }}
                        className="absolute top-2 right-2 bg-red-500 text-white p-1 rounded-full hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-opacity-50"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="h-5 w-5"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                        >
                          <path
                            fillRule="evenodd"
                            d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                            clipRule="evenodd"
                          />
                        </svg>
                      </button>
                    </div>
                  ))}
                </div>
              </div>

              {/* Image upload*/}
              <div className=" mt-5 mr-2">
                <div
                  className={`${
                    dragActive
                      ? "bg-blue-400 lg:w-[530px]  w-[330px]"
                      : "bg-blue-50 lg:w-[530px]  w-[330px] border-2 border-dashed border-sky-400"
                  }  p-2 rounded-lg  text-center flex flex-col items-center justify-center`}
                  onDragEnter={handleDragEnter}
                  onSubmit={(e) => e.preventDefault()}
                  onDrop={handleDrop}
                  onDragLeave={handleDragLeave}
                  onDragOver={handleDragOver}
                >
                  <input
                    placeholder="fileInput"
                    className="hidden"
                    ref={inputRef}
                    type="file"
                    multiple={true}
                    onChange={handleChange}
                    accept=".jpg, .jpeg, .png"
                  />

                  <p>
                    Drag & Drop Image here or{" "}
                    <span
                      className="font-bold text-blue-600 cursor-pointer"
                      onClick={openFileExplorer}
                    >
                      <u>Select image</u>
                    </span>{" "}
                    to upload <span className="text-gray-400">(max 5)</span>
                  </p>

                  {errorMessage && (
                    <p className="text-red-500">{errorMessage}</p>
                  )}

                  <div className="grid grid-cols-5 gap-1 p-1 ">
                    {files.map((file, idx) => (
                      <div key={idx} className="">
                        <img
                          className=" h-16 w-32"
                          src={URL.createObjectURL(file)}
                          alt={file.name}
                        />
                        <img
                          type="button"
                          onClick={() => removeFile(file.name, idx)}
                          src={remove}
                          className="h-5 w-5 m-auto mt-1 cursor-pointer"
                          alt="remove"
                        ></img>
                      </div>
                    ))}
                  </div>
                </div>
              </div>

              <div className="mt-4  ">
                <div>
                  <p>
                    Contact number primary :
                    <span className="text-gray-500"> {post.primaryNumber}</span>
                  </p>
                </div>

                <div className="mt-2">
                  <p className=" inline">
                    Contact number Secondary (optional) : +880{" "}
                  </p>
                  <input
                    className="border-2 h-8 w-[165px] rounded-lg border-gray-300 px-1 pl-2"
                    placeholder="1xxxxxxxx"
                    type="number"
                    defaultValue={post.secondaryNumber}
                    onChange={(e) => setSecondaryNumber(e.target.value)}
                  ></input>
                </div>
              </div>

              {/* Location*/}
              <div className=" mt-3">
                <p>
                  Previous Location: {post.subDistrict}, {post.district},{" "}
                  {post.division}
                </p>
                <p>
                  Select Location:
                  <span className="text-red-500 ml-1">*</span>
                </p>
                <div className="flex lg:space-x-5 mt-2">
                  <div className="relative h-12 lg:w-40 w-32">
                    <select
                      name="Division"
                      className="peer h-full w-full rounded-[7px] border-2 border-blue-gray-600 border-t-transparent bg-transparent px-1 text-[15px] text-blue-gray-700 outline outline-0 transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200 empty:!bg-red-500 focus:border-2 focus:border-pink-500 focus:border-t-transparent focus:outline-0 disabled:border-0 disabled:bg-blue-gray-50"
                      onChange={(e) => handleDivision(e)}
                    >
                      <option value="" className="text-pink-600">
                        -Select Division-
                      </option>
                      {locationData.map((getDivision, index) => (
                        <option value={getDivision.division_id} key={index}>
                          {getDivision.division_name}
                        </option>
                      ))}
                    </select>
                    <label className="before:content[' '] after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none text-[12px] font-normal leading-tight text-blue-gray-400 transition-all before:pointer-events-none before:mt-[6.5px] before:mr-1 before:box-border before:block before:h-1.5 before:w-2.5 before:rounded-tl-md before:border-t before:border-l before:border-blue-gray-200 before:transition-all after:pointer-events-none after:mt-[6.5px] after:ml-1 after:box-border after:block after:h-1.5 after:w-2.5 after:flex-grow after:rounded-tr-md after:border-t after:border-r after:border-blue-gray-200 after:transition-all peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[3.75] peer-placeholder-shown:text-blue-gray-500 peer-placeholder-shown:before:border-transparent peer-placeholder-shown:after:border-transparent peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-pink-500 peer-focus:before:border-t-2 peer-focus:before:border-l-2 peer-focus:before:border-pink-500 peer-focus:after:border-t-2 peer-focus:after:border-r-2 peer-focus:after:border-pink-500 peer-disabled:text-transparent peer-disabled:before:border-transparent peer-disabled:after:border-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500">
                      Division
                    </label>
                  </div>

                  {district.length > 0 && (
                    <div className="relative h-12 w-40">
                      <select
                        name="states"
                        className="peer h-full w-full rounded-[7px] border-2 border-blue-gray-600 border-t-transparent bg-transparent px-1 text-[15px] text-blue-gray-700 outline outline-0 transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200 empty:!bg-red-500 focus:border-2 focus:border-pink-500 focus:border-t-transparent focus:outline-0 disabled:border-0 disabled:bg-blue-gray-50"
                        onChange={(e) => handleDistrict(e)}
                      >
                        <option value="" className="text-pink-600">
                          -Select District-
                        </option>
                        {district.map((getDistrict, index) => (
                          <option value={getDistrict.district_id} key={index}>
                            {getDistrict.district_name}
                          </option>
                        ))}
                      </select>

                      <label className="before:content[' '] after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none text-[12px] font-normal leading-tight text-blue-gray-400 transition-all before:pointer-events-none before:mt-[6.5px] before:mr-1 before:box-border before:block before:h-1.5 before:w-2.5 before:rounded-tl-md before:border-t before:border-l before:border-blue-gray-200 before:transition-all after:pointer-events-none after:mt-[6.5px] after:ml-1 after:box-border after:block after:h-1.5 after:w-2.5 after:flex-grow after:rounded-tr-md after:border-t after:border-r after:border-blue-gray-200 after:transition-all peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[3.75] peer-placeholder-shown:text-blue-gray-500 peer-placeholder-shown:before:border-transparent peer-placeholder-shown:after:border-transparent peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-pink-500 peer-focus:before:border-t-2 peer-focus:before:border-l-2 peer-focus:before:border-pink-500 peer-focus:after:border-t-2 peer-focus:after:border-r-2 peer-focus:after:border-pink-500 peer-disabled:text-transparent peer-disabled:before:border-transparent peer-disabled:after:border-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500">
                        District
                      </label>
                    </div>
                  )}

                  {subDistrict.length > 0 && (
                    <div className="relative h-12 w-40">
                      <select
                        name="subDistrict"
                        className="peer h-full w-full rounded-[7px] border-2 border-blue-gray-600 border-t-transparent bg-transparent px-1 text-[15px] text-blue-gray-700 outline outline-0 transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200 empty:!bg-red-500 focus:border-2 focus:border-pink-500 focus:border-t-transparent focus:outline-0 disabled:border-0 disabled:bg-blue-gray-50"
                        onChange={(e) => handleSubDistrict(e)}
                      >
                        <option value="" className="text-pink-600">
                          -Select Sub-District-
                        </option>
                        {subDistrict.map((getSubDistrict, index) => (
                          <option
                            value={getSubDistrict.sub_district_id}
                            key={index}
                          >
                            {getSubDistrict.sub_district_name}
                          </option>
                        ))}
                      </select>
                      <label className="before:content[' '] after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none text-[12px] font-normal leading-tight text-blue-gray-400 transition-all before:pointer-events-none before:mt-[6.5px] before:mr-1 before:box-border before:block before:h-1.5 before:w-2.5 before:rounded-tl-md before:border-t before:border-l before:border-blue-gray-200 before:transition-all after:pointer-events-none after:mt-[6.5px] after:ml-1 after:box-border after:block after:h-1.5 after:w-2.5 after:flex-grow after:rounded-tr-md after:border-t after:border-r after:border-blue-gray-200 after:transition-all peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[3.75] peer-placeholder-shown:text-blue-gray-500 peer-placeholder-shown:before:border-transparent peer-placeholder-shown:after:border-transparent peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-pink-500 peer-focus:before:border-t-2 peer-focus:before:border-l-2 peer-focus:before:border-pink-500 peer-focus:after:border-t-2 peer-focus:after:border-r-2 peer-focus:after:border-pink-500 peer-disabled:text-transparent peer-disabled:before:border-transparent peer-disabled:after:border-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500">
                        Sub-District
                      </label>
                    </div>
                  )}
                </div>
              </div>

              {/* Price*/}

              <div className="flex  lg:space-x-5 space-x-1 mt-2 ">
                <div className=" ">
                  <p className=" md:text-base text-sm">
                    Total Price (tk.)
                    <span className="text-red-500 ml-1">*</span>
                  </p>
                  <input
                    className="h-10 w-40  border-2 border-gray-200 rounded-lg  p-2"
                    type="number"
                    required
                    defaultValue={post.price}
                    onKeyPress={(e) => {
                      if (e.charCode < 48 || e.charCode > 57) {
                        e.preventDefault();
                      }
                    }}
                    onChange={(e) => setPrice(e.target.value)}
                  />
                </div>

                <div className=" ">
                  <p className=" md:text-base text-sm line-through">
                    Previous Price (Tk.)
                  </p>
                  <input
                    className="h-10 w-40  border-2 border-gray-200 rounded-lg  p-2 "
                    type="number"
                    defaultValue={post.exPrice}
                    onKeyPress={(e) => {
                      const charCode = e.charCode;
                      if ((charCode < 48 || charCode > 57) && charCode !== 46) {
                        e.preventDefault();
                      }
                    }}
                    placeholder="Optional"
                    onChange={(e) => setExPrice(e.target.value)}
                  />
                </div>
              </div>

              {/* Land size*/}
              <div className="flex  lg:space-x-5 space-x-1 mt-2 ">
                {propertyType !== "Land" ? (
                  <div className=" ">
                    <p className=" md:text-base text-sm">
                      Size: Sq.Ft
                      <span className="text-red-500 ml-1">*</span>
                    </p>
                    <input
                      placeholder=""
                      className="h-10 lg:w-40 w-28 border-2 border-gray-200 rounded-lg  p-2"
                      type="number"
                      defaultValue={post.size}
                      onKeyPress={(e) => {
                        const charCode = e.charCode;
                        if (
                          (charCode < 48 || charCode > 57) &&
                          charCode !== 46
                        ) {
                          e.preventDefault();
                        }
                      }}
                      required
                      onChange={(e) => setSize(e.target.value)}
                    />
                  </div>
                ) : null}

                <div className="">
                  <p className=" md:text-base text-sm">
                    Land size: Katha
                    <span className="text-red-500 ml-1">*</span>
                  </p>
                  <input
                    placeholder=""
                    className="h-10 lg:w-40 w-28 border-2 border-gray-200 rounded-lg p-2"
                    type="number"
                    required
                    onKeyPress={(e) => {
                      const charCode = e.charCode;
                      if ((charCode < 48 || charCode > 57) && charCode !== 46) {
                        e.preventDefault();
                      }
                    }}
                    defaultValue={post.landSize}
                    onChange={(e) => setLandSize(e.target.value)}
                  />
                </div>
              </div>
            </div>

            {/* Property Specifications*/}
            <div className="rightSide  lg:w-[60%]  ">
              <p className="text-xl">Property Specifications</p>

              <div className="sec1  mt-5 grid lg:grid-cols-5 grid-cols-2 gap-4 ">
                {/* Property type*/}
                <div className="relative h-10 w-36 ">
                  <div className=" border-2 border-gray-200 text-sm p-2 rounded-lg text-gray-500 overflow-hidden ">
                    {post.propertyType}
                  </div>
                  <label className="before:content[' '] after:content[' '] bg--gray-100 pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none text-[12px] font-normal leading-tight text-blue-gray-400 transition-all before:pointer-events-none before:mt-[6.5px] before:mr-1 before:box-border before:block before:h-1.5 before:w-2.5 before:rounded-tl-md before:border-t before:border-l before:border-blue-gray-200 before:transition-all after:pointer-events-none after:mt-[6.5px] after:ml-1 after:box-border after:block after:h-1.5 after:w-2.5 after:flex-grow after:rounded-tr-md after:border-t after:border-r after:border-blue-gray-200 after:transition-all peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[3.75] peer-placeholder-shown:text-blue-gray-500 peer-placeholder-shown:before:border-transparent peer-placeholder-shown:after:border-transparent peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-pink-500 peer-focus:before:border-t-2 peer-focus:before:border-l-2 peer-focus:before:border-pink-500 peer-focus:after:border-t-2 peer-focus:after:border-r-2 peer-focus:after:border-pink-500 peer-disabled:text-transparent peer-disabled:before:border-transparent peer-disabled:after:border-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500">
                    Property type
                  </label>
                </div>

                {propertyType !== "Land" ? (
                  <>
                    {/* Construction Status*/}
                    <div className="relative h-10 w-36 ">
                      <select
                        className="peer h-full w-full rounded-[7px] border-2 border-blue-gray-600 border-t-transparent bg-transparent px-3 text-[15px] text-blue-gray-700 outline outline-0 transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200 empty:!bg-red-500 focus:border-2 focus:border-pink-500 focus:border-t-transparent focus:outline-0 disabled:border-0 disabled:bg-blue-gray-50"
                        defaultValue={post.construction}
                        onChange={(e) => setConstruction(e.target.value)}
                      >
                        <option value="Ready">Ready</option>
                        <option value="Under Construction">
                          Under Construction
                        </option>
                      </select>
                      <label className="before:content[' '] after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none text-[11px] font-normal leading-tight text-blue-gray-400 transition-all before:pointer-events-none before:mt-[6.5px] before:mr-1 before:box-border before:block before:h-1.5 before:w-2.5 before:rounded-tl-md before:border-t before:border-l before:border-blue-gray-200 before:transition-all after:pointer-events-none after:mt-[6.5px] after:ml-1 after:box-border after:block after:h-1.5 after:w-2.5 after:flex-grow after:rounded-tr-md after:border-t after:border-r after:border-blue-gray-200 after:transition-all peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[3.75] peer-placeholder-shown:text-blue-gray-500 peer-placeholder-shown:before:border-transparent peer-placeholder-shown:after:border-transparent peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-pink-500 peer-focus:before:border-t-2 peer-focus:before:border-l-2 peer-focus:before:border-pink-500 peer-focus:after:border-t-2 peer-focus:after:border-r-2 peer-focus:after:border-pink-500 peer-disabled:text-transparent peer-disabled:before:border-transparent peer-disabled:after:border-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500">
                        Construction Status
                      </label>
                    </div>

                    {/* Condition*/}
                    <div className="relative h-10 w-36">
                      <select
                        className="peer h-full w-full rounded-[7px] border-2 border-blue-gray-600 border-t-transparent bg-transparent px-3 text-[15px] text-blue-gray-700 outline outline-0 transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200 empty:!bg-red-500 focus:border-2 focus:border-pink-500 focus:border-t-transparent focus:outline-0 disabled:border-0 disabled:bg-blue-gray-50"
                        defaultValue={post.condition}
                        onChange={(e) => setCondition(e.target.value)}
                      >
                        <option value="New">New</option>
                        <option value="Used">Used</option>
                      </select>
                      <label className="before:content[' '] after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none text-[12px] font-normal leading-tight text-blue-gray-400 transition-all before:pointer-events-none before:mt-[6.5px] before:mr-1 before:box-border before:block before:h-1.5 before:w-2.5 before:rounded-tl-md before:border-t before:border-l before:border-blue-gray-200 before:transition-all after:pointer-events-none after:mt-[6.5px] after:ml-1 after:box-border after:block after:h-1.5 after:w-2.5 after:flex-grow after:rounded-tr-md after:border-t after:border-r after:border-blue-gray-200 after:transition-all peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[3.75] peer-placeholder-shown:text-blue-gray-500 peer-placeholder-shown:before:border-transparent peer-placeholder-shown:after:border-transparent peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-pink-500 peer-focus:before:border-t-2 peer-focus:before:border-l-2 peer-focus:before:border-pink-500 peer-focus:after:border-t-2 peer-focus:after:border-r-2 peer-focus:after:border-pink-500 peer-disabled:text-transparent peer-disabled:before:border-transparent peer-disabled:after:border-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500">
                        Condition
                      </label>
                    </div>

                    {/* Rooms*/}
                    {propertyType === "Commercial Office" ||
                    propertyType === "Commercial Restaurant" ? (
                      <div className="relative h-10 w-36">
                        <select
                          className="peer h-full w-full rounded-[7px] border-2 border-blue-gray-600 border-t-transparent bg-transparent px-3 text-[15px] text-blue-gray-700 outline outline-0 transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200 empty:!bg-red-500 focus:border-2 focus:border-pink-500 focus:border-t-transparent focus:outline-0 disabled:border-0 disabled:bg-blue-gray-50"
                          defaultValue={post.rooms}
                          onChange={(e) => setRooms(e.target.value)}
                        >
                          <option value="1">1</option>
                          <option value="2">2</option>
                          <option value="3">3</option>
                          <option value="4">4</option>
                          <option value="5">5</option>
                          <option value="6">6</option>
                          <option value="7">7</option>
                          <option value="8">8</option>
                          <option value="9">9</option>
                          <option value="10">10</option>
                          <option value="11">11</option>
                          <option value="12">12</option>
                          <option value="13">13</option>
                          <option value="14">14</option>
                          <option value="15">15</option>
                        </select>
                        <label className="before:content[' '] after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none text-[12px] font-normal leading-tight text-blue-gray-400 transition-all before:pointer-events-none before:mt-[6.5px] before:mr-1 before:box-border before:block before:h-1.5 before:w-2.5 before:rounded-tl-md before:border-t before:border-l before:border-blue-gray-200 before:transition-all after:pointer-events-none after:mt-[6.5px] after:ml-1 after:box-border after:block after:h-1.5 after:w-2.5 after:flex-grow after:rounded-tr-md after:border-t after:border-r after:border-blue-gray-200 after:transition-all peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[3.75] peer-placeholder-shown:text-blue-gray-500 peer-placeholder-shown:before:border-transparent peer-placeholder-shown:after:border-transparent peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-pink-500 peer-focus:before:border-t-2 peer-focus:before:border-l-2 peer-focus:before:border-pink-500 peer-focus:after:border-t-2 peer-focus:after:border-r-2 peer-focus:after:border-pink-500 peer-disabled:text-transparent peer-disabled:before:border-transparent peer-disabled:after:border-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500">
                          Rooms
                        </label>
                      </div>
                    ) : null}

                    {/* Bedroom*/}
                    {propertyType === "Apartment/Flats" ||
                    propertyType === "Land Sharing Flats" ||
                    propertyType === "Duplex Home" ? (
                      <div className="relative h-10 w-36">
                        <select
                          className="peer h-full w-full rounded-[7px] border-2 border-blue-gray-600 border-t-transparent bg-transparent px-3 text-[15px] text-blue-gray-700 outline outline-0 transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200 empty:!bg-red-500 focus:border-2 focus:border-pink-500 focus:border-t-transparent focus:outline-0 disabled:border-0 disabled:bg-blue-gray-50"
                          defaultValue={post.bedroom}
                          onChange={(e) => setBedroom(e.target.value)}
                        >
                          <option value="1">1</option>
                          <option value="2">2</option>
                          <option value="3">3</option>
                          <option value="4">4</option>
                          <option value="5">5</option>
                          <option value="6">6</option>
                          <option value="7">7</option>
                          <option value="8">8</option>
                          <option value="9">9</option>
                          <option value="10">10</option>
                          <option value="11">11</option>
                          <option value="12">12</option>
                          <option value="13">13</option>
                          <option value="14">14</option>
                          <option value="15">15</option>
                          <option value="16">16</option>
                        </select>
                        <label className="before:content[' '] after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none text-[12px] font-normal leading-tight text-blue-gray-400 transition-all before:pointer-events-none before:mt-[6.5px] before:mr-1 before:box-border before:block before:h-1.5 before:w-2.5 before:rounded-tl-md before:border-t before:border-l before:border-blue-gray-200 before:transition-all after:pointer-events-none after:mt-[6.5px] after:ml-1 after:box-border after:block after:h-1.5 after:w-2.5 after:flex-grow after:rounded-tr-md after:border-t after:border-r after:border-blue-gray-200 after:transition-all peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[3.75] peer-placeholder-shown:text-blue-gray-500 peer-placeholder-shown:before:border-transparent peer-placeholder-shown:after:border-transparent peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-pink-500 peer-focus:before:border-t-2 peer-focus:before:border-l-2 peer-focus:before:border-pink-500 peer-focus:after:border-t-2 peer-focus:after:border-r-2 peer-focus:after:border-pink-500 peer-disabled:text-transparent peer-disabled:before:border-transparent peer-disabled:after:border-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500">
                          Bedrooms
                        </label>
                      </div>
                    ) : null}

                    {/* Bathroom*/}
                    {propertyType !== "Commercial Factory" &&
                    propertyType !== "Commercial Warehouse" ? (
                      <div className="relative h-10 w-36">
                        <select
                          className="peer h-full w-full rounded-[7px] border-2 border-blue-gray-600 border-t-transparent bg-transparent px-3 text-[15px] text-blue-gray-700 outline outline-0 transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200 empty:!bg-red-500 focus:border-2 focus:border-pink-500 focus:border-t-transparent focus:outline-0 disabled:border-0 disabled:bg-blue-gray-50"
                          defaultValue={post.bathroom}
                          onChange={(e) => setBathroom(e.target.value)}
                        >
                          <option value="1">1</option>
                          <option value="2">2</option>
                          <option value="3">3</option>
                          <option value="4">4</option>
                          <option value="5">5</option>
                          <option value="6">6</option>
                          <option value="7">7</option>
                          <option value="8">8</option>
                        </select>
                        <label className="before:content[' '] after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none text-[12px] font-normal leading-tight text-blue-gray-400 transition-all before:pointer-events-none before:mt-[6.5px] before:mr-1 before:box-border before:block before:h-1.5 before:w-2.5 before:rounded-tl-md before:border-t before:border-l before:border-blue-gray-200 before:transition-all after:pointer-events-none after:mt-[6.5px] after:ml-1 after:box-border after:block after:h-1.5 after:w-2.5 after:flex-grow after:rounded-tr-md after:border-t after:border-r after:border-blue-gray-200 after:transition-all peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[3.75] peer-placeholder-shown:text-blue-gray-500 peer-placeholder-shown:before:border-transparent peer-placeholder-shown:after:border-transparent peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-pink-500 peer-focus:before:border-t-2 peer-focus:before:border-l-2 peer-focus:before:border-pink-500 peer-focus:after:border-t-2 peer-focus:after:border-r-2 peer-focus:after:border-pink-500 peer-disabled:text-transparent peer-disabled:before:border-transparent peer-disabled:after:border-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500">
                          Bathroom
                        </label>
                      </div>
                    ) : null}

                    {/* Balconies*/}
                    {propertyType === "Apartment/Flats" ||
                    propertyType === "Land Sharing Flats" ||
                    propertyType === "Duplex Home" ||
                    propertyType === "Commercial Office" ||
                    propertyType === "Commercial Restaurant" ? (
                      <div className="relative h-10 w-36">
                        <select
                          className="peer h-full w-full rounded-[7px] border-2 border-blue-gray-600 border-t-transparent bg-transparent px-3 text-[15px] text-blue-gray-700 outline outline-0 transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200 empty:!bg-red-500 focus:border-2 focus:border-pink-500 focus:border-t-transparent focus:outline-0 disabled:border-0 disabled:bg-blue-gray-50"
                          defaultValue={post.balconies}
                          onChange={(e) => setBalconies(e.target.value)}
                        >
                          <option value="1">1</option>
                          <option value="2">2</option>
                          <option value="3">3</option>
                          <option value="4">4</option>
                          <option value="5">5</option>
                          <option value="6">6</option>
                          <option value="7">7</option>
                          <option value="8">8</option>
                        </select>
                        <label className="before:content[' '] after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none text-[12px] font-normal leading-tight text-blue-gray-400 transition-all before:pointer-events-none before:mt-[6.5px] before:mr-1 before:box-border before:block before:h-1.5 before:w-2.5 before:rounded-tl-md before:border-t before:border-l before:border-blue-gray-200 before:transition-all after:pointer-events-none after:mt-[6.5px] after:ml-1 after:box-border after:block after:h-1.5 after:w-2.5 after:flex-grow after:rounded-tr-md after:border-t after:border-r after:border-blue-gray-200 after:transition-all peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[3.75] peer-placeholder-shown:text-blue-gray-500 peer-placeholder-shown:before:border-transparent peer-placeholder-shown:after:border-transparent peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-pink-500 peer-focus:before:border-t-2 peer-focus:before:border-l-2 peer-focus:before:border-pink-500 peer-focus:after:border-t-2 peer-focus:after:border-r-2 peer-focus:after:border-pink-500 peer-disabled:text-transparent peer-disabled:before:border-transparent peer-disabled:after:border-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500">
                          Balconies
                        </label>
                      </div>
                    ) : null}

                    {/* Lift*/}
                    {propertyType === "Apartment/Flats" ||
                    propertyType === "Land Sharing Flats" ||
                    propertyType === "Commercial Office" ||
                    propertyType === "Commercial Showroom" ||
                    propertyType === "Commercial Shop" ||
                    propertyType === "Commercial Restaurant" ? (
                      <div className="relative h-10 w-36">
                        <select
                          className="peer h-full w-full rounded-[7px] border-2 border-blue-gray-600 border-t-transparent bg-transparent px-3 text-[15px] text-blue-gray-700 outline outline-0 transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200 empty:!bg-red-500 focus:border-2 focus:border-pink-500 focus:border-t-transparent focus:outline-0 disabled:border-0 disabled:bg-blue-gray-50"
                          defaultValue={post.lift}
                          onChange={(e) => setLift(e.target.value)}
                        >
                          <option value="No Lift">No Lift</option>
                          <option value="1">1</option>
                          <option value="2">2</option>
                          <option value="3">3</option>
                          <option value="4">4</option>
                          <option value="5">5</option>
                          <option value="6">6</option>
                          <option value="7">7</option>
                          <option value="8">8</option>
                        </select>
                        <label className="before:content[' '] after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none text-[12px] font-normal leading-tight text-blue-gray-400 transition-all before:pointer-events-none before:mt-[6.5px] before:mr-1 before:box-border before:block before:h-1.5 before:w-2.5 before:rounded-tl-md before:border-t before:border-l before:border-blue-gray-200 before:transition-all after:pointer-events-none after:mt-[6.5px] after:ml-1 after:box-border after:block after:h-1.5 after:w-2.5 after:flex-grow after:rounded-tr-md after:border-t after:border-r after:border-blue-gray-200 after:transition-all peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[3.75] peer-placeholder-shown:text-blue-gray-500 peer-placeholder-shown:before:border-transparent peer-placeholder-shown:after:border-transparent peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-pink-500 peer-focus:before:border-t-2 peer-focus:before:border-l-2 peer-focus:before:border-pink-500 peer-focus:after:border-t-2 peer-focus:after:border-r-2 peer-focus:after:border-pink-500 peer-disabled:text-transparent peer-disabled:before:border-transparent peer-disabled:after:border-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500">
                          Lift Service
                        </label>
                      </div>
                    ) : null}

                    {/* Drawing rooms*/}
                    {propertyType === "Apartment/Flats" ||
                    propertyType === "Land Sharing Flats" ? (
                      <div className="relative h-10 w-36">
                        <select
                          className="peer h-full w-full rounded-[7px] border-2 border-blue-gray-600 border-t-transparent bg-transparent px-3 text-[15px] text-blue-gray-700 outline outline-0 transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200 empty:!bg-red-500 focus:border-2 focus:border-pink-500 focus:border-t-transparent focus:outline-0 disabled:border-0 disabled:bg-blue-gray-50"
                          defaultValue={post.drawingRooms}
                          onChange={(e) => setDrawingRooms(e.target.value)}
                        >
                          <option value="Yes">Yes</option>
                          <option value="No">No</option>
                        </select>
                        <label className="before:content[' '] after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none text-[12px] font-normal leading-tight text-blue-gray-400 transition-all before:pointer-events-none before:mt-[6.5px] before:mr-1 before:box-border before:block before:h-1.5 before:w-2.5 before:rounded-tl-md before:border-t before:border-l before:border-blue-gray-200 before:transition-all after:pointer-events-none after:mt-[6.5px] after:ml-1 after:box-border after:block after:h-1.5 after:w-2.5 after:flex-grow after:rounded-tr-md after:border-t after:border-r after:border-blue-gray-200 after:transition-all peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[3.75] peer-placeholder-shown:text-blue-gray-500 peer-placeholder-shown:before:border-transparent peer-placeholder-shown:after:border-transparent peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-pink-500 peer-focus:before:border-t-2 peer-focus:before:border-l-2 peer-focus:before:border-pink-500 peer-focus:after:border-t-2 peer-focus:after:border-r-2 peer-focus:after:border-pink-500 peer-disabled:text-transparent peer-disabled:before:border-transparent peer-disabled:after:border-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500">
                          Drawing rooms
                        </label>
                      </div>
                    ) : null}

                    {/* Garages*/}
                    {propertyType !== "Commercial Factory" &&
                    propertyType !== "Commercial Warehouse" ? (
                      <div className="relative h-10 w-36">
                        <select
                          className="peer h-full w-full rounded-[7px] border-2 border-blue-gray-600 border-t-transparent bg-transparent px-3 text-[15px] text-blue-gray-700 outline outline-0 transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200 empty:!bg-red-500 focus:border-2 focus:border-pink-500 focus:border-t-transparent focus:outline-0 disabled:border-0 disabled:bg-blue-gray-50"
                          defaultValue={post.garages}
                          onChange={(e) => setGarages(e.target.value)}
                        >
                          <option value="Yes">Yes</option>
                          <option value="No">No</option>
                        </select>
                        <label className="before:content[' '] after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none text-[12px] font-normal leading-tight text-blue-gray-400 transition-all before:pointer-events-none before:mt-[6.5px] before:mr-1 before:box-border before:block before:h-1.5 before:w-2.5 before:rounded-tl-md before:border-t before:border-l before:border-blue-gray-200 before:transition-all after:pointer-events-none after:mt-[6.5px] after:ml-1 after:box-border after:block after:h-1.5 after:w-2.5 after:flex-grow after:rounded-tr-md after:border-t after:border-r after:border-blue-gray-200 after:transition-all peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[3.75] peer-placeholder-shown:text-blue-gray-500 peer-placeholder-shown:before:border-transparent peer-placeholder-shown:after:border-transparent peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-pink-500 peer-focus:before:border-t-2 peer-focus:before:border-l-2 peer-focus:before:border-pink-500 peer-focus:after:border-t-2 peer-focus:after:border-r-2 peer-focus:after:border-pink-500 peer-disabled:text-transparent peer-disabled:before:border-transparent peer-disabled:after:border-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500">
                          Garages
                        </label>
                      </div>
                    ) : null}

                    {/* Furnishing*/}
                    {propertyType !== "Commercial Factory" &&
                    propertyType !== "Commercial Warehouse" ? (
                      <div className="relative h-10 w-36">
                        <select
                          className="peer h-full w-full rounded-[7px] border-2 border-blue-gray-600 border-t-transparent bg-transparent px-3 text-[15px]  text-blue-gray-700 outline outline-0 transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200 empty:!bg-red-500 focus:border-2 focus:border-pink-500 focus:border-t-transparent focus:outline-0 disabled:border-0 disabled:bg-blue-gray-50"
                          defaultValue={post.furnishing}
                          onChange={(e) => setFurnishing(e.target.value)}
                        >
                          <option value="Furnished">Furnished</option>
                          <option value="semi-furnished">Semi-Furnished</option>
                          <option value="Unfurnished">Unfurnished</option>
                        </select>
                        <label className="before:content[' '] after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none text-[12px] font-normal leading-tight text-blue-gray-400 transition-all before:pointer-events-none before:mt-[6.5px] before:mr-1 before:box-border before:block before:h-1.5 before:w-2.5 before:rounded-tl-md before:border-t before:border-l before:border-blue-gray-200 before:transition-all after:pointer-events-none after:mt-[6.5px] after:ml-1 after:box-border after:block after:h-1.5 after:w-2.5 after:flex-grow after:rounded-tr-md after:border-t after:border-r after:border-blue-gray-200 after:transition-all peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[3.75] peer-placeholder-shown:text-blue-gray-500 peer-placeholder-shown:before:border-transparent peer-placeholder-shown:after:border-transparent peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-pink-500 peer-focus:before:border-t-2 peer-focus:before:border-l-2 peer-focus:before:border-pink-500 peer-focus:after:border-t-2 peer-focus:after:border-r-2 peer-focus:after:border-pink-500 peer-disabled:text-transparent peer-disabled:before:border-transparent peer-disabled:after:border-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500">
                          Furnishing
                        </label>
                      </div>
                    ) : null}

                    {/* Facing*/}
                    {propertyType !== "Commercial Factory" &&
                    propertyType !== "Commercial Warehouse" ? (
                      <div className="relative h-10 w-36">
                        <select
                          className="peer h-full w-full rounded-[7px] border-2 border-blue-gray-600 border-t-transparent bg-transparent px-1 text-[15px] text-blue-gray-700 outline outline-0 transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200 empty:!bg-red-500 focus:border-2 focus:border-pink-500 focus:border-t-transparent focus:outline-0 disabled:border-0 disabled:bg-blue-gray-50"
                          defaultValue={post.facing}
                          onChange={(e) => setFacing(e.target.value)}
                        >
                          <option value="North Facing">North Facing</option>
                          <option value="South Facing">South Facing</option>
                          <option value="East Facing">East Facing</option>
                          <option value="West Facing">West Facing</option>
                        </select>
                        <label className="before:content[' '] after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none text-[12px] font-normal leading-tight text-blue-gray-400 transition-all before:pointer-events-none before:mt-[6.5px] before:mr-1 before:box-border before:block before:h-1.5 before:w-2.5 before:rounded-tl-md before:border-t before:border-l before:border-blue-gray-200 before:transition-all after:pointer-events-none after:mt-[6.5px] after:ml-1 after:box-border after:block after:h-1.5 after:w-2.5 after:flex-grow after:rounded-tr-md after:border-t after:border-r after:border-blue-gray-200 after:transition-all peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[3.75] peer-placeholder-shown:text-blue-gray-500 peer-placeholder-shown:before:border-transparent peer-placeholder-shown:after:border-transparent peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-pink-500 peer-focus:before:border-t-2 peer-focus:before:border-l-2 peer-focus:before:border-pink-500 peer-focus:after:border-t-2 peer-focus:after:border-r-2 peer-focus:after:border-pink-500 peer-disabled:text-transparent peer-disabled:before:border-transparent peer-disabled:after:border-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500">
                          Facing
                        </label>
                      </div>
                    ) : null}

                    {/* Total Unit*/}
                    {propertyType === "Apartment/Flats" ||
                    propertyType === "Land Sharing Flats" ||
                    propertyType === "Commercial Office" ||
                    propertyType === "Commercial Showroom" ||
                    propertyType === "Commercial Shop" ||
                    propertyType === "Commercial Restaurant" ? (
                      <div className="relative h-10 w-36">
                        <select
                          className="peer h-full w-full rounded-[7px] border-2 border-blue-gray-600 border-t-transparent bg-transparent px-3 text-[15px] text-blue-gray-700 outline outline-0 transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200 empty:!bg-red-500 focus:border-2 focus:border-pink-500 focus:border-t-transparent focus:outline-0 disabled:border-0 disabled:bg-blue-gray-50"
                          defaultValue={post.unit}
                          onChange={(e) => setUnit(e.target.value)}
                        >
                          <option value="1">1</option>
                          <option value="2">2</option>
                          <option value="3">3</option>
                          <option value="4">4</option>
                          <option value="5">5</option>
                          <option value="6">6</option>
                          <option value="7">7</option>
                          <option value="8">8</option>
                          <option value="9">9</option>
                          <option value="10">10</option>
                        </select>
                        <label className="before:content[' '] after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none text-[12px] font-normal leading-tight text-blue-gray-400 transition-all before:pointer-events-none before:mt-[6.5px] before:mr-1 before:box-border before:block before:h-1.5 before:w-2.5 before:rounded-tl-md before:border-t before:border-l before:border-blue-gray-200 before:transition-all after:pointer-events-none after:mt-[6.5px] after:ml-1 after:box-border after:block after:h-1.5 after:w-2.5 after:flex-grow after:rounded-tr-md after:border-t after:border-r after:border-blue-gray-200 after:transition-all peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[3.75] peer-placeholder-shown:text-blue-gray-500 peer-placeholder-shown:before:border-transparent peer-placeholder-shown:after:border-transparent peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-pink-500 peer-focus:before:border-t-2 peer-focus:before:border-l-2 peer-focus:before:border-pink-500 peer-focus:after:border-t-2 peer-focus:after:border-r-2 peer-focus:after:border-pink-500 peer-disabled:text-transparent peer-disabled:before:border-transparent peer-disabled:after:border-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500">
                          Total Unit
                        </label>
                      </div>
                    ) : null}

                    {/* Total floor*/}
                    {propertyType !== "Commercial Factory" &&
                    propertyType !== "Commercial Warehouse" ? (
                      <div className="relative h-10 w-36">
                        <select
                          className="peer h-full w-full rounded-[7px] border-2 border-blue-gray-600 border-t-transparent bg-transparent px-3 text-[15px] text-blue-gray-700 outline outline-0 transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200 empty:!bg-red-500 focus:border-2 focus:border-pink-500 focus:border-t-transparent focus:outline-0 disabled:border-0 disabled:bg-blue-gray-50"
                          defaultValue={post.totalFloor}
                          onChange={(e) => setTotalFloor(e.target.value)}
                        >
                          <option value="1">1</option>
                          <option value="2">2</option>
                          <option value="3">3</option>
                          <option value="4">4</option>
                          <option value="5">5</option>
                          <option value="6">6</option>
                          <option value="7">7</option>
                          <option value="8">8</option>
                          <option value="9">9</option>
                          <option value="10">10</option>
                          <option value="11">11</option>
                          <option value="12">12</option>
                          <option value="13">13</option>
                          <option value="14">14</option>
                          <option value="15">15</option>
                          <option value="16">16</option>
                          <option value="17">17</option>
                          <option value="18">18</option>
                          <option value="19">19</option>
                          <option value="21">21</option>
                          <option value="22">22</option>
                          <option value="23">23</option>
                          <option value="24">24</option>
                          <option value="25">25</option>
                          <option value="26">26</option>
                          <option value="27">27</option>
                          <option value="28">28</option>
                          <option value="29">29</option>
                          <option value="30">30</option>
                        </select>
                        <label className="before:content[' '] after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none text-[12px] font-normal leading-tight text-blue-gray-400 transition-all before:pointer-events-none before:mt-[6.5px] before:mr-1 before:box-border before:block before:h-1.5 before:w-2.5 before:rounded-tl-md before:border-t before:border-l before:border-blue-gray-200 before:transition-all after:pointer-events-none after:mt-[6.5px] after:ml-1 after:box-border after:block after:h-1.5 after:w-2.5 after:flex-grow after:rounded-tr-md after:border-t after:border-r after:border-blue-gray-200 after:transition-all peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[3.75] peer-placeholder-shown:text-blue-gray-500 peer-placeholder-shown:before:border-transparent peer-placeholder-shown:after:border-transparent peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-pink-500 peer-focus:before:border-t-2 peer-focus:before:border-l-2 peer-focus:before:border-pink-500 peer-focus:after:border-t-2 peer-focus:after:border-r-2 peer-focus:after:border-pink-500 peer-disabled:text-transparent peer-disabled:before:border-transparent peer-disabled:after:border-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500">
                          Total floor
                        </label>
                      </div>
                    ) : null}

                    {/* Total available floor*/}
                    {propertyType === "Apartment/Flats" ||
                    propertyType === "Land Sharing Flats" ||
                    propertyType === "Commercial Office" ||
                    propertyType === "Commercial Showroom" ||
                    propertyType === "Commercial Shop" ||
                    propertyType === "Commercial Restaurant" ? (
                      <div className="relative lg:h-12 lg:w-[305px] w-[96%] col-span-2 ">
                        <Multiselect
                          className="peer h-full w-full rounded-[7px] border-2 border-blue-gray-600 border-t-transparent bg-transparent px-0 text-[15px] text-blue-gray-700 outline outline-0 transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200 empty:!bg-red-500 focus:border-2 focus:border-pink-500 focus:border-t-transparent focus:outline-0 disabled:border-0 disabled:bg-blue-gray-50"
                          isObject={false}
                          options={multiOptions}
                          selectedValues={availableFloor}
                          onSelect={handleSelect}
                          onRemove={handleRemove}
                          placeholder="Select floor"
                          style={{
                            chips: {
                              background: "blue",
                              padding: "2px 8px",
                            },
                            searchBox: {
                              border: "none",
                            },
                          }}
                          showCheckbox
                        />
                        <label className="before:content[' '] after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none text-[12px] font-normal leading-tight text-blue-gray-400 transition-all before:pointer-events-none before:mt-[6.5px] before:mr-1 before:box-border before:block before:h-1.5 before:w-2.5 before:rounded-tl-md before:border-t before:border-l before:border-blue-gray-200 before:transition-all after:pointer-events-none after:mt-[6.5px] after:ml-1 after:box-border after:block after:h-1.5 after:w-2.5 after:flex-grow after:rounded-tr-md after:border-t after:border-r after:border-blue-gray-200 after:transition-all peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[3.75] peer-placeholder-shown:text-blue-gray-500 peer-placeholder-shown:before:border-transparent peer-placeholder-shown:after:border-transparent peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-pink-500 peer-focus:before:border-t-2 peer-focus:before:border-l-2 peer-focus:before:border-pink-500 peer-focus:after:border-t-2 peer-focus:after:border-r-2 peer-focus:after:border-pink-500 peer-disabled:text-transparent peer-disabled:before:border-transparent peer-disabled:after:border-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500">
                          Available floor on
                        </label>
                      </div>
                    ) : null}
                  </>
                ) : null}
              </div>

              {propertyType !== "Land" &&
              propertyType !== "Commercial Warehouse" &&
              propertyType !== "Commercial Factory" ? (
                <p className="lg:mt-24 mt-5 text-xl">Others facility</p>
              ) : null}

              {/* Conditional checkbox*/}
              {propertyType !== "Commercial Factory" &&
              propertyType !== "Commercial Warehouse" &&
              propertyType !== "Land" ? (
                <div className="sec2 mt-5 grid lg:grid-cols-4 grid-cols-2 gap-4">
                  {/* check boxxxxxxxxxxxxxxxxxes */}

                  <div className="flex items-center mb-4">
                    <input
                      id="Mosque-checkbox"
                      type="checkbox"
                      defaultChecked={post.mosque === true}
                      onChange={(e) => handleCheckboxChange(e, "mosque")}
                      className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                    />
                    <label
                      htmlFor="Mosque-checkbox"
                      className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-900"
                    >
                      Mosque/Prayer room
                    </label>
                  </div>

                  <div className="flex items-center mb-4">
                    <input
                      id="Security-checkbox"
                      type="checkbox"
                      defaultChecked={post.security === true}
                      onChange={(e) => handleCheckboxChange(e, "security")}
                      className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                    />
                    <label
                      htmlFor="Security-checkbox"
                      className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-900"
                    >
                      Security
                    </label>
                  </div>

                  <div className="flex items-center mb-4 ">
                    <input
                      id="Fire-exit-checkbox"
                      type="checkbox"
                      defaultChecked={post.fireExit === true}
                      onChange={(e) => handleCheckboxChange(e, "fireExit")}
                      className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                    />
                    <label
                      htmlFor="Fire-exit-checkbox"
                      className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-900"
                    >
                      Fire exit
                    </label>
                  </div>

                  <div className="flex items-center mb-4">
                    <input
                      id="Fire-protection-checkbox"
                      type="checkbox"
                      defaultChecked={post.fireProtection === true}
                      onChange={(e) =>
                        handleCheckboxChange(e, "fireProtection")
                      }
                      className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                    />
                    <label
                      htmlFor="Fire-protection-checkbox"
                      className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-900"
                    >
                      Fire protection
                    </label>
                  </div>

                  <div className="flex items-center mb-4">
                    <input
                      id="Security-Alarm-checkbox"
                      type="checkbox"
                      defaultChecked={post.securityAlarm === true}
                      onChange={(e) => handleCheckboxChange(e, "securityAlarm")}
                      className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                    />
                    <label
                      htmlFor="Security-Alarm-checkbox"
                      className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-900"
                    >
                      Security Alarm system
                    </label>
                  </div>

                  <div className="flex items-center mb-4">
                    <input
                      id="Electronic-security-checkbox"
                      type="checkbox"
                      defaultChecked={post.electronicSecurity === true}
                      onChange={(e) =>
                        handleCheckboxChange(e, "electronicSecurity")
                      }
                      className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                    />
                    <label
                      htmlFor="Electronic-security-checkbox"
                      className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-900"
                    >
                      Electronic security
                    </label>
                  </div>

                  <div className="flex items-center mb-4">
                    <input
                      id="Conversation-checkbox"
                      type="checkbox"
                      defaultChecked={post.conversation === true}
                      onChange={(e) => handleCheckboxChange(e, "conversation")}
                      className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                    />
                    <label
                      htmlFor="Conversation-checkbox"
                      className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-900"
                    >
                      Conversation Room
                    </label>
                  </div>

                  {/* Conditional checkbox*/}
                  {propertyType === "Apartment/Flats" ||
                  propertyType === "Land Sharing Flats" ||
                  propertyType === "Commercial Office" ||
                  propertyType === "Duplex Home" ||
                  propertyType === "Commercial Restaurant" ? (
                    <>
                      <div className="flex items-center mb-4">
                        <input
                          id="Wasa-checkbox"
                          type="checkbox"
                          defaultChecked={post.wasaConnection === true}
                          onChange={(e) =>
                            handleCheckboxChange(e, "wasaConnection")
                          }
                          className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                        />
                        <label
                          htmlFor="Wasa-checkbox"
                          className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-900"
                        >
                          Wasa connection
                        </label>
                      </div>

                      <div className="flex items-center mb-4">
                        <input
                          id="Hot-water-checkbox"
                          type="checkbox"
                          defaultChecked={post.hotWater === true}
                          onChange={(e) => handleCheckboxChange(e, "hotWater")}
                          className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                        />
                        <label
                          htmlFor="Hot-water-checkbox"
                          className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-900"
                        >
                          Hot water
                        </label>
                      </div>

                      <div className="flex items-center mb-4">
                        <input
                          id="Cylinder-checkbox"
                          type="checkbox"
                          defaultChecked={post.cylinderGas === true}
                          onChange={(e) =>
                            handleCheckboxChange(e, "cylinderGas")
                          }
                          className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                        />
                        <label
                          htmlFor="Cylinder-checkbox"
                          className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-900"
                        >
                          Cylinder gas
                        </label>
                      </div>

                      <div className="flex items-center mb-4">
                        <input
                          id="Gymnasium-checkbox"
                          type="checkbox"
                          defaultChecked={post.gymnasium === true}
                          onChange={(e) => handleCheckboxChange(e, "gymnasium")}
                          className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                        />
                        <label
                          htmlFor="Gymnasium-checkbox"
                          className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-900"
                        >
                          Gymnasium
                        </label>
                      </div>

                      <div className="flex items-center mb-4">
                        <input
                          id="Pool-checkbox"
                          type="checkbox"
                          defaultChecked={post.pool === true}
                          onChange={(e) => handleCheckboxChange(e, "pool")}
                          className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                        />
                        <label
                          htmlFor="Pool-checkbox"
                          className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-900"
                        >
                          Pool
                        </label>
                      </div>

                      <div className="flex items-center mb-4">
                        <input
                          id="Garden-checkbox"
                          type="checkbox"
                          defaultChecked={post.garden === true}
                          onChange={(e) => handleCheckboxChange(e, "garden")}
                          className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                        />
                        <label
                          htmlFor="Garden-checkbox"
                          className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-900"
                        >
                          Garden
                        </label>
                      </div>

                      <div className="flex items-center mb-4">
                        <input
                          id="Self-water-supply-checkbox"
                          type="checkbox"
                          defaultChecked={post.selfWaterSupply === true}
                          onChange={(e) =>
                            handleCheckboxChange(e, "selfWaterSupply")
                          }
                          className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                        />
                        <label
                          htmlFor="Self-water-supply-checkbox"
                          className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-900"
                        >
                          Self water supply
                        </label>
                      </div>
                    </>
                  ) : null}

                  <div className="flex items-center mb-4">
                    <input
                      id="Generator-checkbox"
                      type="checkbox"
                      defaultChecked={post.generator === true}
                      onChange={(e) => handleCheckboxChange(e, "generator")}
                      className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                    />
                    <label
                      htmlFor="Generator-checkbox"
                      className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-900"
                    >
                      Generator
                    </label>
                  </div>

                  <div className="flex items-center mb-4">
                    <input
                      id="Intercom-checkbox"
                      type="checkbox"
                      defaultChecked={post.intercom === true}
                      onChange={(e) => handleCheckboxChange(e, "intercom")}
                      className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                    />
                    <label
                      htmlFor="Intercom-checkbox"
                      className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-900"
                    >
                      Intercom
                    </label>
                  </div>

                  <div className="flex items-center mb-4">
                    <input
                      id="Wi-Fi-connection-checkbox"
                      type="checkbox"
                      defaultChecked={post.wifiConnection === true}
                      onChange={(e) =>
                        handleCheckboxChange(e, "wifiConnection")
                      }
                      className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                    />
                    <label
                      htmlFor="Wi-Fi-connection-checkbox"
                      className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-900"
                    >
                      Wi-Fi connection
                    </label>
                  </div>

                  <div className="flex items-center mb-4">
                    <input
                      id="Satellite-tv-checkbox"
                      type="checkbox"
                      defaultChecked={post.satelliteCableTv === true}
                      onChange={(e) =>
                        handleCheckboxChange(e, "satelliteCableTv")
                      }
                      className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                    />
                    <label
                      htmlFor="Satellite-tv-checkbox"
                      className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-900"
                    >
                      Satellite or cable TV
                    </label>
                  </div>

                  <div className="flex items-center mb-4">
                    <input
                      id="Solar-panel-checkbox"
                      type="checkbox"
                      defaultChecked={post.solarPanel === true}
                      onChange={(e) => handleCheckboxChange(e, "solarPanel")}
                      className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                    />
                    <label
                      htmlFor="Solar-panel-checkbox"
                      className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-900"
                    >
                      Solar panel
                    </label>
                  </div>

                  <div className="flex items-center mb-4">
                    <input
                      id="Servant-room-checkbox"
                      type="checkbox"
                      defaultChecked={post.servantRoom === true}
                      onChange={(e) => handleCheckboxChange(e, "servantRoom")}
                      className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                    />
                    <label
                      htmlFor="Servant-room-checkbox"
                      className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-900"
                    >
                      Servant room
                    </label>
                  </div>

                  <div className="flex items-center mb-4">
                    <input
                      id="Guest-parking-checkbox"
                      type="checkbox"
                      defaultChecked={post.guestParking === true}
                      onChange={(e) => handleCheckboxChange(e, "guestParking")}
                      className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                    />
                    <label
                      htmlFor="Guest-parking-checkbox"
                      className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-900"
                    >
                      Guest parking
                    </label>
                  </div>
                </div>
              ) : null}
            </div>
          </div>

          <button
            type="submit"
            className="w-[250px] m-auto lg:mt-3 mt-5 flex gap-1 items-center justify-center py-2.5 text-white rounded-xl bg-[#F59E0B] hover:bg-[#fd7200] duration-200"
          >
            {btnLoading && (
              <CgSpinner size={20} className="mt-1 animate-spin" />
            )}
            <span className="text-md">Submit</span>
          </button>
        </form>
      </div>
    </div>
  );
};

export default EditRentPost;
