import React, { useRef, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

// icons
import icn_arrow_down from "../../img/icn/arrow_down.png";
import icn_dashboard from "../../img/icn/dashboard.png";
import icn_admin from "../../img/icn/admin.png";
import icn_notifications from "../../img/icn/notifications.png";
import icn_vlog from "../../img/icn/vlogger.png";
import icn_userManage from "../../img/icn/userManage.png";
import icn_postManage from "../../img/icn/postManage.png";
import icn_notice from "../../img/icn/notice.png";
import icn_siteSettings from "../../img/icn/SiteSettings.png";
import icn_logout from "../../img/icn/logout.png";

function AdminSidebar({ isOpen, activePage, onClose }) {
  const navigate = useNavigate();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const sidebarRef = useRef(null);

  const menuItems = [
    { name: "Dashboard", icon: icn_dashboard, label: "Dashboard" },
    { name: "AdminInfo", icon: icn_admin, label: "Admin" },
    { name: "Notifications", icon: icn_notifications, label: "Notifications" },
    { name: "BlogPost", icon: icn_vlog, label: "Blog Post" },
    { name: "UserManage", icon: icn_userManage, label: "User Management" },
    {
      name: "SellPostManage",
      icon: icn_postManage,
      label: "Sell Posts Manage",
    },
    {
      name: "RentPostManage",
      icon: icn_postManage,
      label: "Rent Posts Manage",
    },
    {
      name: "Group-Building-Manage",
      icon: icn_postManage,
      label: "Group Building Manage",
      hasDropdown: true,
      dropdownItems: [
        {
          name: "Group-Building-Desired-Request",
          label: "User Desired Request",
        },
      ],
    },
    { name: "Interior", icon: icn_postManage, label: "Interior Management" },
    { name: "NoticeBoard", icon: icn_notice, label: "Notice Board" },
    { name: "SiteSettings", icon: icn_siteSettings, label: "Site Settings" },
  ];

  const handleItemClick = (name) => {
    if (name === "LogOut") {
      localStorage.removeItem("ad-t");
      localStorage.removeItem("ad-n");
      navigate("/Home");
      window.location.reload();
    } else {
      navigate(`/admin/${name}`);
    }
    onClose(); // Close the sidebar after navigation
  };

  const handleDropdownToggle = (e) => {
    e.stopPropagation(); // Prevent the click from propagating to the parent div
    setIsDropdownOpen(!isDropdownOpen);
  };

  const handleClickOutside = (event) => {
    if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
      onClose();
    }
  };

  useEffect(() => {
    if (isOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isOpen]);

  return (
    <div
      className={`fixed inset-0  text-sm bg-gray-800 bg-opacity-50 transition-opacity duration-300 z-50  ${
        isOpen ? "opacity-100" : "opacity-0 pointer-events-none"
      }`}
    >
      <div
        ref={sidebarRef}
        className={`bg-white w-64 h-full shadow-lg transform transition-transform duration-300 ease-in-out ${
          isOpen ? "translate-x-0" : "-translate-x-full"
        }`}
      >
        <div className="p-4 flex justify-between items-center">
          <h2 className="text-xl font-bold text-[#f5882b]">Admin Panel</h2>

          <button
            className="text-black hover:text-gray-700"
            onClick={onClose} // Close the sidebar
          >
            <svg
              className="h-8 w-8"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </button>
        </div>

        <div className="p-1   space-y-2">
          {menuItems.map((item) => (
            <React.Fragment key={item.name}>
              <div
                className={`text-xl h-12 my-1 rounded-[15px] shadow-md flex items-center relative p-2 m-1 cursor-pointer ${
                  activePage === item.name
                    ? "bg-orange-100 border border-orange-400"
                    : ""
                }`}
                onClick={() => handleItemClick(item.name)}
              >
                <img
                  src={item.icon}
                  className="h-7 w-7 inline mr-2"
                  alt={item.label}
                />
                <p className=" text-sm">
                  {" "}
                  {item.label}
                </p>
                {item.hasDropdown && (
                  <img
                    src={icn_arrow_down}
                    className={`h-8 w-7 absolute right-3 transition-transform duration-200 ${
                      isDropdownOpen ? "rotate-180" : ""
                    }`}
                    alt="Dropdown"
                    onClick={handleDropdownToggle}
                  />
                )}
              </div>
              {item.hasDropdown && isDropdownOpen && (
                <div className="">
                  {item.dropdownItems.map((dropdownItem) => (
                    <div
                      key={dropdownItem.name}
                      className="text-sm h-10 border-b border-orange-500 rounded-[15px] shadow-md flex items-center justify-center cursor-pointer p-1  hover:bg-gray-200"
                      onClick={() => handleItemClick(dropdownItem.name)}
                    >
                      {dropdownItem.label}
                    </div>
                  ))}
                </div>
              )}
            </React.Fragment>
          ))}

          <div
            className="m-auto mt-5 flex items-center justify-evenly h-12 px-1 bg-red-300 rounded-lg cursor-pointer hover:bg-red-500 hover:text-white"
            onClick={() => handleItemClick("LogOut")}
          >
            <img src={icn_logout} className="h-9 w-9" alt="Logout" />
            <div className="text-sm hidden lg:block">Logout</div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AdminSidebar;
