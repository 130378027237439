import React, { useState, useEffect } from "react";
import axios from "axios";
import { useTranslation } from "react-i18next";
import { toast } from "react-hot-toast";
import { motion } from "framer-motion";
import { FaCheck, FaTimes } from "react-icons/fa";
import AdminNavBar from "../admin/AdminNavBar";
import { Helmet } from "react-helmet";

function SiteSettings() {
  const [autoApproveSell, setAutoApproveSell] = useState(false);
  const [autoApproveRent, setAutoApproveRent] = useState(false);

  const { t, i18n } = useTranslation();



  const getSiteSettings = async () => {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("ad-t")}`,
      },
    };

    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/admin/getsitesettings`,
        config
      );
      const siteSettings = response.data[0];

      if (siteSettings) {
        setAutoApproveSell(siteSettings.autoApproveSell);
        setAutoApproveRent(siteSettings.autoApproveRent);
      }
    } catch (error) {
      console.error("Error getting site settings:", error);
      toast.error("Error getting site settings");
    }
  };

  useEffect(() => {
    getSiteSettings();
  }, []);

  const updateSiteSettings = async () => {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("ad-t")}`,
      },
    };
    try {
      await axios.put(
        `${process.env.REACT_APP_API_URL}/api/admin/updatesitesettings`,
        {
          autoApproveSell,
          autoApproveRent,
        },
        config
      );
      toast.success("Settings updated");
    } catch (error) {
      console.error("Error updating site settings:", error);
      toast.error("Error updating site settings");
    }
  };

  return (
    <div className={`MainSection  min-h-screen`}>
 <Helmet>
        <title>Site Settings - Admin Panel</title>
      </Helmet>
      <div className="md:flex md:my-5 my-1 ">
        <AdminNavBar activePage="SiteSettings" />
        <div className="w-full">
          <motion.div
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
            className="mb-4"
          >
            <label className="flex items-center space-x-2">
              <span>Auto Approve Sell post</span>
              <input
                type="checkbox"
                checked={autoApproveSell}
                onChange={() => setAutoApproveSell(!autoApproveSell)}
                className="form-checkbox h-5 w-5 text-blue-500"
              />
              {autoApproveSell ? <FaCheck className="text-green-500" /> : <FaTimes className="text-red-500" />}
            </label>
          </motion.div>

          <motion.div
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: 0.2 }}
            className="mb-4"
          >
            <label className="flex items-center space-x-2">
              <span>Auto Approve Rent post</span>
              <input
                type="checkbox"
                checked={autoApproveRent}
                onChange={() => setAutoApproveRent(!autoApproveRent)}
                className="form-checkbox h-5 w-5 text-blue-500"
              />
              {autoApproveRent ? <FaCheck className="text-green-500" /> : <FaTimes className="text-red-500" />}
            </label>
          </motion.div>

          <motion.button
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            onClick={updateSiteSettings}
            className="bg-blue-500 text-white px-4 py-2 rounded-md mt-4"
          >
            Update Settings
          </motion.button>
        </div>
      </div>
    </div>
  );
}

export default SiteSettings;
