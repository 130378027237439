import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import toast from "react-hot-toast";
import AdminNavBar from "../../AdminNavBar";
import { useTranslation } from "react-i18next";
import ListLoading from "../../../../components/LocalUi/ListLoading";
import { Helmet } from "react-helmet";
import action from "../../../../img/icn/action.png";

function RentPostManagement() {
  ///Setting font style
  const { t, i18n } = useTranslation();




  const navigate = useNavigate();
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const token = localStorage.getItem("ad-t");
  const [allowedRentPostData, setAllowedRentPostData] = useState(null);
  const [pendingRentPostData, setPendingRentPostData] = useState(null);

  useEffect(() => {
    if (!localStorage.getItem("ad-t")) {
      navigate("/admin");
      return; // Add a return statement to exit early
    }

    const fetchPrivateData = async () => {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("ad-t")}`,
        },
      };

      try {
        const { data } = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/admin/rentpostmanage`,
          config
        );
        setAllowedRentPostData(data);

        // pendingRentPost request
        const pendingRentPostData = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/admin/pendingRentPost`,
          config
        );
        setPendingRentPostData(pendingRentPostData.data);

        setIsLoading(false);
      } catch (error) {
        localStorage.removeItem("ad-t");
        localStorage.removeItem("ad-n");
        setError("You are not authorized, please login");
        setIsLoading(false); // Make sure to set isLoading to false in case of an error
      }
    };

    fetchPrivateData();
  }, [navigate]); // Include navigate as a dependency

  // Function to handle opening detail view
  const handleOpenDetail = (item) => {
    navigate(`/Property-Rent-Details/${item.PostID}`);
  };

  function calculateRemainingDays(targetDate) {
    const targetDateTime = new Date(targetDate);
    const now = new Date();

    const timeDifference = targetDateTime - now;
    // Calculate the remaining days
    const remainingDays = Math.ceil(timeDifference / (1000 * 60 * 60 * 24));

    return remainingDays;
  }

  const renderListLoadingPage = () => {
    return <ListLoading />;
  };

  //Action modal
  const [showPendingAction, setShowPendingAction] = useState(false);
  const [showApprovedAction, setApprovedAction] = useState(false);
  const [postSelectedData, setPostSelectedData] = useState(null);
  const [approvalMonth, setApprovalMonth] = useState("30");

  const handlePendingAction = (event, item) => {
    event.stopPropagation();

    setShowPendingAction(true);
    setPostSelectedData(item);
  };

  const handleApprovedAction = (event, item) => {
    event.stopPropagation();
    setApprovedAction(true);
    setPostSelectedData(item);
  };

  //cancel click
  const handleCancel = () => {
    setShowPendingAction(false);
    setApprovedAction(false);
  };

  const handleRentPostApprove = async () => {
    const postId = postSelectedData._id;

    // Send an approve request to the server
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    const requestBody = {
      approvalMonth: approvalMonth,
    };

    try {
      const response = await axios.put(
        `${process.env.REACT_APP_API_URL}/api/admin/propertyrentpostapprove/${postId}`,
        requestBody,
        config
      );

      if (response.status === 200) {
        setPendingRentPostData((prevPosts) =>
          prevPosts.filter((post) => post._id !== postId)
        );
        toast.success("Post Approved successfully");
      } else {
        console.error("Failed to Approve rent post:", response.data.error);
      }
    } catch (error) {
      console.error("Error approving rent post:", error);
    }

    setShowPendingAction(false);
  };

  const handleApprovedRentPostEndDate = async () => {
    const postId = postSelectedData._id;

    // Send an approve request to the server
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    const requestBody = {
      approvalMonth: approvalMonth,
    };

    try {
      const response = await axios.put(
        `${process.env.REACT_APP_API_URL}/api/admin/propertyrentpostenddate/${postId}`,
        requestBody,
        config
      );

      if (response.status === 200) {
        setPendingRentPostData((prevPosts) =>
          prevPosts.filter((post) => post._id !== postId)
        );
        toast.success("Post Approved successfully");
      } else {
        console.error("Failed to Approve rent post:", response.data.error);
      }
    } catch (error) {
      console.error("Error approving rent post:", error);
    }

    setApprovedAction(false);
  };

  const handlePropertyRentPostDelete = () => {
    const postId = postSelectedData._id;
    if (!token) {
      console.error("Authentication token not found.");
      toast.error("No Authentication found");
      return;
    }
    axios
      .delete(
        `${process.env.REACT_APP_API_URL}/api/admin/propertyrentpostdelete/${postId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        if (response.status === 200) {
          toast.success("Post deleted successfully");
          setPendingRentPostData((prevPosts) =>
            prevPosts.filter((post) => post._id !== postId)
          );
          setAllowedRentPostData((prevPosts) =>
            prevPosts.filter((post) => post._id !== postId)
          );
        } else {
          console.error("Failed to delete rent post:", response.data.error);
        }
      })
      .catch((error) => {
        console.error("Error deleting rent post:", error);
        toast.error("Error deleting rent post");
      });
    setShowPendingAction(false);
    setApprovedAction(false);
  };

  return (
    <div
      className={` MainBody overflow-auto styleScrollbar duration-200 px-2 min-h-screen  `}
    >
      <Helmet>
        <title>Rent Post Manage - Admin Panel</title>
      </Helmet>

      <div className="md:flex md:my-5 my-1  ">
        <AdminNavBar activePage="RentPostManage" />

        <div className="w-full">
          <div>
            <p className="font-semibold text-3xl text-gray-500 mb-5">
              Rent Posts Management
            </p>
          </div>

          {isLoading ? (
            <div className=" w-full flex justify-center items-center">
              <div className="h-full w-full flex justify-center items-center">
                <div className=" space-y-3  px-2 w-full">
                  {renderListLoadingPage()}
                </div>
              </div>
            </div>
          ) : (
            <div className="lg:flex w-full lg:space-x-4 ">
              <div className=" md:w-[45%] w-full ">
                <p>Pending post ({pendingRentPostData.length})</p>

                {pendingRentPostData.length === 0 ? (
                  <p>No pending post available</p>
                ) : (
                  <div className="   ">
                    {pendingRentPostData?.map((item) => (
                      <div key={item._id} className="flex ">
                        <div
                          onClick={() => handleOpenDetail(item)}
                          className=" my-3 flex justify-between items-center shadow-md hover:shadow-lg  duration-200  bg-orange-100 border border-green-400 cursor-pointer rounded-xl p-1   "
                        >
                          <div className="flex items-center space-x-3">
                            <div className="">
                              {item.imageUrls.map((imageUrl, index) => (
                                <img
                                  className="h-24 w-28 py-1 rounded-xl"
                                  key={index}
                                  src={imageUrl}
                                  alt={`Image ${index}`}
                                />
                              ))}
                            </div>

                            <div className="  h-[100px] lg:w-[350px] w-[100%] ">
                              <div className="overflow-hidden">
                                <p className="line-clamp-2 md:text-md text-sm font-bold">
                                  {item.postTitle}
                                </p>
                              </div>

                              <p className="text-xs">
                                Post Time : {item.postDate}
                              </p>
                              <p className="text-red-600 md:text-md text-sm">
                                Price: {item.price}
                              </p>
                              <p className=" md:text-sm text-xs">
                                {" "}
                                {item.subDistrict}, {item.district},{" "}
                                {item.division}
                              </p>
                            </div>
                          </div>

                          <div className="flex duration-200 ">
                            <div
                              className="cursor-pointer bg-red-200 rounded-md px-2 py-2"
                              onClick={(event) =>
                                handlePendingAction(event, item)
                              }
                            >
                              <div className="flex items-center">
                                <img
                                  src={action}
                                  alt="locations"
                                  className="md:h-7 h-5 md:w-7 w-5 duration-200"
                                />
                                <p className="md:text-base hidden  ml-2 lg:block duration-200">
                                  Action
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>

                        {showPendingAction && (
                          <div className="fixed inset-0 z-50 flex items-center justify-center bg-[#000000b0] ">
                            <div className="bg-white p-6 rounded-2xl  relative">
                              {" "}
                              <div className=" mx-20  mt-10 py-5 border  rounded-xl text-center items-center">
                                <button
                                  className="px-4 py-2 absolute right-0  bg-red-300 rounded-l-md"
                                  onClick={handleCancel}
                                >
                                  Cancel
                                </button>

                                <div className="flex justify-center items-center">
                                  <div className=" ">
                                    <div className=" flex justify-center">
                                      <img
                                        className="h-28 w-44"
                                        src={postSelectedData.imageUrls}
                                        alt="user"
                                      />
                                    </div>
                                    <div className=" text-center">
                                      <div className="mt-1 w-[400px] overflow-hidden">
                                        <p className="line-clamp-2 text-bold text-xl">
                                          Title: {postSelectedData.postTitle}
                                        </p>
                                      </div>

                                      <p>Price: {postSelectedData.price} </p>
                                      <p>
                                        Author ID: {postSelectedData.userId}{" "}
                                      </p>
                                      <p className="md:text-sm text-xs">
                                        Location: {postSelectedData.subDistrict}
                                        , {postSelectedData.district},{" "}
                                        {postSelectedData.division}{" "}
                                      </p>
                                      <p className="inline">
                                        Post Date: {postSelectedData.postDate}{" "}
                                      </p>
                                    </div>

                                    <div className="mt-5 my-3 border rounded-lg mr-2 text-center flex justify-center ">
                                      <div className=" p-3 ">
                                        <label className=" text-left block text-sm font-medium text-gray-600">
                                          Approve for
                                        </label>
                                        <select
                                          onChange={(e) =>
                                            setApprovalMonth(e.target.value)
                                          }
                                          id="Approve"
                                          name="Approve"
                                          className=" h-8  w-28 border rounded-md mr-2"
                                        >
                                          <option value="30">1 Month</option>
                                          <option value="90">3 Month</option>
                                          <option value="180">6 Month</option>
                                        </select>

                                        <button
                                          onClick={handleRentPostApprove}
                                          className=" h-8 px-2  rounded-md bg-green-400 mr-2"
                                        >
                                          Approve
                                        </button>

                                        <button
                                          onClick={handlePropertyRentPostDelete}
                                          className="h-8 px-2 rounded-md bg-red-400 "
                                        >
                                          Delete Post
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    ))}
                  </div>
                )}
              </div>

              <div className="  md:w-[45%] w-full">
                <p>Allowed posts ({allowedRentPostData.length})</p>
                {allowedRentPostData.length === 0 ? (
                  <p>No available post</p>
                ) : (
                  <div>
                    {allowedRentPostData.map((item) => (
                      <div key={item._id} className="flex ">
                        <div
                          onClick={() => handleOpenDetail(item)}
                          className=" my-3 flex justify-between items-center shadow-md hover:shadow-lg  duration-200   bg-orange-100 border border-green-400 cursor-pointer rounded-xl p-1 "
                        >
                          <div className="flex items-center space-x-3">
                            <div className=" ">
                              {item.imageUrls.map((imageUrl, index) => (
                                <img
                                  className="h-24 w-28 py-1 rounded-xl"
                                  key={index}
                                  src={imageUrl}
                                  alt={`Image ${index}`}
                                />
                              ))}
                            </div>
                            <div className="  h-[100px] lg:w-[350px] w-[100%] ">
                              <div className="overflow-hidden">
                                <p className="line-clamp-2 font-bold md:text-md text-sm">
                                  {item.postTitle}
                                </p>
                              </div>
                              <p className="text-xs">
                                Post Time : {item.postDate}
                              </p>
                              <p className="text-red-600 md:text-md text-sm">
                                Price: {item.price}
                              </p>
                              <p className=" text-sm">
                                {" "}
                                {item.subDistrict}, {item.district},{" "}
                                {item.division}
                              </p>
                            </div>
                          </div>

                          <div className=" my-3   rounded-xl text-center flex">
                            {calculateRemainingDays(item.deletionDate) < 1 ? (
                              <div className="flex duration-200 ">
                                <div
                                  className="cursor-pointer bg-red-200 rounded-md px-2 py-2"
                                  onClick={(event) =>
                                    handleApprovedAction(event, item)
                                  }
                                >
                                  <div className="flex items-center">
                                    <img
                                      src={action}
                                      alt="locations"
                                      className="md:h-7 h-5 md:w-7 w-5 duration-200"
                                    />
                                    <p className="md:text-base hidden   lg:block  ml-2 duration-200">
                                      Action
                                    </p>
                                  </div>
                                </div>
                              </div>
                            ) : (
                              <div className="flex duration-200 ">
                                <div
                                  className="cursor-pointer bg-green-200 rounded-md px-3 py-2"
                                  onClick={(event) =>
                                    handleApprovedAction(event, item)
                                  }
                                >
                                  <div className="flex items-center">
                                    <img
                                      src={action}
                                      alt="locations"
                                      className="md:h-7 h-5 md:w-7 w-5 duration-200"
                                    />
                                    <p className="md:text-base hidden  ml-2 lg:block duration-200">
                                      Action
                                    </p>
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                        {showApprovedAction && (
                          <div className="fixed inset-0 z-50 flex items-center  justify-center bg-[#00000017] ">
                            <div className="bg-white  lg:p-6 p-2 rounded-2xl  relative">
                              <div className=" lg:mx-20 mx-3  lg:mt-10 mt-2 py-5 border  rounded-xl text-center items-center">
                                <button
                                  className="px-4 py-2 absolute right-0  bg-red-300 rounded-l-md"
                                  onClick={handleCancel}
                                >
                                  Cancel
                                </button>

                                <div className="flex justify-center items-center">
                                  <div className=" ">
                                    <div className=" flex justify-center">
                                      <img
                                        className="h-28 w-44"
                                        src={postSelectedData.imageUrls}
                                        alt="user"
                                      />
                                    </div>
                                    <div className=" text-center">
                                      <div className="mt-1  overflow-hidden">
                                        <p className="line-clamp-2 text-bold text-xl">
                                          Title: {postSelectedData.postTitle}
                                        </p>
                                      </div>

                                      <p>Price: {postSelectedData.price} </p>
                                      <p>
                                        Author ID: {postSelectedData.userId}{" "}
                                      </p>
                                      <p>
                                        Location: {postSelectedData.subDistrict}
                                        , {postSelectedData.district},{" "}
                                        {postSelectedData.division}{" "}
                                      </p>
                                      <p className="inline">
                                        Post Date: {postSelectedData.postDate}{" "}
                                      </p>

                                      {calculateRemainingDays(
                                        postSelectedData.deletionDate
                                      ) < 1 ? (
                                        <p className=" text-red-600 font-bold">
                                          Post Out of date:{" "}
                                          {calculateRemainingDays(
                                            postSelectedData.deletionDate
                                          )}{" "}
                                          Days ago
                                        </p>
                                      ) : (
                                        <p className="text-green-600 font-bold">
                                          Post Remaining Date:{" "}
                                          {calculateRemainingDays(
                                            postSelectedData.deletionDate
                                          )}
                                        </p>
                                      )}

                                      <p>
                                        Post Deletions Date:{" "}
                                        {postSelectedData.deletionDate}
                                      </p>
                                    </div>

                                    <div className="mt-5 my-3 border rounded-lg mr-2 text-center flex justify-center ">
                                      <div className=" p-3 ">
                                        <label className=" text-left block text-sm font-medium text-gray-600">
                                          Set post duration
                                        </label>
                                        <select
                                          onChange={(e) =>
                                            setApprovalMonth(e.target.value)
                                          }
                                          id="Approve"
                                          name="Approve"
                                          className=" h-8  w-28 border rounded-md mr-2"
                                        >
                                          <option value="30">1 Month</option>
                                          <option value="90">3 Month</option>
                                          <option value="180">6 Month</option>
                                        </select>

                                        <button
                                          onClick={
                                            handleApprovedRentPostEndDate
                                          }
                                          className=" h-8 px-2  rounded-md bg-green-400 mr-2"
                                        >
                                          Update Post End Date
                                        </button>

                                        <button
                                          onClick={handlePropertyRentPostDelete}
                                          className="h-8 px-2 rounded-md bg-red-400 "
                                        >
                                          Delete Post
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default RentPostManagement;
