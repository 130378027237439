import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-hot-toast";
import axios from "axios";
import { useUser } from "../../context/UserContext";
import { FaSort } from "react-icons/fa";
import { motion } from "framer-motion";

import ListLoading from "../../components/LocalUi/ListLoading";
import location from "../../img/icn/location.png";
import delete_pic from "../../img/icn/delete.png";

const UserSavedPosts = () => {
  const { user } = useUser();
  const [statusCode, setStatusCode] = useState();
  const navigate = useNavigate();
  const [SavedPosts, setSavedPosts] = useState([]);
  const [deletedPosts, setDeletedPosts] = useState([]);
  const userID = user.id;
  const userToken = localStorage.getItem("userToken");

  const renderListLoadingPage = () => {
    return <ListLoading />;
  };

  useEffect(() => {
    const handleFetchSavedPosts = async () => {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
      };

      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/profile/getSavePost`,
          config
        );
        setSavedPosts(response.data.SavedPosts);
        setDeletedPosts(response.data.notFoundIds);
        setStatusCode(response.status);
      } catch (error) {
        console.error("Error making Axios request:", error);
        toast.error(error.message);
      }
    };
    handleFetchSavedPosts();
  }, [userID]);

  const handleOpenDetail = (item) => {
    if (item.type === "sell") {
      navigate(`/Property-Buy-Sell-Details/${item.postId}`);
    }
    if (item.type === "rent") {
      navigate(`/Property-Rent-Details/${item.postId}`);
    }
    if (item.type === "group") {
      navigate(`/Group-Wise-Building-Details/${item.postId}`);
    }
    if (item.type === "interior") {
      navigate(`/Interior-Details/${item._id}`);
    }
  };

  const handleDeletePost = async (event, postId) => {
    event.stopPropagation();
    try {
      await axios.delete(
        `${process.env.REACT_APP_API_URL}/api/profile/deleteUserSavedPost/${postId}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );

      setSavedPosts((prevPosts) =>
        prevPosts ? prevPosts.filter((post) => post._id !== postId) : []
      );
      setDeletedPosts((prevNotFoundIds) =>
        prevNotFoundIds ? prevNotFoundIds.filter((id) => id !== postId) : []
      );
      toast.success("Post Removed");
    } catch (error) {
      console.error("Error deleting post:", error);
      toast.error("Error deleting post");
    }
  };

  const [isOpen, setIsOpen] = useState(false);
  const [isAscending, setIsAscending] = useState(true);
  const [filterType, setFilterType] = useState("");
  const [isRecent, setIsRecent] = useState(false);
  const timeoutRef = useRef(null);
  const componentRef = useRef(null);

  const handleMouseEnter = () => {
    setIsOpen(true);
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
  };

  const handleMouseLeave = () => {
    timeoutRef.current = setTimeout(() => {
      setIsOpen(false);
    }, 2000);
  };

  useEffect(() => {
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, []);

  // Sort and filter the posts based on the selected sort option and filter type
  let sortedPosts = [...SavedPosts];

  if (isRecent) {
    sortedPosts = sortedPosts.sort(
      (a, b) => new Date(b.postDate) - new Date(a.postDate)
    );
  } else {
    sortedPosts = isAscending ? sortedPosts : sortedPosts.reverse();
  }

  if (filterType) {
    sortedPosts = sortedPosts.filter((post) => post.type === filterType);
  }

  return (
    <div className="MainBody overflow-auto styleScrollbar duration-200">
      {statusCode !== 200 ? (
        <div className="min-h-[82vh] w-full flex justify-center items-center">
          <div className="space-y-3 px-2 w-full">{renderListLoadingPage()}</div>
        </div>
      ) : (
        <div className="MainBody min-h-[82vh] space-y-2 overflow-auto styleScrollbar duration-200">
          {/* Buttons to toggle sorting */}
          <div
            className="mb-6  text-xs md:text-sm font-semibold  inline-flex   text-gray-500"
            onMouseLeave={handleMouseLeave}
            ref={componentRef}
          >
            <button
              onMouseEnter={handleMouseEnter}
              className="px-2 py-1 bg-gray-200 hover:bg-gray-300 rounded-md flex items-center"
            >
              <FaSort className="mr-2" size={18} />
              Sort
            </button>

            {isOpen && (
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 0.5 }}
                className="ml-2   grid grid-cols-4 md:grid-cols-8 gap-2 duration-200 ease-in"
              >
                <button
                  onClick={() => {
                    setIsAscending(true);
                    setIsRecent(false);
                  }}
                  className={`p-1 rounded ${
                    isAscending && !isRecent
                      ? "bg-blue-500 text-white"
                      : "bg-gray-300"
                  }`}
                >
                  Ascending
                </button>
                <button
                  onClick={() => {
                    setIsAscending(false);
                    setIsRecent(false);
                  }}
                  className={`p-1 rounded ${
                    !isAscending && !isRecent
                      ? "bg-blue-500 text-white"
                      : "bg-gray-300"
                  }`}
                >
                  Descending
                </button>
                <button
                  onClick={() => {
                    setIsRecent(true);
                  }}
                  className={`p-1 rounded ${
                    isRecent ? "bg-blue-500 text-white" : "bg-gray-300"
                  }`}
                >
                  Recent
                </button>

                {/* Buttons to filter by post type */}

                <button
                  onClick={() => setFilterType("")}
                  className={`p-1 rounded ${
                    !filterType ? "bg-blue-500 text-white" : "bg-gray-300"
                  }`}
                >
                  All Posts
                </button>

                <button
                  onClick={() => setFilterType("sell")}
                  className={`p-1 rounded ${
                    filterType === "sell"
                      ? "bg-blue-500 text-white"
                      : "bg-gray-300"
                  }`}
                >
                  Sell
                </button>
                <button
                  onClick={() => setFilterType("rent")}
                  className={`p-1 rounded ${
                    filterType === "rent"
                      ? "bg-blue-500 text-white"
                      : "bg-gray-300"
                  }`}
                >
                  Rent
                </button>
                <button
                  onClick={() => setFilterType("group")}
                  className={`p-1 rounded ${
                    filterType === "group"
                      ? "bg-blue-500 text-white"
                      : "bg-gray-300"
                  }`}
                >
                  Group
                </button>
                <button
                  onClick={() => setFilterType("interior")}
                  className={`p-1 rounded ${
                    filterType === "interior"
                      ? "bg-blue-500 text-white"
                      : "bg-gray-300"
                  }`}
                >
                  Interior
                </button>
              </motion.div>
            )}
          </div>

          {/* Display the sorted posts */}
          {sortedPosts && sortedPosts.length > 0 ? (
            sortedPosts.map((post) => (
              <div className="flex space-x-10 duration-200" key={post._id}>
                <div className="w-full duration-200">
                  <div>
                    <div
                      className="border border-gray-400 rounded-lg flex justify-between items-center duration-200 p-1 md:p-2"
                      onClick={() => handleOpenDetail(post)}
                    >
                      <div className="flex duration-200">
                        <div className="absolute duration-200">
                          <div className="text-white px-1 py-1 bg-red-600 text-xs rounded-lg">
                            <p className="capitalize">{post.type}</p>
                          </div>
                        </div>

                        <img
                          src={post.imageUrl}
                          alt={post.postTitle}
                          className="mr-2 md:h-24 h-16 rounded-lg md:w-24 w-16"
                        />
                        <div className="cursor-pointer">
                          <div className="overflow-hidden">
                            <p className="line-clamp-2 font-bold md:text-base text-xs">
                              {post.postTitle}
                            </p>
                          </div>

                          <div className="flex space-x-1 items-center">
                            <img
                              src={location}
                              className="h-3 w-3 mt-1 md:mt-0 md:h-4 md:w-4"
                              alt="location icon"
                            />
                            <p className="md:text-base text-xs">
                              {post.type !== "interior" ? (
                                <>
                                  {post.subDistrict}, {post.district},{" "}
                                  {post.division}
                                </>
                              ) : (
                                <>
                                  {post.district}, {post.division}
                                </>
                              )}
                            </p>
                          </div>

                          {post.type !== "interior" ? (
                            <p className="md:text-base text-xs font-bold md:font-semibold text-[#1a9649]">
                              Tk. {post.price}
                            </p>
                          ) : null}
                        </div>
                      </div>
                      <div className="flex space-x-3 text-center">
                        <button
                          className=""
                          onClick={(event) => handleDeletePost(event, post._id)}
                        >
                          <img
                            src={delete_pic}
                            className="md:h-10 md:w-10 h-7 w-7"
                            alt="delete"
                          />
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))
          ) : (
            <div className="h-[70vh] w-full flex text-center justify-center items-center duration-200">
              <p className="lg:text-5xl md:text-xl font-bold text-gray-400">
                No Saved Posts Found
              </p>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default UserSavedPosts;
