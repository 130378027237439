import React from "react";
import axios from "axios";
import { Helmet } from "react-helmet";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { useUser } from "../../context/UserContext";

import { CgSpinner } from "react-icons/cg";
import { useTranslation } from "react-i18next";
import { toast } from "react-hot-toast";

import eyeOpen from "../../svg/eye-open.svg";
import eyeClosed from "../../svg/eye-closed.svg";

import Logo from "../../img/icn/Logo.png";

import signBg from "../../img/signbg.jpg";

const SignIn = () => {
  const navigate = useNavigate();

  const { t, i18n } = useTranslation();




  // Retrieve the stored URL from local storage
  const redirectUrl = localStorage.getItem("redirectUrl");
  const [loading, setLoading] = useState(false);
  //get value for Sign in
  const [number, setNumber] = useState("");

  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState("");
  const { refreshUserData } = useUser();

  useEffect(() => {
    if (localStorage.getItem("userToken")) {
      navigate("/profile");
    }
  });

  const togglePasswordVisibility = (field, e) => {
    e.preventDefault();
    if (field === "password") {
      setShowPassword(!showPassword);
    }
  };

  const loginHandler = async (e) => {
    e.preventDefault();
    setLoading(true);

    const config = {
      header: {
        "Content-Type": "application/json",
      },
    };

    try {
      const { data } = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/auth/login`,
        { number, password },
        config
      );

      // Set token in localStorage
      localStorage.setItem("userToken", data.token);

      // Refresh user data in context immediately after successful login
      await refreshUserData();

      // Handle redirect
      const redirectUrl = localStorage.getItem("redirectUrl");
      if (redirectUrl) {
        localStorage.removeItem("redirectUrl");
        navigate(redirectUrl);
      } else {
        navigate("/profile");
      }

      toast.success("Welcome Back!");
    } catch (error) {
      if (error.response) {
        if (error.response.data && error.response.data.success === false) {
          // If the server responds with a specific error message
          if (
            error.response.data.error === "Number or password isn't correct"
          ) {
            toast.error("Number or password isn't correct");
          } else {
            toast.error(error.response.data.message);
          }
        } else {
          // If there is a different kind of error
          toast.error("An unexpected error occurred. Please try again.");
        }
      } else if (error.request) {
        toast.error("Network error. Please try again.");
      } else {
        toast.error("An unexpected error occurred. Please try again.");
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <div
      className={`w-full h-[75vh] flex justify-center items-center duration-200 `}
    >
      <Helmet>
        <title>Sign-In - Loko Property</title>
      </Helmet>
      <div className=" mt-10 xl:w-[60%] lg:w-[80%] h-[520px] w-[95%] bg-gradient-to-b lg:bg-gradient-to-r from-black via-red-300 to-orange-200 lg:flex justify-center items-center shadow-xl rounded-[20px] duration-200">
        <div className="lg:h-full lg:w-[50%] lg:flex h-[150px] relative justify-center items-center">
          <img
            className=" opacity-30 h-full w-full lg:object-fill absolute lg:rounded-l-[20px] rounded-t-[20px]"
            src={signBg}
            alt="bg"
          />

          <div className="absolute lg:w-[400px] w-full lg:mt-0 mt-3">
            <img
              className=" m-auto block lg:h-[150px] h-[60px] lg:w-[150px]  w-[60px]  duration-300 mb-5"
              src={Logo}
              alt="logo"
            />

            <p
              className={`px-6  text-white lg:text-2xl text-lg text-center duration-300`}
            >
              {t("home.heading_1")}
            </p>
          </div>

          <div className="leftItem "></div>
        </div>

        <div className=" lg:w-[50%] mt-5  flex justify-center items-center  ">
          <div className="  ">
            <p className=" lg:text-2xl text-xl lg:text-black text-white font-bold duration-200">
              Login
            </p>

            <form
              className="lg:mt-10 mt-5 duration-200"
              onSubmit={loginHandler}
            >
              <input
                className="h-12 md:w-80  w-72  text-black border-[1px] rounded-xl border-cyan-900 placeholder-gray-600 p-3 text-lg"
                type="number"
                required
                id="number"
                placeholder="Phone number"
                onChange={(e) => setNumber(e.target.value)}
                value={number}
                tabIndex={1}
              />

              <div>
                <div className="my-3 relative">
                  <input
                    type={showPassword ? "text" : "password"}
                    required
                    className="h-12 md:w-80  w-72 text-black border-[1px] rounded-xl border-cyan-900 placeholder-gray-900 p-3 text-base"
                    placeholder="Enter password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                  <button
                    className="absolute top-1/2 right-3 transform -translate-y-1/2 focus:outline-none"
                    onClick={(e) => togglePasswordVisibility("password", e)}
                  >
                    <img
                      src={showPassword ? eyeOpen : eyeClosed}
                      alt={showPassword ? "Hide password" : "Show password"}
                      className="h-5 w-5 text-gray-400"
                    />
                  </button>
                </div>
              </div>

              <button
                type="submit"
                className="md:w-80  w-72 flex gap-1 items-center justify-center py-2.5 text-white rounded-xl bg-[#F59E0B] hover:bg-[#fd7200] duration-200"
              >
                {loading && (
                  <CgSpinner size={20} className="mt-1 animate-spin" />
                )}
                <span className="text-md">Login</span>
              </button>

              <div className="mt-9 mb-3 text-center ">
                <Link
                  to="/forgotpassword"
                  className="text-blue-600 cursor-pointer text-sm"
                >
                  <p className="text-blue-600 cursor-pointer text-sm">
                    {" "}
                    Forget password?
                  </p>
                </Link>

                <p className="inline-block text-sm">
                  {" "}
                  Haven't any account yet?
                </p>
                <Link
                  to="/Register"
                  className=" text-blue-600 font-bold italic"
                >
                  {" "}
                  Register now.
                </Link>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};
export default SignIn;
