import React from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";

function AboutUs() {
  return (
    <div>
      <Helmet>
        <title>About Us - Demo</title>
        <meta name="description" content="This is a demo page for the About Us section." />
      </Helmet>
      <h1>About Us</h1>
      <p>Welcome to the demo page of the About Us section.</p>
    </div>
  );
}

export default AboutUs;
