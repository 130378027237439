import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate, useLocation, Link, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useUser } from "../../context/UserContext";
import CardLoading from "../../components/LocalUi/HorizontalCardLoading";

import { toast } from "react-hot-toast";
import { Helmet } from "react-helmet";

//icons

import icn_email from "../../img/icn/icn_email.png";
import icn_gender from "../../img/icn/icn_gender.png";

import save from "../../img/icn/love.png";
import unsave from "../../img/icn/unsave.png";
import locations from "../../img/icn/location.png";
import camera from "../../img/icn/camera.png";
import bed from "../../img/icn/bed.png";
import icn_user from "../../img/icn/icn_user.png";
import bath from "../../img/icn/bath.png";
import area from "../../img/icn/area.png";
import garage from "../../img/icn/garage.png";
import conditions from "../../img/icn/conditions.png";

export const UsersPosts = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const { t, i18n } = useTranslation();

  const renderCardLoadingPage = () => {
    return <CardLoading />;
  };

  //get current user data
  const userToken = localStorage.getItem("userToken");

  //get user saved post
  const { user } = useUser();

  const [savedPosts, setSavedPosts] = useState([]);
  const [loading, setLoading] = useState(true);
  const userID = user?.id;
  const status = user?.status;

  const { id } = useParams();
  const [usersPosts, setUserPosts] = useState({ sellPosts: [], rentPosts: [] });

  //fetch cards data
  useEffect(() => {
    const response = `${process.env.REACT_APP_API_URL}/api/users/usersposts/${id}`;
    axios
      .get(response)
      .then((response) => {
        setUserPosts(response.data);

        setLoading(false);
      })

      .catch((error) => {
        console.error("Error fetching data:", error);
        setLoading(false);
      });
  }, [id]);

  const allPosts = [...usersPosts.sellPosts, ...usersPosts.rentPosts];

  //get user SavePost
  useEffect(() => {
    // Check if user exists before making the Axios request
    if (user) {
      const handleFetchSavedPosts = async () => {
        try {
          const response = await axios.get(
            `${process.env.REACT_APP_API_URL}/api/profile/getSavePost`,
            {
              headers: {
                Authorization: `Bearer ${userToken}`, // Ensure userToken is defined
              },
            }
          );
          setSavedPosts(response.data.SavedPosts);
        } catch (error) {
          console.error("Error making Axios request:", error);
        }
      };

      handleFetchSavedPosts();
    }
  }, [user]); // Run when the user changes

  //Save unsave Optimistic UI Update
  const handleSaveUnSavePost = async (event, postId) => {
    event.stopPropagation();

    if (!userToken) {
      localStorage.setItem("redirectUrl", location.pathname);
      toast.error("You have to login first");
      navigate("/SignIn");
    } else {
      const userID = user.id;
      const PostID = postId;
      const isPostSaved = savedPosts.some(
        (savedPost) => savedPost._id === PostID
      );

      try {
        if (isPostSaved) {
          // Optimistically update the UI
          setSavedPosts((prevPosts) =>
            prevPosts.filter((post) => post._id !== PostID)
          );
          toast.success("Post Unsaved");

          // Make the network request in the background
          await axios.delete(
            `${process.env.REACT_APP_API_URL}/api/profile/deleteUserSavedPost/${PostID}`,
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${userToken}`,
              },
            }
          );
        } else {
          // Optimistically update the UI
          const newSavedPost = { _id: PostID }; // Create a minimal representation of the saved post
          setSavedPosts((prevPosts) => [...prevPosts, newSavedPost]);
          toast.success("Post Saved");

          // Make the network request in the background
          const response = await axios.post(
            `${process.env.REACT_APP_API_URL}/api/profile/setSavePost`,
            { userID, PostID },
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${userToken}`,
              },
            }
          );

          // Update the UI with the full post details from the server response
          const savedPost = response.data.SavedPosts.find(
            (post) => post._id === PostID
          );
          setSavedPosts((prevPosts) =>
            prevPosts.map((post) => (post._id === PostID ? savedPost : post))
          );
        }
      } catch (error) {
        // Revert the optimistic UI update on error
        if (isPostSaved) {
          setSavedPosts((prevPosts) => [...prevPosts, { _id: PostID }]);
        } else {
          setSavedPosts((prevPosts) =>
            prevPosts.filter((post) => post._id !== PostID)
          );
        }

        console.error("Error saving/unsaving post:", error);
        if (error.response && error.response.data) {
          const errorMessage = error.response.data.error;
          console.error("Server error message:", errorMessage);
          //toast.error(`Error: ${errorMessage}`);
        } else {
          //toast.error("Error saving/unsaving post");
        }
      }
    }
  };

  // Function to handle opening detail view
  const handleOpenDetail = (item) => {
    if (item.type === "sell") {
      navigate(`/Property-Buy-Sell-Details/${item.PostID}`);
    } else if (item.type === "rent") {
      navigate(`/Property-Rent-Details/${item.PostID}`);
    }
  };

  function getRelativeTime(postDate) {
    const post = new Date(postDate);
    const now = new Date();
    const diffInSeconds = Math.floor((now - post) / 1000);

    if (diffInSeconds < 3600) {
      const minutes = Math.floor(diffInSeconds / 60);
      return `${minutes} minute${minutes !== 1 ? "s" : ""} ago`;
    } else if (diffInSeconds < 86400) {
      const hours = Math.floor(diffInSeconds / 3600);
      return `${hours} hour${hours !== 1 ? "s" : ""} ago`;
    } else {
      const days = Math.floor(diffInSeconds / 86400);
      return `${days} day${days !== 1 ? "s" : ""} ago`;
    }
  }

  return (
    <div className={`MainSection  min-h-screen  w-full flex justify-center`}>
      <Helmet>
        <title>
          {usersPosts && usersPosts.userData
            ? usersPosts.userData.name + "'s " + "Posts"
            : "User Posts"}{" "}
          - Loko Property
        </title>
      </Helmet>

      <div className="w-[95%] md:w-[90%] xl:w-[85%]">
        {/* Display loading indicator */}
        {loading ? (
          <div className=" w-full flex justify-center  items-center ">
            <div className="  px-2 w-full">{renderCardLoadingPage()}</div>
          </div>
        ) : allPosts.length === 0 ? (
          <div className="h-[50vh] w-full flex text-center justify-center items-center duration-200">
            <p className="text-xl xl:text-3xl 2xl:text-5xl font-bold text-gray-400">
              No post available
            </p>
          </div>
        ) : (
          <div className=" mt-5">
            <div className="mx-4 md:mx-0 bg-gradient-to-l p-2 my-1 mt-2 rounded-lg from-transparent to-[#40a5213f]">
              <p className="text-gray-600 text-sm md:text-lg lg:text-2xl font-semibold">
                {usersPosts.userData.name + "'s " + "Posts"}
              </p>
            </div>

            <div className=" md:flex">
              <div className="w-full md:w-[70%]">
                <div className=" items-center mt-5 duration-200 ">
                  {/* show post */}
                  <div className=" space-y-8 ">
                    {allPosts.map((item) => (
                      <PropertyCard
                        key={item._id}
                        item={item}
                        handleOpenDetail={handleOpenDetail}
                        handleSaveUnSavePost={handleSaveUnSavePost}
                        savedPosts={savedPosts}
                        getRelativeTime={getRelativeTime}
                      />
                    ))}
                  </div>
                </div>
              </div>

              <div className="md:w-[30%] mx-4 lg:mx-0 md:ml-3 md:mt-5">
                <div className="md:ml-3 ">
                  <div className="bg-white shadow-lg rounded-xl  p-8   md:mt-0 mt-10 ">
                    <div className="flex flex-col items-center">
                      {usersPosts.userData.proPic ? (
                        <img
                          src={usersPosts.userData.proPic}
                          className="w-24 h-24 rounded-full mb-4"
                          alt="Profile"
                        />
                      ) : (
                        <img
                          src={icn_user}
                          className="w-24 h-24 rounded-full mb-4"
                          alt="Profile"
                        />
                      )}
                      <h2 className="text-2xl font-bold mb-2">
                        {usersPosts.userData.name}
                      </h2>
                    </div>

                    <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 mt-6">
                      {usersPosts.userData.email && (
                        <div className="">
                          <div className="flex items-center mb-1 ">
                            <img
                              src={icn_email}
                              className="w-4 h-4 mr-2"
                              alt="Email"
                            />
                            <span className="font-medium">Email:</span>
                          </div>
                          <div className="text-gray-600">
                            {usersPosts.userData.email}
                          </div>
                        </div>
                      )}

                      <div>
                        <div className="flex items-center mb-1">
                          <img
                            src={icn_gender}
                            className="w-4 h-4 mr-2"
                            alt="Gender"
                          />
                          <span className="font-medium">Gender:</span>
                        </div>
                        <div className="text-gray-600">
                          {usersPosts.userData.gender}
                        </div>
                      </div>
                    </div>

                    <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 mt-6">
                      <div>
                        <div className="flex items-center mb-1">
                          {/* <img src={icn_email} className="w-4 h-4 mr-2" alt="Email" /> */}
                          <span className="font-medium"> Total Posts</span>
                        </div>

                        <div className="text-gray-600">
                          Sell Posts : {usersPosts.totalSellPosts}
                        </div>
                        <div className="text-gray-600">
                          Rent Posts : {usersPosts.totalRentPosts}
                        </div>
                      </div>

                      <div>
                        <div className="flex items-center mb-1">
                          {/* <img src={icn_gender} className="w-4 h-4 mr-2" alt="Gender" /> */}
                          <span className="font-medium">Account Status</span>
                        </div>
                        <div className="text-gray-600"> </div>
                      </div>
                    </div>

                    <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 mt-6">
                      <div className="">
                        <div className="flex items-center mb-1">
                          <span className="font-medium">About:</span>
                        </div>
                        <div className="text-gray-600">
                          {usersPosts.userData.about}
                        </div>
                      </div>

                      <div className="">
                        <div className="flex items-center mb-1">
                          <span className="font-medium">Occupation:</span>
                        </div>
                        <div className="text-gray-600">
                          {usersPosts.userData.occupation}
                        </div>
                      </div>
                    </div>

                    <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 mt-6">
                      {usersPosts.userData.address && (
                        <div className="">
                          <div className="flex items-center mb-1">
                            <span className="font-medium">Address:</span>
                          </div>
                          <div className="text-gray-600">
                            {usersPosts.userData.address}
                          </div>
                        </div>
                      )}

                      <div className="">
                        <div className="flex items-center mb-1">
                          <span className="font-medium">Join Date:</span>
                        </div>
                        <div className="text-gray-600">
                          {usersPosts.userData.joiningDate}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
const PropertyCard = ({
  item,
  handleOpenDetail,
  handleSaveUnSavePost,
  savedPosts,
  getRelativeTime,
}) => (
  <div
    onClick={() => handleOpenDetail(item)}
    className="md:flex cursor-pointer md:h-[300px] 2xl:h-[350px] m-3 md:mx-0 mx-5 md:max-w-[100%] shadow-xl rounded-xl myHover overflow-hidden hover:translate-y-[-5px] duration-300"
  >
    <div className="relative overflow-hidden h-[220px] md:h-full md:w-1/2">
      <img
        src={
          item.imageUrls.length > 0
            ? item.imageUrls[0]
            : "default-image-url.jpg"
        }
        alt="Property Image"
        className="md:w-full md:h-full md:object-cover transition-transform imageHover duration-500"
      />
      <div className="absolute top-0 w-full h-[40px] bg-gradient-to-b from-[#000000c2] to-transparent duration-200">
        <div className="flex justify-between items-center">
          <div className="flex h-7 space-x-1 m-3">
            <img src={camera} alt="camera" className="h-5 w-5 mt-[2px]" />
            <h2 className="text-white">{item.imageUrls.length}</h2>
          </div>
          <div className="text-white px-2 py-1 bg-red-600 text-xs mr-2 rounded-lg">
            <p className="capitalize">{item.type}</p>
          </div>
        </div>
      </div>
      <div className="px-3 h-[50px] w-full m-auto bottom-0 flex items-center justify-between absolute bg-gradient-to-b from-transparent to-[#000000e8] duration-200">
        <div className="flex items-center space-x-1 mb-2">
          <img
            src={item.userData.proPic ? item.userData.proPic : icn_user}
            alt="User Profile"
            className="h-5 lg:h-7 w-5 lg:w-7 rounded-full"
          />
          <p className="text-white p-2 text-sm">{item.userData.username}</p>
        </div>
        <button onClick={(event) => handleSaveUnSavePost(event, item._id)}>
          <div className="m-auto flex items-center justify-end h-10 w-10 px-1 cursor-pointer rounded-lg hover:scale-105 duration-200">
            <img
              src={
                savedPosts.some((savedPost) => savedPost._id === item._id)
                  ? save
                  : unsave
              }
              className="h-6 w-6 duration-200"
              alt="Save Icon"
            />
          </div>
        </button>
      </div>
    </div>
    <div className="md:w-1/2 md:p-4 p-2 bg-white relative flex flex-col h-full">
      <div className="2xl:text-2xl md:text-xl text-xl  text-[#666666] font-bold max-w-[100%] line-clamp-1">
        {item.postTitle}
      </div>
      <div className="flex justify-between bg-gradient-to-l px-2 my-1 mt-2 from-transparent to-[#40a5213f]">
        <p className="text-green-900 text-sm">
          {"Condition : "}
          {item.condition}
        </p>
      </div>
      <div className="mt-4">
        <div className="flex items-center">
          <img src={locations} className="h-4 w-4" alt="Location" />
          <p className="ml-2 lg:text-base text-[#666666] text-xs">
            {item.subDistrict}, {item.district}, {item.division}
          </p>
        </div>
        <div className="border-b-2 border-gray-300 mt-2"></div>
      </div>
      <div className="mt-2">
        <p className="text-xs font-semibold text-[#666666]">
          Type : {item.propertyType}
        </p>
      </div>

      <div className="flex 2xl:space-x-12 lg:space-x-6 space-x-4 mt-4 text-[#666666]  ">
        {item.propertyType === "Apartment/Flats" ||
        item.propertyType === "Land Sharing Flats" ||
        item.propertyType === "Duplex Home" ? (
          <div className="text-center md:font-semibold  flex flex-col items-center text-xs duration-200 ">
            <div>
              <p>Bedrooms</p>
            </div>
            <div className="flex space-x-1  mt-1">
              <img src={bed} alt="bed" className="h-5 w-5"></img>
              <p className=" text-center m-auto">{item.bedroom}</p>
            </div>
          </div>
        ) : null}

        {item.propertyType === "Apartment/Flats" ||
        item.propertyType === "Land Sharing Flats" ||
        item.propertyType === "Duplex Home" ||
        item.propertyType === "Commercial Restaurant" ||
        item.propertyType === "Commercial Shop" ||
        item.propertyType === "Commercial Office" ? (
          <div className="text-center md:font-semibold  flex flex-col items-center text-xs duration-200 ">
            <div>
              <p>Bathrooms</p>
            </div>
            <div className="flex space-x-1 mt-1">
              <img src={bath} alt="bed" className="h-5 w-5"></img>
              <p className=" text-center m-auto">{item.bathroom}</p>
            </div>
          </div>
        ) : null}

        {item.propertyType === "Commercial Office" ||
        item.propertyType === "Commercial Restaurant" ? (
          <div className="text-center md:font-semibold  flex flex-col items-center text-xs duration-200">
            <div>
              <p>Rooms</p>
            </div>
            <div className="flex space-x-1  mt-1">
              <img src={bed} alt="bed" className="h-5 w-5"></img>
              <p className=" text-center m-auto">{item.rooms}</p>
            </div>
          </div>
        ) : null}

        {item.propertyType !== "Commercial Factory" &&
        item.propertyType !== "Commercial Warehouse" &&
        item.propertyType !== "Land" ? (
          <div className="text-center md:font-semibold  flex flex-col items-center text-xs duration-200 ">
            <div>
              <p>Garage</p>
            </div>
            <div className="flex space-x-1  mt-1">
              <img src={garage} alt="bed" className="h-5 w-5"></img>
              <p className=" text-center m-auto">{item.garages}</p>
            </div>
          </div>
        ) : null}

        {item.propertyType === "Commercial Warehouse" ? (
          <div className="text-center md:font-semibold  flex flex-col items-center text-xs duration-200 ">
            <div>
              <p>Status</p>
            </div>
            <div className="flex space-x-1  mt-1">
              <img src={conditions} alt="bed" className="h-5 w-5"></img>
              <p className=" text-center m-auto">{item.construction}</p>
            </div>
          </div>
        ) : null}

        {item.propertyType === "Commercial Warehouse" ? (
          <div className="text-center md:font-semibold  flex flex-col items-center text-xs duration-200">
            <div>
              <p>Condition</p>
            </div>
            <div className="flex space-x-1  mt-1">
              <img src={conditions} alt="bed" className="h-5 w-5"></img>
              <p className=" text-center m-auto">{item.condition}</p>
            </div>
          </div>
        ) : null}

        {item.propertyType !== "Land" ? (
          <div className="text-center md:font-semibold  flex flex-col items-center text-xs duration-200 ">
            <div>
              <p>Area</p>
            </div>
            <div className="flex space-x-1  mt-1">
              <img src={area} alt="bed" className="h-5 w-5"></img>
              <p className=" text-center m-auto">{item.size} ft²</p>
            </div>
          </div>
        ) : null}

        {item.propertyType === "Land" ? (
          <div className="text-center md:font-semibold  flex flex-col items-center text-xs duration-200 ">
            <div>
              <p>Land Size</p>
            </div>
            <div className="flex space-x-1  mt-1">
              <img src={area} alt="bed" className="h-5 w-5"></img>
              <p className=" text-center m-auto">{item.landSize} (Katha)</p>
            </div>
          </div>
        ) : null}
      </div>

      <div className="mt-4 sm:mt-auto">
        <div className="flex justify-between  items-end">
          <div>
            <p className="font-bold text-gray-500">For Rent</p>
            <span className="text-md md:text-lg  lg:text-xl  font-semibold text-[#1a9649]">
              Tk {item.price}
            </span>
            {item.exPrice && (
              <span className="ml-2 text-[#808080b2] line-through font-semibold">
                Tk {item.exPrice}
              </span>
            )}
          </div>
          <p className="md:text-sm text-green-900 text-xs">
            {getRelativeTime(item.postDate)}
          </p>
        </div>
      </div>
    </div>
  </div>
);
export default UsersPosts;
