import { React, useRef, useState, useEffect } from "react";
import axios from "axios";
import { useTranslation } from "react-i18next";
import { CgSpinner } from "react-icons/cg";
import { motion, AnimatePresence } from "framer-motion";
import { FaTrash } from "react-icons/fa";
import ListLoading from "../../components/LocalUi/ListLoading";
import { toast } from "react-hot-toast";
import AdminNavBar from "../admin/AdminNavBar";
import { Helmet } from "react-helmet";

function NoticeBoard() {
  const token = localStorage.getItem("ad-t");
  const [loading, setLoading] = useState(false);

  ///Setting font style
  const { t, i18n } = useTranslation();


  const [noticeColor, setNoticeColor] = useState("Red");
  const [notice, setNotice] = useState("");

  const renderListLoadingPage = () => {
    return <ListLoading />;
  };

  //get Notice data to manage
  const [noticeData, setNoticeData] = useState([]);
  useEffect(() => {
    // Define the function to fetch Blog posts
    const fetchSiteNotice = async () => {
      try {
        // Make a GET request to your server endpoint
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/admin/getsitenotice`
        ); // Update the endpoint accordingly

        // Set the retrieved Blog posts in the component state
        setNoticeData(response.data.data);
        // setPageLoading(false);
      } catch (error) {
        // Handle errors if any
        console.error("Error fetching Site Notice:", error);
      }
    };

    fetchSiteNotice();
  }, []);

  //send notice to backend
  const handleSubmit = async (e) => {
    e.preventDefault(); // Prevent the default form submission
    setLoading(true);

    try {
      const formData = new FormData();

      formData.append("Notice", notice);
      formData.append("NoticeColor", noticeColor);

      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/admin/setsitenotice`,
        formData,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setNoticeData(response.data.data);
      setNotice("");
      setLoading(false);
      toast.success("Notice Added");
    } catch (error) {
      console.error("Error:", error);
    }
  };

  // Function to handle delete notice
  const handleDeleteNotice = async (color) => {
    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_API_URL}/api/admin/deletesitenotice`,
        {
          data: { color }, // Send the color to be deleted
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      // Assuming the response contains the updated noticeData
      setNoticeData(response.data.data);

      console.log("Notice deleted successfully");
    } catch (error) {
      console.error("Error deleting notice:", error);
    }
  };

  return (
    <div className={`MainBody bg-gray-100 min-h-screen `}>
      <Helmet>
        <title>Notice Board- Admin Panel</title>
      </Helmet>

      <div className="flex my-5 flex-col md:flex-row gap-2">
        <AdminNavBar activePage="NoticeBoard" />


        <div className=" w-full px-2">
          <div className="flex flex-col lg:flex-row gap-8">
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5 }}
              className="w-full lg:w-1/2"
            >
              <form onSubmit={handleSubmit} className="space-y-4">
                <div>
                  <label className="block text-sm font-medium text-gray-700">
                    Notice Priority
                  </label>
                  <select
                    onChange={(e) => setNoticeColor(e.target.value)}
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                  >
                    <option value="Red">Red</option>
                    <option value="Yellow">Yellow</option>
                    <option value="Green">Green</option>
                  </select>
                </div>
                <div>
                  <label className="block text-sm font-medium text-gray-700">
                    Add Notice
                  </label>
                  <textarea
                    onChange={(e) => setNotice(e.target.value)}
                    value={notice}
                    required
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                    rows="3"
                  ></textarea>
                </div>
                <div className="flex justify-end">
                  <motion.button
                    whileHover={{ scale: 1.05 }}
                    whileTap={{ scale: 0.95 }}
                    className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                    type="submit"
                    disabled={loading}
                  >
                    {loading ? (
                      <CgSpinner className="animate-spin mr-2" />
                    ) : null}
                    Add Notice
                  </motion.button>
                </div>
              </form>
            </motion.div>

            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: 0.2 }}
              className="w-full lg:w-1/2 space-y-4"
            >
              <h2 className="text-xl font-semibold">Manage Notice</h2>
              <AnimatePresence>
                {["Red", "Yellow", "Green"].map((color) => {
                  const notice = noticeData[`${color}Notice`];
                  if (!notice) return null;
                  return (
                    <motion.div
                      key={color}
                      initial={{ opacity: 0, y: -10 }}
                      animate={{ opacity: 1, y: 0 }}
                      exit={{ opacity: 0, y: -10 }}
                      transition={{ duration: 0.3 }}
                      className={`flex justify-between items-center p-4 rounded-lg ${
                        color === "Red"
                          ? "bg-red-100"
                          : color === "Yellow"
                          ? "bg-yellow-100"
                          : "bg-green-100"
                      }`}
                    >
                      <p className="flex-grow mr-4">{notice}</p>
                      <motion.button
                        whileHover={{ scale: 1.1 }}
                        whileTap={{ scale: 0.9 }}
                        onClick={() => handleDeleteNotice(color)}
                        className={`p-2 rounded-full ${
                          color === "Red"
                            ? "bg-red-200 hover:bg-red-300"
                            : color === "Yellow"
                            ? "bg-yellow-200 hover:bg-yellow-300"
                            : "bg-green-200 hover:bg-green-300"
                        }`}
                      >
                        <FaTrash />
                      </motion.button>
                    </motion.div>
                  );
                })}
              </AnimatePresence>
            </motion.div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default NoticeBoard;
