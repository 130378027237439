import { React, useRef, useState, useEffect } from "react";
import AdminNavBar from "./AdminNavBar";
import { motion } from "framer-motion";
import { Helmet } from "react-helmet";
import { FaPlus, FaTrash, FaEye } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { CgSpinner } from "react-icons/cg";
import { useTranslation } from "react-i18next";
import { toast } from "react-hot-toast";
import ListLoading from "../../components/LocalUi/ListLoading";
import delete_pic from "../../img/icn/delete.png";

import remove from "../../img/icn/remove.png";

function BlogPost() {
  const token = localStorage.getItem("ad-t");
  const navigate = useNavigate();
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [pageLoading, setPageLoading] = useState(true);

  //Language
  const { t, i18n } = useTranslation();


  const renderListLoadingPage = () => {
    return <ListLoading />;
  };

  //get value from Form
  const [vlogTitle, setVlogTitle] = useState("");
  const [vlogTopic, setVlogTopic] = useState("");
  const [vlogDetails, setVlogDetails] = useState("");
  const [vlogPostAs, setVlogPostAs] = useState("Admin");

  //files from image
  const [files, setFiles] = useState([]);
  ////Getting image from from ------------------
  const [dragActive, setDragActive] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const inputRef = useRef(null);

  function handleChange(e) {
    e.preventDefault();
    console.log("File has been added");

    const newFiles = [...files];

    if (e.target.files && e.target.files[0]) {
      for (let i = 0; i < e.target.files.length; i++) {
        const file = e.target.files[i];
        if (newFiles.length < 5) {
          newFiles.push(file);
        } else {
          setErrorMessage("Maximum 5 images allowed.");
          setTimeout(() => {
            setErrorMessage("");
          }, 5000);
          break;
        }
      }
    }
    setFiles(newFiles);
  }
  function handleDrop(e) {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);

    const newFiles = [...files];

    if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
      for (let i = 0; i < e.dataTransfer.files.length; i++) {
        const file = e.dataTransfer.files[i];
        if (newFiles.length < 5) {
          newFiles.push(file);
        } else {
          setErrorMessage("Maximum 5 images allowed.");
          setTimeout(() => {
            setErrorMessage("");
          }, 5000);
          break;
        }
      }
      setFiles(newFiles);
    }
  }

  function handleDragLeave(e) {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
  }
  function handleDragOver(e) {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(true);
  }
  function handleDragEnter(e) {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(true);
  }
  function removeFile(fileName, idx) {
    const newArr = [...files];
    newArr.splice(idx, 1);
    setFiles([]);
    setFiles(newArr);
  }
  function openFileExplorer() {
    inputRef.current.value = "";
    inputRef.current.click();
  }

  const handleInputChange = (e) => {
    setVlogDetails(e.target.value);
    if (e.target.value.length > 800) {
      setError("");
    } else {
      setError("");
    }
  };

  //send vlogPost to backend
  const handleSubmit = async (e) => {
    e.preventDefault(); // Prevent the default form submission
    setLoading(true);

    if (files.length === 0) {
      toast.error("Please select at least one image.");
      setLoading(false); // Stop loading
      return; // Stop the function
    }

    if (vlogDetails.length >= 800) {
      // Proceed with your form submission logic
      try {
        const formData = new FormData();
        formData.append("Author", vlogPostAs);
        formData.append("Title", vlogTitle);
        formData.append("Topic", vlogTopic);
        formData.append("Post", vlogDetails);

        // Function to resize an image to a maximum size of 1080x1920
        async function resizeImage(imageFile) {
          return new Promise(async (resolve, reject) => {
            const img = new Image();
            img.src = URL.createObjectURL(imageFile);
            img.onload = async () => {
              const maxWidth = 1080;
              const maxHeight = 1920;
              let width = img.width;
              let height = img.height;

              // Check if resizing is needed
              if (width > maxWidth || height > maxHeight) {
                if (width / maxWidth > height / maxHeight) {
                  height *= maxWidth / width;
                  width = maxWidth;
                } else {
                  width *= maxHeight / height;
                  height = maxHeight;
                }

                // Create a canvas to resize the image
                const canvas = document.createElement("canvas");
                canvas.width = width;
                canvas.height = height;
                const ctx = canvas.getContext("2d");
                ctx.drawImage(img, 0, 0, width, height);

                // Convert the canvas back to a Blob
                canvas.toBlob((blob) => {
                  resolve(blob);
                }, imageFile.type);
              } else {
                resolve(imageFile);
              }
            };

            img.onerror = (error) => {
              reject(error);
            };
          });
        }

        // Append each image individually as binary data
        for (let i = 0; i < files.length; i++) {
          const imageFile = files[i];
          const resizedImageBlob = await resizeImage(imageFile);
          formData.append("images", resizedImageBlob, imageFile.name);
        }

        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}/api/admin/setvlogpost`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: `Bearer ${token}`,
            },
          }
        );

        // Extract the newly created blog post from the response
        const newVlogPost = response.data.blogPosts[0]; // Assuming the new post is the first in the array
        setVlogPosts((prevPosts) => [newVlogPost, ...prevPosts]);

        // Reset form values
        setVlogTitle("");
        setVlogTopic("");
        setVlogDetails("");
        setFiles([]);
        toast.success("Vlog uploaded");
      } catch (error) {
        console.error("Error creating vlog post:", error);
        toast.error("Failed to upload vlog");
      }

      setLoading(false);
    } else {
      setError(
        `Body should be at least 800 characters. Inserted ${vlogDetails.length} characters.`
      );
      setTimeout(() => {
        setLoading(false);
      }, 800);
    }
  };

  //get vlog post data to manage
  const [vlogPosts, setVlogPosts] = useState([]);

  useEffect(() => {
    // Define the function to fetch vlog posts
    const fetchVlogPosts = async () => {
      try {
        // Make a GET request to your server endpoint
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/admin/getvlogpostadmin`
        ); // Update the endpoint accordingly

        // Set the retrieved vlog posts in the component state
        setVlogPosts(response.data.data);
        setPageLoading(false);
      } catch (error) {
        // Handle errors if any
        console.error("Error fetching vlog posts:", error);
      }
    };

    // Call the function to fetch vlog posts when the component mounts
    fetchVlogPosts();
  }, []);

  //delete vlog post
  const handleVlogPostPostDelete = (event, item) => {
    event.stopPropagation();
    const postId = item._id;
    axios
      .delete(
        `${process.env.REACT_APP_API_URL}/api/admin/deletevlogpost/${postId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        if (response.status === 200) {
          toast.success("Vlog Post deleted successfully");
          setVlogPosts((prevPosts) =>
            prevPosts.filter((item) => item._id !== postId)
          );
        } else {
          console.error("Failed to Vlog sell post:", response.data.error);
        }
      })
      .catch((error) => {
        console.error("Error deleting Vlog post:", error);
        toast.error("Error deleting Vlog post");
      });
  };

  const handleOpenDetail = (item) => {
    navigate(`/BlogPostDetails/${item}`);
  };

  return (
    <div className={`MainBody bg-gray-100 px-2 min-h-screen `}>
      <Helmet>
        <title>Manage Blog Posts - Admin Panel</title>
      </Helmet>
      <div className="flex flex-col my-5 lg:flex-row">
        <AdminNavBar activePage="BlogPost" />

        <div className="w-full  lg:p-8">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
            className="grid lg:grid-cols-2 gap-8"
          >
            {/* Create Blog Post Section */}
            <div className="bg-white rounded-lg shadow-lg p-6">
              <h2 className="text-2xl font-semibold mb-6">
                Create a Blog Post
              </h2>
              <form onSubmit={handleSubmit} encType="multipart/form-data">
                <div className="grid md:grid-cols-3 gap-4 mb-6">
                  <div>
                    <label className="block text-sm font-medium text-gray-700 mb-1">
                      Vlog Post Title
                    </label>
                    <input
                      required
                      onChange={(e) => setVlogTitle(e.target.value)}
                      value={vlogTitle}
                      type="text"
                      className="w-full p-2 border rounded-md focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                    />
                  </div>
                  <div>
                    <label className="block text-sm font-medium text-gray-700 mb-1">
                      Topic
                    </label>
                    <input
                      required
                      onChange={(e) => setVlogTopic(e.target.value)}
                      value={vlogTopic}
                      type="text"
                      className="w-full p-2 border rounded-md focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                    />
                  </div>
                  <div>
                    <label className="block text-sm font-medium text-gray-700 mb-1">
                      Post As
                    </label>
                    <select
                      onChange={(e) => setVlogPostAs(e.target.value)}
                      className="w-full p-2 border rounded-md focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                    >
                      <option value="admin">Admin</option>
                      <option value="LokoPorperty">Loko Porperty</option>
                    </select>
                  </div>
                </div>

                {/* Image upload section */}
                <div className="mb-6">
                  <label className="block text-sm font-medium text-gray-700 mb-2">
                    Upload Images
                  </label>
                  <div
                    className={`border-2 border-dashed rounded-lg p-4 text-center ${
                      dragActive
                        ? "border-blue-500 bg-blue-50"
                        : "border-gray-300"
                    }`}
                    onDragEnter={handleDragEnter}
                    onDragLeave={handleDragLeave}
                    onDragOver={handleDragOver}
                    onDrop={handleDrop}
                  >
                    <input
                      type="file"
                      ref={inputRef}
                      className="hidden"
                      onChange={handleChange}
                       accept=".jpg, .jpeg, .png"
                      multiple
                    />
                    <p className="text-gray-600">
                      Drag & Drop Image here or{" "}
                      <span
                        className="text-blue-500 cursor-pointer hover:underline"
                        onClick={openFileExplorer}
                      >
                        Select image
                      </span>{" "}
                      to upload <span className="text-gray-400">(max 5)</span>
                    </p>
                    {errorMessage && (
                      <p className="text-red-500 mt-2">{errorMessage}</p>
                    )}
                    <div className="grid grid-cols-5 gap-2 mt-4">
                      {files.map((file, idx) => (
                        <div key={idx} className="relative">
                          <img
                            src={URL.createObjectURL(file)}
                            alt={file.name}
                            className="w-full h-20 object-cover rounded"
                          />

                          <button
                            type="button"
                            onClick={() => removeFile(file.name, idx)}
                            className="absolute top-0 right-0 bg-red-500 text-white rounded-full p-1 transform translate-x-1/2 -translate-y-1/2"
                          >
                            <FaTrash size={12} />
                          </button>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>

                <div className="mb-6">
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    Body (at least 800 characters) (HTML Formate Supported)
                  </label>
                  {error && <p className="text-red-500 mb-1">{error}</p>}
                  <textarea
                    required
                    onChange={handleInputChange}
                    value={vlogDetails}
                    rows="6"
                    className="w-full p-2 border rounded-md focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                  ></textarea>
                </div>

                <button
                  type="submit"
                  className="flex items-center justify-center px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 transition duration-300"
                  disabled={loading}
                >
                  {loading && (
                    <CgSpinner size={20} className="animate-spin mr-2" />
                  )}
                  <span>Post</span>
                </button>
              </form>
            </div>

            {/* Manage Blog Post Section */}
            <div className="bg-white rounded-lg shadow-lg p-6">
              <h2 className="text-2xl font-semibold mb-6">Manage Blog Posts</h2>
              <div className="overflow-auto max-h-[600px]">
                {pageLoading ? (
                  <div className="space-y-3 px-2">
                    {renderListLoadingPage()}
                  </div>
                ) : vlogPosts.length === 0 ? (
                  <div className="text-center text-gray-500">
                    <p className="text-xl">No posts available</p>
                  </div>
                ) : (
                  vlogPosts.map((item) => (
                    <motion.div
                      key={item.PostID}
                      className="flex items-center cursor-pointer  bg-white border rounded-lg p-4 mb-4 hover:shadow-md transition duration-300"
                      //whileHover={{ scale: 1.02 }}
                      onClick={() => handleOpenDetail(item.PostID)}
                    >
                      <div className="flex-shrink-0 mr-4">
                        {item.imageUrls.length > 0 && (
                          <img
                            src={item.imageUrls[0]}
                            alt="Post thumbnail"
                            className="w-20 h-20 object-cover rounded"
                          />
                        )}
                      </div>
                      <div className="flex-grow">
                        <h3 className="font-semibold text-lg mb-1">
                          {item.Title}
                        </h3>
                        <p className="text-sm text-gray-600 mb-1">
                          Topic: {item.Topic}
                        </p>
                        <p className="text-sm text-gray-500 mb-2">
                          Posted on: {item.postDate}
                        </p>
                        <p className="text-sm text-gray-700 line-clamp-2">
                          {item.Post}
                        </p>
                      </div>
                      <div className="flex-shrink-0 ml-4">
                        <button
                          onClick={(event) =>
                            handleVlogPostPostDelete(event, item)
                          }
                          className="text-red-500 hover:text-red-700"
                        >
                          <FaTrash size={20} />
                        </button>
                      </div>
                    </motion.div>
                  ))
                )}
              </div>
            </div>
          </motion.div>
        </div>
      </div>
    </div>
  );
}

export default BlogPost;
