import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { CgSpinner } from "react-icons/cg";
import { Helmet } from "react-helmet";
import { motion } from "framer-motion";
import { FaBookOpen, FaClock, FaUser } from "react-icons/fa";
//import loading pages
import ListLoading from "../../components/LocalUi/ListLoading";
import PostLoading from "../../components/LocalUi/PostLoading";
import DOMPurify from "dompurify";

const BlogPosts = () => {
  const { postId } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [pageLoading, setPageLoading] = useState(true);
  const [posts, setPosts] = useState([]);
  const [page, setPage] = useState(1);
  const [selectedPost, setSelectedPost] = useState(null);

  const renderPostLoadingPage = () => {
    return <PostLoading />;
  };
  const renderListLoadingPage = () => {
    return <ListLoading />;
  };

  const fetchMorePosts = async () => {
    setLoading(true);
    const nextPage = page + 1;
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/admin/getvlogpost?page=${nextPage}&limit=5`
      );
      setPosts([...posts, ...response.data.items]);
      setPage(nextPage);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching more posts:", error);
    }
  };

  useEffect(() => {
    const fetchPosts = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/admin/getvlogpost?page=1&limit=5`
        );
        // Find the post with the received postId
        const postWithId = response.data.items.find(
          (post) => post.vlogData.postID === postId
        );

        if (postWithId) {
          setSelectedPost(postWithId);
        } else {
          setSelectedPost(response.data.items[0]);
          setPosts(response.data.items);
        }
        setPageLoading(false);
      } catch (error) {
        console.error("Error fetching posts:", error);
      }
    };

    fetchPosts();
  }, [postId]);

  const handlePostClick = (postId) => {
    const clickedPost = posts.find((post) => post.vlogData.postID === postId);
    setSelectedPost(clickedPost);
  };

  const continueReading = () => {
    if (selectedPost) {
      navigate(`/BlogPostDetails/${selectedPost.vlogData.postID}`);
    }
  };

  const sanitizeHTML = (html) => ({
    __html: DOMPurify.sanitize(html.replace(/\n/g, "<br>")),
  });

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5 }}
      className="w-full bg-gray-100 min-h-screen"
    >
      <Helmet>
        <title>Blog - Loko Property</title>
      </Helmet>
      <div className="w-full flex justify-center py-10">
        <div className="flex flex-wrap justify-between w-[98%] md:w-[90%] xl:w-[85%]">
          <motion.div
            className="left-sec 2xl:w-[60%] xl:w-[55%] lg:w-[50%] w-full bg-white rounded-lg shadow-lg p-6"
            initial={{ x: -50 }}
            animate={{ x: 0 }}
            transition={{ duration: 0.5 }}
          >
            {pageLoading ? (
              <PostLoading />
            ) : selectedPost === 0 ? (
              <div className="h-[80vh] flex items-center justify-center">
                <p className="text-3xl font-bold text-gray-400">
                  No post available
                </p>
              </div>
            ) : (
              selectedPost && (
                <motion.div
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.5 }}
                >
                  <span className="bg-blue-100 text-blue-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded">
                    {selectedPost.vlogData.postTopic}
                  </span>
                  <h1 className="text-3xl font-bold mt-2 mb-4">
                    {selectedPost.vlogData.postTitle}
                  </h1>
                  <div className="flex items-center text-gray-600 mb-4">
                    <FaUser className="mr-2" />
                    <span className="mr-4">
                      {selectedPost.userData.displayAuthorData.username}
                    </span>
                    <FaClock className="mr-2" />
                    <span>{selectedPost.vlogData.postDate}</span>
                  </div>
                  <img
                    onClick={continueReading}
                    className="w-full h-64 object-cover rounded-lg shadow-md mb-6 cursor-pointer"
                    src={selectedPost.vlogData.imageUrls[0]}
                    alt="blog photo"
                  />

                  <div>
                    <div
                      className="text-gray-700 mb-4"
                      dangerouslySetInnerHTML={sanitizeHTML(
                        selectedPost.vlogData.postBody.length > 750
                          ? selectedPost.vlogData.postBody.slice(0, 750) + "..."
                          : selectedPost.vlogData.postBody
                      )}
                    />
                    {selectedPost.vlogData.postBody.length > 750 && (
                      <button
                        onClick={continueReading}
                        className="flex items-center text-blue-500 hover:text-blue-700 transition duration-300"
                      >
                        <FaBookOpen className="mr-2" />
                        Continue Reading
                      </button>
                    )}
                  </div>


                </motion.div>
              )
            )}
          </motion.div>

          <motion.div
            className="right-sec lg:mt-0 mt-10 lg:w-[35%] w-full"
            initial={{ x: 50 }}
            animate={{ x: 0 }}
            transition={{ duration: 0.5 }}
          >
            <h2 className="text-2xl font-bold mb-6 text-gray-800">
              Spot Light
            </h2>
            {pageLoading ? (
              <div className="space-y-3 px-2">
                <ListLoading />
              </div>
            ) : posts.length === 0 ? (
              <div className="h-[80vh] flex items-center justify-center">
                <p className="text-3xl font-bold text-gray-400">
                  No posts available
                </p>
              </div>
            ) : (
              <div className="h-[65vh] overflow-y-auto pr-4">
                {posts.map((post, index) => (
                  <motion.div
                    key={index}
                    className="mb-4 bg-white rounded-lg shadow-md p-4 cursor-pointer hover:shadow-lg transition duration-300"
                    onClick={() => handlePostClick(post.vlogData.postID)}
                    whileHover={{ scale: 1.03 }}
                  >
                    <span className="bg-blue-100 text-blue-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded">
                      {post.vlogData.postTopic}
                    </span>
                    <h3 className="font-semibold text-lg mt-2 mb-2">
                      {post.vlogData.postTitle}
                    </h3>

                    <div
                      className="text-gray-600 text-sm line-clamp-2"
                      dangerouslySetInnerHTML={sanitizeHTML(
                        post.vlogData.postBody
                      )}
                    />
                  </motion.div>
                ))}
                <div className="flex justify-center mt-6">
                  <button
                    className="flex items-center justify-center px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 transition duration-300"
                    onClick={fetchMorePosts}
                    disabled={loading}
                  >
                    {loading ? (
                      <CgSpinner size={20} className="animate-spin mr-2" />
                    ) : null}
                    <span>Load more...</span>
                  </button>
                </div>
              </div>
            )}
          </motion.div>
        </div>
      </div>
    </motion.div>
  );
};

export default BlogPosts;
