import {
  createContext,
  useContext,
  useEffect,
  useState,
  useCallback,
} from "react";
import axios from "axios";
import { toast } from "react-hot-toast";

const UserContext = createContext();

export function useUser() {
  return useContext(UserContext);
}

export function UserProvider({ children }) {
  const [user, setUser] = useState(null);
  const [error, setError] = useState(null);
  const [profileStatus, setProfileStatus] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  // Create a function to fetch user data
  const fetchUserData = useCallback(async () => {
    const token = localStorage.getItem("userToken");
    if (!token) {
      setUser(null);
      setIsLoading(false);
      return;
    }

    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };

      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/profile`,
        config
      );

      setUser(response.data.userData);
      setProfileStatus(response.data.userData.status);
      localStorage.setItem("userToken", response.data.userData.userToken);

      if (response.data.userData.status === "Disabled") {
        localStorage.removeItem("userToken");
        setUser(null);
      }

      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching user information:", error);
      localStorage.removeItem("userToken");
      setUser(null);
      setIsLoading(false);
    }
  }, []);

  // Function to handle login
  const login = useCallback(
    async (credentials) => {
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}/api/login`,
          credentials
        );

        localStorage.setItem("userToken", response.data.userToken);
        // Fetch user data immediately after successful login
        await fetchUserData();
        return { success: true };
      } catch (error) {
        console.error("Login error:", error);
        return {
          success: false,
          error: error.response?.data?.message || "Login failed",
        };
      }
    },
    [fetchUserData]
  );

  // Function to handle logout
  const logout = useCallback(() => {
    localStorage.removeItem("userToken");
    setUser(null);
    setProfileStatus(null);
  }, []);

  // Initial load
  useEffect(() => {
    fetchUserData();
  }, [fetchUserData]);

  // Create a refresh function that components can call
  const refreshUserData = useCallback(() => {
    return fetchUserData();
  }, [fetchUserData]);

  return (
    <UserContext.Provider
      value={{
        user,
        error,
        isLoading,
        profileStatus,
        login,
        logout,
        refreshUserData,
      }}
    >
      {children}
    </UserContext.Provider>
  );
}
