import React, { useEffect, useState } from "react";
import locationData from "../../../Location.json";
import { useUser } from "../../../context/UserContext";
import { useNavigate, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

import axios from "axios";
import CardLoading from "../../../components/LocalUi/HorizontalCardLoading";
import { toast } from "react-hot-toast";

import logo from "../../../img/icn/Logo.png";
import save from "../../../img/icn/love.png";
import unsave from "../../../img/icn/unsave.png";

import premium from "../../../img/icn/premium.png";

import bg2 from "../../../img/interior-bg.jpg";
import camera from "../../../img/icn/camera.png";
import { Helmet } from "react-helmet";

//icon
import { CgSpinner } from "react-icons/cg";
import locations from "../../../img/icn/location.png";

import interior_advertise_en from "../../../img/interior_advertise_en.jpg";
import interior_advertise_bn from "../../../img/interior_advertise_bn.jpg";

import cancel from "../../../img/icn/cancel.png";

import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "../../../components/ui/tooltip";

const InteriorProperty = () => {
  const renderCardLoadingPage = () => {
    return <CardLoading />;
  };

  //get current user data
  const { user } = useUser();
  const userToken = localStorage.getItem("userToken");

  const navigate = useNavigate();
  const location = useLocation();

  //language
  const { t, i18n } = useTranslation();

  ///Setting font style
  const font = localStorage.getItem("lng");
  let setFont;
  let interior_advertise;

  if (font == "bn") {
    interior_advertise = interior_advertise_bn;
  } else if (font == "en") {
    interior_advertise = interior_advertise_en;
  }

  const [savedPosts, setSavedPosts] = useState([]);

  //get user SavePost
  useEffect(() => {
    // Check if user exists before making the Axios request
    if (user) {
      const handleFetchSavedPosts = async () => {
        try {
          const response = await axios.get(
            `${process.env.REACT_APP_API_URL}/api/profile/getSavePost`,
            {
              headers: {
                Authorization: `Bearer ${userToken}`, // Ensure userToken is defined
              },
            }
          );
          setSavedPosts(response.data.SavedPosts);
        } catch (error) {
          console.error("Error making Axios request:", error);
        }
      };

      handleFetchSavedPosts();
    }
  }, [user]); // Run when the user changes

  const queryParams = new URLSearchParams(location.search);
  const currentPageQueryParam = queryParams.get("page");
  const initialPage = currentPageQueryParam
    ? parseInt(currentPageQueryParam)
    : 1;

  const [currentPage, setCurrentPage] = useState(initialPage);
  const postPerPage = 8;
  const [loading, setLoading] = useState(true);
  const [dataFromAPI, setDataFromAPI] = useState([]);
  const [totalPages, setTotalPages] = useState(1);

  const [searchLoading, setSearchLoading] = useState(false);

  const [totalItems, setTotalItems] = useState();

  // Function to fetch data based on page and limit
  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/InteriorPostCard?page=${currentPage}&limit=${postPerPage}`
      );
      setDataFromAPI(response.data.items);
      setTotalPages(Math.ceil(response.data.totalItems / postPerPage));
      setLoading(false);
    } catch (error) {
      console.error("Error:", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [currentPage]);

  const updateURL = (page) => {
    const newParams = new URLSearchParams(queryParams);
    newParams.set("page", page);
    window.history.replaceState(
      null,
      null,
      `${location.pathname}?${newParams.toString()}`
    );
  };

  // Function to handle opening detail view
  const handleOpenDetail = (item) => {
    console.log(item._id);
    navigate(`/Interior-Details/${item.PostID}`);
  };

  //Search
  const [searchText, setSearchText] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [suggestions, setSuggestions] = useState([]);

  const [divisionId, setDivisionId] = useState("");
  const [district, setDistrict] = useState([]);
  const [districtId, setDistrictId] = useState("");

  const handleDivision = (e) => {
    const getDivisionId = e.target.value;
    // Check if "Select Division" is selected
    if (getDivisionId === "") {
      // Reset the district state
      setDistrict([]);
    } else {
      // Find the selected division and set the district data
      const getDistrictData = locationData.find(
        (division) => division.division_id === getDivisionId
      ).district;
      setDistrict(getDistrictData);
    }
    // Set the divisionId
    setDivisionId(getDivisionId);
  };

  // Modify the handleDistrict function to set sub-districts
  const handleDistrict = (e) => {
    const districtId = e.target.value;
    setDistrictId(districtId);
  };

  useEffect(() => {
    const fetchSuggestions = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/propertyInteriorSearch?searchText=${searchText}&division=${divisionId}&district=${districtId}
        `
        );
        setSuggestions(response.data.items);
      } catch (error) {
        console.error(error);
      }
    };

    // Only fetch suggestions if there is text in the input
    if (searchText.trim() !== "") {
      fetchSuggestions();
    } else {
      setSuggestions([]); // Clear suggestions if the input is empty
    }
  }, [searchText]);

  const handleSearch = async () => {
    setSearchLoading(true);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/propertyInteriorSearch?searchText=${searchText}&division=${divisionId}&district=${districtId}`
      );
      if (response.data.items.length === 0) {
        toast.error("No result found");
      }
      setTotalItems(response.data.totalItems);
      setSearchResults(response.data.items);
      setSuggestions([]);
      setSearchLoading(false);
    } catch (error) {
      console.error(error);
    }
  };

  const handleClear = () => {
    setSearchText("");
    setSearchResults([]);
    setSuggestions([]);
  };

  //Save unsave Optimistic UI Update
  const handleSaveUnSavePost = async (event, postId) => {
    event.stopPropagation();

    if (!userToken) {
      localStorage.setItem("redirectUrl", location.pathname);
      toast.error("You have to login first");
      navigate("/SignIn");
    } else {
      const userID = user.id;
      const PostID = postId;
      const isPostSaved = savedPosts.some(
        (savedPost) => savedPost._id === PostID
      );

      try {
        if (isPostSaved) {
          // Optimistically update the UI
          setSavedPosts((prevPosts) =>
            prevPosts.filter((post) => post._id !== PostID)
          );
          toast.success("Post Unsaved");

          // Make the network request in the background
          await axios.delete(
            `${process.env.REACT_APP_API_URL}/api/profile/deleteUserSavedPost/${PostID}`,
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${userToken}`,
              },
            }
          );
        } else {
          // Optimistically update the UI
          const newSavedPost = { _id: PostID }; // Create a minimal representation of the saved post
          setSavedPosts((prevPosts) => [...prevPosts, newSavedPost]);
          toast.success("Post Saved");

          // Make the network request in the background
          const response = await axios.post(
            `${process.env.REACT_APP_API_URL}/api/profile/setSavePost`,
            { userID, PostID },
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${userToken}`,
              },
            }
          );

          // Update the UI with the full post details from the server response
          const savedPost = response.data.SavedPosts.find(
            (post) => post._id === PostID
          );
          setSavedPosts((prevPosts) =>
            prevPosts.map((post) => (post._id === PostID ? savedPost : post))
          );
        }
      } catch (error) {
        // Revert the optimistic UI update on error
        if (isPostSaved) {
          setSavedPosts((prevPosts) => [...prevPosts, { _id: PostID }]);
        } else {
          setSavedPosts((prevPosts) =>
            prevPosts.filter((post) => post._id !== PostID)
          );
        }

        console.error("Error saving/unsaving post:", error);
        if (error.response && error.response.data) {
          const errorMessage = error.response.data.error;
          console.error("Server error message:", errorMessage);
          //toast.error(`Error: ${errorMessage}`);
        } else {
          //toast.error("Error saving/unsaving post");
        }
      }
    }
  };

  function getRelativeTime(postDate) {
    const post = new Date(postDate);
    const now = new Date();
    const diffInSeconds = Math.floor((now - post) / 1000);

    if (diffInSeconds < 3600) {
      const minutes = Math.floor(diffInSeconds / 60);
      return `${minutes} minute${minutes !== 1 ? "s" : ""} ago`;
    } else if (diffInSeconds < 86400) {
      const hours = Math.floor(diffInSeconds / 3600);
      return `${hours} hour${hours !== 1 ? "s" : ""} ago`;
    } else {
      const days = Math.floor(diffInSeconds / 86400);
      return `${days} day${days !== 1 ? "s" : ""} ago`;
    }
  }

  return (
    <div
      className={`MainSection ${setFont} w-full min-h-screen flex justify-center`}
    >
      <Helmet>
        <title>Interior Service - Loko Property</title>
      </Helmet>

      <div className="w-[98%] md:w-[90%] xl:w-[85%]">
        <div className="mt-5 h-[50vh] sm:h-[40vh] md:h-[50vh] relative">
          <div className="h-full">
            <img
              src={bg2}
              className=" h-full w-full object-cover absolute"
              alt="background"
            />
          </div>

          <div className="TextSection absolute bottom-0 w-full px-4 sm:px-6 md:px-10 lg:px-20 py-4   ">
            <div>
              <p className="text-2xl md:text-3xl lg:text-4xl 2xl:text-5xl font-bold text-white drop-shadow-lg">
                {t("interior_property.heading_1")}
              </p>
            </div>

            <div className="mt-3 sm:mt-4 md:mt-5">
              <p className="text-lg md:text-xl 2xl:text-2xl lg:font-bold text-white">
                {t("interior_property.heading_2")}
              </p>
            </div>

            <div className="mt-3 sm:mt-3 md:mt-4">
              <p className="text-sm sm:text-base md:text-md text-white line-clamp-3 sm:line-clamp-4 md:line-clamp-none">
                {t("interior_property.paragraph_1")}
              </p>
            </div>

            {/* search section*/}
            <div className="relative mt-4 sm:mt-6 md:mt-8 lg:mt-10 w-full duration-200">
              <div className="flex justify-center w-full duration-200">
                <div className="w-full sm:w-[90%] md:w-[80%] lg:w-[70%] xl:w-[60%] bg-[#ffffff7c] rounded-lg p-4 flex flex-col sm:flex-row items-center space-y-4 sm:space-y-0 sm:space-x-4">
                  {/* Location */}
                  <div className="w-full sm:w-auto">
                    <div className="flex flex-col sm:flex-row space-y-2 sm:space-y-0 sm:space-x-2">
                      <select
                        name="Division"
                        required
                        className="w-full px-1 sm:w-auto h-10 font-semibold text-gray-500 bg-gray-50 border border-gray-300 rounded-xl focus:ring-gray-500 focus:border-gray-500 block"
                        onChange={(e) => handleDivision(e)}
                      >
                        <option value="" className="text-pink-600">
                          Select Division
                        </option>
                        {locationData.map((getDivision, index) => (
                          <option value={getDivision.division_id} key={index}>
                            {getDivision.division_name}
                          </option>
                        ))}
                      </select>

                      {district.length > 0 && (
                        <select
                          name="states"
                          className="w-full px-1  sm:w-auto h-10 bg-gray-50 border font-semibold text-gray-500 border-gray-300 rounded-xl focus:ring-gray-500 focus:border-gray-500 block"
                          onChange={(e) => handleDistrict(e)}
                        >
                          <option value="" className="text-pink-600">
                            Select District
                          </option>
                          {district.map((getDistrict, index) => (
                            <option value={getDistrict.district_id} key={index}>
                              {getDistrict.district_name}
                            </option>
                          ))}
                        </select>
                      )}
                    </div>
                  </div>

                  <div className="w-full sm:w-auto flex-grow flex items-center space-x-2">
                    <input
                      type="text"
                      className="w-full h-10 border border-gray-500 rounded-xl p-2"
                      value={searchText}
                      onChange={(e) => setSearchText(e.target.value)}
                      placeholder="Company Name, District, Division"
                    />

                    <button
                      onClick={handleSearch}
                      className="h-10 px-3 bg-white border border-gray-500 font-semibold text-gray-500 rounded-xl flex items-center space-x-2"
                    >
                      {searchLoading && (
                        <CgSpinner size={20} className="animate-spin" />
                      )}
                      <span>Search</span>
                    </button>

                    {(searchText.trim() !== "" ||
                      suggestions.length > 0 ||
                      searchResults.length > 0) && (
                      <button
                        onClick={handleClear}
                        className="h-10 w-10 flex items-center justify-center cursor-pointer"
                      >
                        <img className="h-6 w-6" src={cancel} alt="Clear" />
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Display loading indicator */}
        {loading ? (
          <div className="w-full flex justify-center items-center">
            <div className="px-2 w-full">{renderCardLoadingPage()}</div>
          </div>
        ) : dataFromAPI.length === 0 ? (
          <div className="h-[50vh] w-full flex text-center justify-center items-center duration-200">
            <p className="text-xl sm:text-2xl md:text-3xl lg:text-4xl xl:text-5xl font-bold text-gray-400">
              No post available
            </p>
          </div>
        ) : (
          <div className="md:flex  ">
            <div className="w-full md:w-[70%] ">
              {suggestions.length > 0 ? (
                <div>
                  <p className="text-center my-5 text-yellow-700 bg-yellow-100">
                    Matching with....
                  </p>

                  <div className=" space-y-8 ">
                    {suggestions.map((item) => (
                      <InteriorCard
                        key={item._id}
                        item={item}
                        handleOpenDetail={handleOpenDetail}
                        handleSaveUnSavePost={handleSaveUnSavePost}
                        savedPosts={savedPosts}
                        getRelativeTime={getRelativeTime}
                      />
                    ))}
                  </div>
                </div>
              ) : searchResults && searchResults.length > 0 ? (
                <div>
                  <p className="text-center my-5 text-green-700 bg-green-200">
                    {totalItems} result found
                  </p>
                  <div className=" space-y-8 ">
                    {searchResults.map((item) => (
                      <InteriorCard
                        key={item._id}
                        item={item}
                        handleOpenDetail={handleOpenDetail}
                        handleSaveUnSavePost={handleSaveUnSavePost}
                        savedPosts={savedPosts}
                        getRelativeTime={getRelativeTime}
                      />
                    ))}
                  </div>
                </div>
              ) : dataFromAPI && dataFromAPI.length > 0 ? (
                <div className=" items-center mt-5 duration-200 ">
                  {/* main post */}
                  <div className=" space-y-8 ">
                    {dataFromAPI.map((item) => (
                      <InteriorCard
                        key={item._id}
                        item={item}
                        handleOpenDetail={handleOpenDetail}
                        handleSaveUnSavePost={handleSaveUnSavePost}
                        savedPosts={savedPosts}
                        getRelativeTime={getRelativeTime}
                      />
                    ))}
                  </div>

                  {/* Pagination buttons */}
                  {totalPages > 1 && (
                    <div className="pagination text-center mt-8 mb-8">
                      <div className="flex justify-center items-center space-x-2">
                        {currentPage > 1 && (
                          <button
                            className="pagination-button h-8 w-24 rounded-md border border-green-600 duration-200"
                            onClick={() => {
                              if (currentPage > 1) {
                                setCurrentPage(currentPage - 1);
                                updateURL(currentPage - 1);
                              }
                            }}
                          >
                            {"« Previous"}
                          </button>
                        )}

                        {Array.from({ length: totalPages }).map((_, index) => (
                          <button
                            key={index}
                            className={`pagination-button h-8 w-8 rounded-md border border-green-600 duration-200 ${
                              currentPage === index + 1
                                ? "active bg-green-400"
                                : ""
                            }`}
                            onClick={() => {
                              setCurrentPage(index + 1);
                              updateURL(index + 1);
                            }}
                          >
                            {index + 1}
                          </button>
                        ))}

                        {currentPage < totalPages && (
                          <button
                            className="pagination-button h-8 w-24 rounded-md border border-green-600 duration-200"
                            onClick={() => {
                              if (currentPage < totalPages) {
                                setCurrentPage(currentPage + 1);
                                updateURL(currentPage + 1);
                              }
                            }}
                          >
                            {"Next »"}
                          </button>
                        )}
                      </div>
                    </div>
                  )}
                </div>
              ) : (
                <div></div>
              )}
            </div>
            <div className="md:w-[30%]  mx-6 lg:mx-0 md:ml-3 md:mt-5 mt-10 ">
              <div className="md:ml-3 ">
                <div className=" p-3 shadow-md text-center rounded-xl bg-white">
                  <h2 className="text-md lg:text-lg 2xl:text-2xl  md:px-10   font-bold text-gray-500">
                    Community Supporters
                  </h2>
                  <div
                    className={` w-full flex justify-center items-center duration-200 `}
                  >
                    <div>
                      <p className=" text-justify mt-5 mb-3 text-sm text-gray-500 ">
                        Lorem Ipsum is simply dummy text of the printing and
                        typesetting industry. Lorem Ipsum has been the
                        industry's standard dummy text ever since the 1500s,
                      </p>

                      <img src={interior_advertise} alt="Sponsor" />

                      <button className="bg-yellow-300 hover:bg-yellow-400 text-black font-bold py-2 px-4 rounded-full mt-5">
                        View More
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

const InteriorCard = ({
  item,
  handleOpenDetail,
  handleSaveUnSavePost,
  savedPosts,
  getRelativeTime,
}) => {
  const selectionsCount = Object.values(item.selections).filter(Boolean).length;
  return (
    <div
      onClick={() => handleOpenDetail(item)}
      className="md:flex cursor-pointer md:h-[300px] 2xl:h-[350px] m-3 md:mx-0 mx-5 md:max-w-[100%] shadow-xl rounded-xl myHover overflow-hidden hover:translate-y-[-5px] duration-300"
    >
      <div className="relative overflow-hidden h-[220px] md:h-full md:w-1/2">
        <img
          src={item.coverPhoto || "default-image-url.jpg"}
          alt="Property Image"
          className="md:w-full md:h-full md:object-cover transition-transform imageHover duration-500"
        />

        <div className="absolute top-0 w-full h-[40px] bg-gradient-to-b from-[#000000c2] to-transparent duration-200">
          <div className="flex justify-between items-center">
            <div className="flex h-7 space-x-1 m-3">
              <img src={camera} alt="camera" className="h-5 w-5 mt-[2px]" />
              <h2 className="text-white">{selectionsCount}</h2>
            </div>
            <div className="text-white px-2 py-1 bg-red-600 text-xs mr-2 rounded-lg">
              <p className="capitalize">{item.type}</p>
            </div>
          </div>
        </div>

        <div className="px-3 h-[50px] w-full m-auto bottom-0 flex items-center justify-between absolute bg-gradient-to-b from-transparent to-[#000000e8] duration-200">
          <div className="flex items-center space-x-1 mb-2">
            <img
              src={logo}
              alt="logo"
              className="h-5 lg:h-7 w-5 lg:w-7 rounded-full"
            />
            <p className="text-white p-2 text-sm">Loko Property</p>
          </div>
          <button onClick={(event) => handleSaveUnSavePost(event, item._id)}>
            <div className="m-auto flex items-center justify-end h-10 w-10 px-1 cursor-pointer rounded-lg hover:scale-105 duration-200">
              <img
                src={
                  savedPosts.some((savedPost) => savedPost._id === item._id)
                    ? save
                    : unsave
                }
                className="h-6 w-6 duration-200"
                alt="Save Icon"
              />
            </div>
          </button>
        </div>
      </div>

      <div className="md:w-1/2 md:p-4 p-2 bg-white relative flex flex-col h-full">
        <div className="2xl:text-2xl md:text-xl text-xl  text-[#666666] font-bold max-w-[100%] line-clamp-1">
          {item.companyName}
        </div>

        <div className="flex justify-between bg-gradient-to-l px-2 my-1 mt-2 from-transparent to-[#40a5213f]">
          <p className="text-green-900 text-sm line-clamp-2">
            {item.companySlogan}
          </p>
        </div>
        <div className="mt-4">
          <div className="flex items-center">
            <img src={locations} className="h-4 w-4" alt="Location" />
            <p className="ml-2 lg:text-base text-[#666666] text-xs">
              {item.subDistrict} {item.district}, {item.division}
            </p>
          </div>
          <div className="border-b-2 border-gray-300 mt-2"></div>
        </div>
        <div className="mt-2">
          <p className="text-xs font-semibold text-[#666666] capitalize">
            Type : {item.type}
          </p>
        </div>

        <div className="mt-2">
          <div className="flex justify-between bg-gradient-to-l px-2 my-2 mt-2 from-transparent to-[#40a5213f]">
            <p className="text-green-900 text-sm line-clamp-2">
              Available services
            </p>
          </div>

          <div className="flex flex-wrap">
            {Object.entries(item.selections)
              .filter(([key, value]) => value === true)
              .slice(0, 5)
              .map(([key, value]) => (
                <span
                  key={key}
                  className="bg-green-200 text-green-900 px-2 py-1 rounded-full mr-2 mb-2 text-xs"
                >
                  {key.charAt(0).toUpperCase() + key.slice(1)}
                </span>
              ))}
          </div>
        </div>

        <div className="mt-4 sm:mt-auto">
          <div className="flex justify-between  items-end">
            {/* <div>
              <p className="font-bold text-gray-500">For Rent</p>
              <span className="text-md md:text-lg  lg:text-xl  font-semibold text-[#1a9649]">
                Tk {item.price}
              </span>
              {item.exPrice && (
                <span className="ml-2 text-[#808080b2] line-through font-semibold">
                  Tk {item.exPrice}
                </span>
              )}
            </div> */}

            {/* <p className="md:text-sm text-green-900 text-xs">
              {getRelativeTime(item.postDate)}
            </p> */}

            {/* <div className="absolute bottom-3 left-4">
              <div className="flex items-center">
                <img src={call} className="h-5 w-5 mr-2" alt="Phone" />
                <p className="text-sm">{item.number}</p>
              </div>
            </div> */}

            {item.isPremium && (
              <div className="absolute bottom-2 right-2 lg:bottom-5 lg:right-5">
                <TooltipProvider>
                  <Tooltip>
                    <TooltipTrigger asChild>
                      <div className="flex items-center">
                        <img
                          src={premium}
                          className="h-8 w-8 mr-2"
                          alt="Premium star"
                        />
                      </div>
                    </TooltipTrigger>
                    <TooltipContent>
                      <p className=" text-red-600 ">Premium</p>
                    </TooltipContent>
                  </Tooltip>
                </TooltipProvider>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default InteriorProperty;
