import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useUser } from "../../context/UserContext";

import icn_user from "../../img/icn/icn_user.png";
import Logo from "../../img/icn/Logo.png";
import icn_home from "../../img/icn/home.png";
import icn_vlogger from "../../img/icn/vlogger.png";
import icn_about from "../../img/icn/info.png";
import icn_lang from "../../img/icn/lang.png";
import icn_support from "../../img/icn/support.png";
import SidebarModal from "./SidebarModal"; // Import the new SidebarModal component

const Navbar = () => {
  const { user } = useUser();
  const { t, i18n } = useTranslation();

  const handleChangeLng = (lng) => {
    i18n.changeLanguage(lng);
    localStorage.setItem("lng", lng);
  };

  const GetLng = localStorage.getItem("lng");
  let lang;
  if (GetLng === "bn") {
    lang = "বাংলা";
  } else {
    lang = "English";
  }

  const [isOpen, setIsOpen] = useState(false);

  const [scrolling, setScrolling] = useState(false);
  const [scrollTop, setScrollTop] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      setScrollTop(document.documentElement.scrollTop);
      setScrolling(document.documentElement.scrollTop > scrollTop);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [scrollTop]);

  // Add Tailwind CSS classes based on scrolling state
  const navbarClasses = `fixed top-0 w-full transition-transform duration-200 ${
    scrolling ? "-translate-y-full" : "translate-y-0"
  }`;
  return (
    <>
      <nav
        className={`${navbarClasses}  bg-white shadow-lg sticky w-full flex justify-center top-0 z-50 duration-200 `}
      >
        <div className="w-[98%] lg:w-[95%] xl:w-[85%]  flex items-center justify-between  px-2 py-1  duration-200">
          <div className="flex items-center ">
            <Link to="https://lokoproperty.com" className="flex items-center">
              <img src={Logo} className="h-8 w-8 lg:h-10 lg:w-10" alt="Logo" />
              <span className="text-xl md:text-3xl font-bold text-[#f5882b] ml-2">
                LOKO PROPERTY
              </span>
            </Link>
          </div>

          <div className="hidden lg:flex space-x-4 text-sm font-semibold text-gray-700">
            <div className="nav-link-container  flex">
              <Link
                to="https://lokoproperty.com/Home"
                className="flex items-center p-1 rounded"
              >
                <img
                  src={icn_home}
                  className="h-4 w-4 mb-[3px] mr-1"
                  alt="home"
                />
                {t("navbar.home")}
              </Link>
            </div>

            <div className="nav-link-container flex">
              <Link to="/Blog" className="flex items-center p-1 rounded">
                <img
                  src={icn_vlogger}
                  className="h-4 w-4 mb-[3px] mr-1"
                  alt="Blog"
                />
                {t("navbar.Blog")}
              </Link>
            </div>

            <div className="nav-link-container flex ">
              <Link
                to="/CustomerCare"
                className="flex items-center p-1 rounded"
              >
                <img
                  src={icn_support}
                  className="h-4 w-4 mb-[3px] mr-1"
                  alt="Customer Care"
                />
                {t("navbar.customer_care")}
              </Link>
            </div>

            <div className="nav-link-container flex">
              <Link to="/AboutUs" className="flex items-center p-1 rounded">
                <img
                  src={icn_about}
                  className="h-4 w-4 mb-[3px] mr-1"
                  alt="About"
                />
                {t("navbar.about_us")}
              </Link>
            </div>

            <div className="nav-link-container flex">
              <div
                className="flex items-center p-1 rounded cursor-pointer"
                onClick={() => handleChangeLng(GetLng === "bn" ? "en" : "bn")}
              >
                <img
                  src={icn_lang}
                  className="h-4 w-4 mb-[3px] mr-1"
                  alt="Language"
                />
                {lang}
              </div>
            </div>

            <div className="nav-link-container flex">
              <Link to="/DownloadApp" className="flex items-center p-1 rounded">
                {t("navbar.download_app")}
              </Link>
            </div>

            {user ? (
              <Link to="/Profile" className="flex items-center p-1 rounded">
                <img
                  src={user.proPic || icn_user}
                  className="h-8 w-8 rounded-full"
                  alt="Profile"
                />
              </Link>
            ) : (
              <Link
                to="/SignIn"
                onClick={() => localStorage.removeItem("redirectUrl")}
                className="bg-amber-500 text-white py-1 px-4 rounded hover:text-yellow-900"
              >
                {t("navbar.login")}
              </Link>
            )}
          </div>

          <div className="lg:hidden flex items-center ">
            <button
              onClick={() => setIsOpen(!isOpen)}
              className="text-black-500  hover:text-black-400"
            >
              <svg
                className="h-8 w-8"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M4 6h16M4 12h16M4 18h16"
                />
              </svg>
            </button>
          </div>
        </div>
      </nav>
      <SidebarModal
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        t={t}
        handleChangeLng={handleChangeLng}
        GetLng={GetLng}
        lang={lang}
        user={user}
      />
    </>
  );
};

export default Navbar;
