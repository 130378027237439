import React from "react";

function HorizontalCardLoading() {
  return (
    <div className=" space-y-3 px-2">
      <div className=" p-3 w-full duration-200">

        <div className="animate-pulse h-[280px]  md:max-w-[70%] max-w-[100%]  mt-4 mb-2">
          <div className=" p-5  bg-slate-300 rounded-xl  col-span-2">
            <div className="  h-16 w-16 bg-slate-400 rounded-full  "></div>

            <div className="animate-pulse mt-5 flex justify-between  ">
              <div className="h-2 bg-slate-400 rounded col-span-2"></div>
              <div className="flex-1  space-y-6 ">
                <div className="h-2 bg-slate-500 rounded"></div>
                <div className="space-y-3">
                  <div className="grid grid-cols-3 gap-4">
                    <div className="h-2 bg-slate-400 rounded col-span-2"></div>
                    <div className="h-2 bg-slate-400 rounded col-span-1"></div>
                  </div>
                  <div className="h-2 bg-slate-500 rounded"></div>
                </div>
              </div>
              <div className="flex-1 space-y-6 ">
                <div className="h-2 bg-slate-500 rounded"></div>
                <div className="space-y-3">
                  <div className="grid grid-cols-3 gap-4">
                    <div className="h-2 bg-slate-500 rounded col-span-2"></div>
                    <div className="h-2 bg-slate-500 rounded col-span-1"></div>
                  </div>
                  <div className="h-2 bg-slate-500 rounded"></div>
                </div>
              </div>
            </div>

            <div className="animate-pulse mt-5 flex justify-between  ">
              <div className="h-2 bg-slate-500 rounded col-span-2"></div>
              <div className="flex-1  space-y-6 ">
                <div className="h-2 bg-slate-500 rounded"></div>
                <div className="space-y-3">
                  <div className="grid grid-cols-3 gap-4">
                    <div className="h-2 bg-slate-500 rounded col-span-2"></div>
                    <div className="h-2 bg-slate-500 rounded col-span-1"></div>
                  </div>
                  <div className="h-2 bg-slate-500 rounded"></div>
                </div>
              </div>
              <div className="flex-1 space-y-6 ">
                <div className="h-2 bg-slate-500 rounded"></div>
                <div className="space-y-3">
                  <div className="grid grid-cols-3 gap-4">
                    <div className="h-2 bg-slate-500 rounded col-span-2"></div>
                    <div className="h-2 bg-slate-500 rounded col-span-1"></div>
                  </div>
                  <div className="h-2 bg-slate-500 rounded"></div>
                </div>
              </div>
            </div>


          </div>
        </div>

        <div className="animate-pulse h-[280px]  md:max-w-[70%] max-w-[100%] mb-2">
          <div className=" p-5  bg-slate-400 rounded-xl  col-span-2">
            <div className="  h-16 w-16 bg-slate-600 rounded-full  "></div>

            <div className="animate-pulse mt-5 flex justify-between  ">
              <div className="h-2 bg-slate-500 rounded col-span-2"></div>
              <div className="flex-1  space-y-6 ">
                <div className="h-2 bg-slate-500 rounded"></div>
                <div className="space-y-3">
                  <div className="grid grid-cols-3 gap-4">
                    <div className="h-2 bg-slate-500 rounded col-span-2"></div>
                    <div className="h-2 bg-slate-500 rounded col-span-1"></div>
                  </div>
                  <div className="h-2 bg-slate-500 rounded"></div>
                </div>
              </div>
              <div className="flex-1 space-y-6 ">
                <div className="h-2 bg-slate-500 rounded"></div>
                <div className="space-y-3">
                  <div className="grid grid-cols-3 gap-4">
                    <div className="h-2 bg-slate-500 rounded col-span-2"></div>
                    <div className="h-2 bg-slate-500 rounded col-span-1"></div>
                  </div>
                  <div className="h-2 bg-slate-500 rounded"></div>
                </div>
              </div>
            </div>

            <div className="animate-pulse mt-5 flex justify-between  ">
              <div className="h-2 bg-slate-500 rounded col-span-2"></div>
              <div className="flex-1  space-y-6 ">
                <div className="h-2 bg-slate-500 rounded"></div>
                <div className="space-y-3">
                  <div className="grid grid-cols-3 gap-4">
                    <div className="h-2 bg-slate-500 rounded col-span-2"></div>
                    <div className="h-2 bg-slate-500 rounded col-span-1"></div>
                  </div>
                  <div className="h-2 bg-slate-500 rounded"></div>
                </div>
              </div>
              <div className="flex-1 space-y-6 ">
                <div className="h-2 bg-slate-500 rounded"></div>
                <div className="space-y-3">
                  <div className="grid grid-cols-3 gap-4">
                    <div className="h-2 bg-slate-500 rounded col-span-2"></div>
                    <div className="h-2 bg-slate-500 rounded col-span-1"></div>
                  </div>
                  <div className="h-2 bg-slate-500 rounded"></div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="animate-pulse h-[280px]  md:max-w-[70%] max-w-[100%] mb-2">
          <div className=" p-5  bg-slate-400 rounded-xl  col-span-2">
            <div className="  h-16 w-16 bg-slate-600 rounded-full  "></div>

            <div className="animate-pulse mt-5 flex justify-between  ">
              <div className="h-2 bg-slate-500 rounded col-span-2"></div>
              <div className="flex-1  space-y-6 ">
                <div className="h-2 bg-slate-500 rounded"></div>
                <div className="space-y-3">
                  <div className="grid grid-cols-3 gap-4">
                    <div className="h-2 bg-slate-500 rounded col-span-2"></div>
                    <div className="h-2 bg-slate-500 rounded col-span-1"></div>
                  </div>
                  <div className="h-2 bg-slate-500 rounded"></div>
                </div>
              </div>
              <div className="flex-1 space-y-6 ">
                <div className="h-2 bg-slate-500 rounded"></div>
                <div className="space-y-3">
                  <div className="grid grid-cols-3 gap-4">
                    <div className="h-2 bg-slate-500 rounded col-span-2"></div>
                    <div className="h-2 bg-slate-500 rounded col-span-1"></div>
                  </div>
                  <div className="h-2 bg-slate-500 rounded"></div>
                </div>
              </div>
            </div>

            <div className="animate-pulse mt-5 flex justify-between  ">
              <div className="h-2 bg-slate-500 rounded col-span-2"></div>
              <div className="flex-1  space-y-6 ">
                <div className="h-2 bg-slate-500 rounded"></div>
                <div className="space-y-3">
                  <div className="grid grid-cols-3 gap-4">
                    <div className="h-2 bg-slate-500 rounded col-span-2"></div>
                    <div className="h-2 bg-slate-500 rounded col-span-1"></div>
                  </div>
                  <div className="h-2 bg-slate-500 rounded"></div>
                </div>
              </div>
              <div className="flex-1 space-y-6 ">
                <div className="h-2 bg-slate-500 rounded"></div>
                <div className="space-y-3">
                  <div className="grid grid-cols-3 gap-4">
                    <div className="h-2 bg-slate-500 rounded col-span-2"></div>
                    <div className="h-2 bg-slate-500 rounded col-span-1"></div>
                  </div>
                  <div className="h-2 bg-slate-500 rounded"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default HorizontalCardLoading;
