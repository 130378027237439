import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

// icons
import icn_arrow_down from "../../img/icn/arrow_down.png";
import icn_dashboard from "../../img/icn/dashboard.png";
import icn_admin from "../../img/icn/admin.png";
import icn_notifications from "../../img/icn/notifications.png";
import icn_vlog from "../../img/icn/vlogger.png";
import icn_userManage from "../../img/icn/userManage.png";
import icn_postManage from "../../img/icn/postManage.png";
import icn_notice from "../../img/icn/notice.png";
import icn_siteSettings from "../../img/icn/SiteSettings.png";
import icn_logout from "../../img/icn/logout.png";
import AdminSidebar from "./AdminSidebar"; // Import the sidebar component

function AdminNavBar({ activePage }) {
  const navigate = useNavigate();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const menuItems = [
    { name: "Dashboard", icon: icn_dashboard, label: "Dashboard" },
    { name: "AdminInfo", icon: icn_admin, label: "Admin" },
    { name: "Notifications", icon: icn_notifications, label: "Notifications" },
    { name: "BlogPost", icon: icn_vlog, label: "Blog Post" },
    { name: "UserManage", icon: icn_userManage, label: "User Manage" },
    {
      name: "SellPostManage",
      icon: icn_postManage,
      label: "Sell Posts Manage",
    },
    {
      name: "RentPostManage",
      icon: icn_postManage,
      label: "Rent Posts Manage",
    },
    {
      name: "Group-Building-Manage",
      icon: icn_postManage,
      label: "Group Building",
      hasDropdown: true,
      dropdownItems: [
        {
          name: "Group-Building-Desired-Request",
          label: "User Desired Request",
        },
      ],
    },
    { name: "Interior", icon: icn_postManage, label: "Interior Manage" },
    { name: "NoticeBoard", icon: icn_notice, label: "Notice Board" },
    { name: "SiteSettings", icon: icn_siteSettings, label: "Site Settings" },
  ];

  const handleItemClick = (name) => {
    if (name === "LogOut") {
      localStorage.removeItem("ad-t");
      localStorage.removeItem("ad-n");
      navigate("/Home");
      window.location.reload();
    } else {
      navigate(`/admin/${name}`);
    }
  };

  const handleDropdownToggle = (e) => {
    e.stopPropagation(); // Prevent the click from propagating to the parent div
    setIsDropdownOpen(!isDropdownOpen);
  };

  const handleResize = () => {
    setIsMobile(window.innerWidth < 768);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };

  if (isMobile) {
    return (
      <>
        <div className=" ">
          <button
            onClick={() => setIsOpen(!isOpen)}
            className="text-black-500 hover:text-black-400 "
          >
            <svg
              className="h-8 w-8"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M4 6h16M4 12h16M4 18h16"
              />
            </svg>
          </button>
        </div>
        <AdminSidebar
          isOpen={isOpen}
          onClose={() => setIsOpen(false)}
          activePage={activePage}
        />
      </>
    );
  }

  return (
    <div className="LeftSideBar h-full lg:w-[20%] w-[78px] lg:mx-6 items-center text-center rounded-[25px] p-2 bg-gradient-to-r from-orange-200 via-purple-200 to-white shadow-md duration-200">
      {menuItems.map((item) => (
        <React.Fragment key={item.name}>
          <div
            className={`text-xl h-12 my-3 flex justify-center items-center relative rounded-[15px] duration-200 shadow-md cursor-pointer ${
              activePage === item.name
                ? "bg-orange-100 border border-orange-400"
                : ""
            }`}
            onClick={() => handleItemClick(item.name)}
          >
            <div className=" flex items-center">
              <img
                src={item.icon}
                className="h-6 w-6 absolute left-2"
                alt={item.label}
              />
              <div className="lg:text-[14px] font-semibold text-gray-600 hidden lg:block duration-200">
                {item.label}
              </div>
            </div>

            {item.hasDropdown && (
              <img
                src={icn_arrow_down}
                className={`h-8 w-7 absolute right-3 transition-transform duration-200 ${
                  isDropdownOpen ? "rotate-180" : ""
                }`}
                alt="Dropdown"
                onClick={handleDropdownToggle}
              />
            )}
          </div>

          {item.hasDropdown && isDropdownOpen && (
            <div className="px-2  flex justify-center rounded-[15px]  border-b border-orange-500 shadow-md hover:bg-gray-100 duration-200">
              {item.dropdownItems.map((dropdownItem) => (
                <div
                  key={dropdownItem.name}
                  className="text-lg h-10  flex items-center  cursor-pointer duration-200 "
                  onClick={() => handleItemClick(dropdownItem.name)}
                >
                  <div className="  lg:text-[14px] hidden lg:block duration-200">
                    {dropdownItem.label}
                  </div>
                </div>
              ))}
            </div>
          )}
        </React.Fragment>
      ))}

      <div
        className="m-auto lg:mt-8 mt-5 flex items-center justify-evenly h-12 lg:w-[115px] px-1 bg-red-300 rounded-[25px] cursor-pointer hover:scale-105 hover:bg-red-500 hover:text-white hover:shadow-lg duration-200"
        onClick={() => handleItemClick("LogOut")}
      >
        <img src={icn_logout} className="h-9 w-9 duration-200" alt="Logout" />
        <div className="lg:text-[16px] hidden lg:block duration-200">
          Logout
        </div>
      </div>
    </div>
  );
}

export default AdminNavBar;
