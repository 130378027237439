import React from "react";
const ResetPassword = () => {
  return (
    <div className="MainSection h-[72vh]  w-screen  flex justify-center text-center">
      <h1> reset password here</h1>
    
    </div>
  );
};

export default ResetPassword;