import React from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
const PageNotFound = () => {
  return (

      <div className="pnf flex text-center items-center lg:h-[80.5vh] h-[60vh] w-full "  >
        <Helmet>
        <title>Page Not Found - Loko Property</title>
      </Helmet>

        <div className="lg:h-[40.5vh] w-full ">

        <h1 className="pnf-title lg:text-[80px]">404</h1>
        <h2 className="pnf-heading lg:text-[100px]">Oops ! Page Not Found</h2>
        <Link to="/home" className="pnf-btn lg:text-[50px]">
          Go Back
        </Link>

        </div>

      </div>

  );
};

export default PageNotFound;