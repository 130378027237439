import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import AdminNavBar from "../../AdminNavBar";
import ListLoading from "../../../../components/LocalUi/ListLoading";

const GroupBuildingDesiredRequest = () => {
  const { t, i18n } = useTranslation();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const token = localStorage.getItem("ad-t");


  const renderListLoadingPage = () => {
    return <ListLoading />;
  };

  const fetchData = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/admin/group-building-desired-data`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setData(response.data);
      setLoading(false);
    } catch (error) {
      setError(error);
      setLoading(false);
    }
  };

  const deleteRecord = async (id) => {
    try {
      await axios.delete(
        `${process.env.REACT_APP_API_URL}/api/admin/group-building-desired/${id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setData(data.filter((item) => item._id !== id));
      setShowModal(false);
    } catch (error) {
      console.error("Error deleting data:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  if (error) return <div>Error loading data</div>;

  return (
    <div className={`MainBody  `}>
      <Helmet>
        <title>Desired Request - Admin Panel</title>
      </Helmet>

      <div className="md:flex md:my-5 my-1 ">
        <AdminNavBar activePage="Group-Building-Desired-Request" />
        <div className="w-full">
          <div className={` bg-gray-100`}>
            <div className="flex flex-col my-5 lg:flex-row">
              <div className="flex-grow p-6">
                <h1 className="text-2xl font-bold mb-4">
                  Group Building Desired Requests
                </h1>

                {loading ? (
                  <div className="h-full w-full flex justify-center items-center">
                    <div className="space-y-3 px-2 w-full">
                      {renderListLoadingPage()}
                    </div>
                  </div>
                ) : (
                  <table className="w-full border-collapse border border-gray-300">
                    <thead>
                      <tr className="bg-gray-200">
                        <th className="border border-gray-300 p-2">Division</th>
                        <th className="border border-gray-300 p-2">Location</th>
                        <th className="border border-gray-300 p-2">Number</th>
                        <th className="border border-gray-300 p-2">
                          Post Time
                        </th>
                        <th className="border border-gray-300 p-2">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {data.map((item) => (
                        <tr key={item._id}>
                          <td className="border text-center border-gray-300 p-2">
                            {item.division}
                          </td>
                          <td className="border text-center border-gray-300 p-2">
                            {item.location}
                          </td>
                          <td className="border text-center border-gray-300 p-2">
                            {item.number}
                          </td>
                          <td className="border text-center border-gray-300 p-2">
                            {new Date(item.postTime).toLocaleString()}
                          </td>
                          <td className="border text-center border-gray-300 p-2">
                            <button
                              onClick={() => {
                                setDeleteId(item._id);
                                setShowModal(true);
                              }}
                              className="bg-red-500 text-white px-2 py-1 rounded"
                            >
                              Delete
                            </button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                )}
              </div>
            </div>
          </div>

          {showModal && (
            <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full z-50 flex items-center justify-center">
              <div className="bg-white p-5 rounded-lg">
                <h2 className="text-xl mb-4">Confirm Deletion</h2>
                <p>Are you sure you want to delete this record?</p>
                <div className="mt-4 flex justify-end">
                  <button
                    onClick={() => setShowModal(false)}
                    className="bg-gray-300 text-black px-4 py-2 rounded mr-2"
                  >
                    Cancel
                  </button>
                  <button
                    onClick={() => deleteRecord(deleteId)}
                    className="bg-red-500 text-white px-4 py-2 rounded"
                  >
                    Delete
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default GroupBuildingDesiredRequest;
