import { React, useRef, useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import locationData from "../../../Location.json";
import Multiselect from "multiselect-react-dropdown";
import axios from "axios";
import { useUser } from "../../../context/UserContext";
import { CgSpinner } from "react-icons/cg";
import { useTranslation } from "react-i18next";
import { toast } from "react-hot-toast";
import remove from "../../../img/icn/remove.png";

const RentPost = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  ///Language
  const { t, i18n } = useTranslation();


  ///Setting font style
  const font = localStorage.getItem("lng");
  let setFont;
  if (font == "bn") {
    setFont = "font-TrioBangla";
  } else if (font == "en") {
    setFont = "font-Poppins-Regular";
  }

  const [divisionId, setDivisionId] = useState("");
  const [district, setDistrict] = useState([]);
  const [subDistrict, setSubDistrict] = useState([]);
  const [districtId, setDistrictId] = useState("");
  const [subDistrictId, setSubDistrictId] = useState("");

  const handleDivision = (e) => {
    const getDivisionId = e.target.value;
    // Check if "Select Division" is selected
    if (getDivisionId === "") {
      // Reset the district state
      setDistrict([]);
    } else {
      // Find the selected division and set the district data
      const getDistrictData = locationData.find(
        (division) => division.division_id === getDivisionId
      ).district;
      setDistrict(getDistrictData);
    }
    // Set the divisionId
    setDivisionId(getDivisionId);
  };

  // Modify the handleDistrict function to set sub-districts
  const handleDistrict = (e) => {
    const districtId = e.target.value;
    setDistrictId(districtId);

    // Find the selected district in the locationData
    const selectedDistrict = locationData
      .find((division) => division.division_id === divisionId)
      .district.find((district) => district.district_id === districtId);

    // Extract and set the sub-districts for the selected district
    if (selectedDistrict && selectedDistrict.sub_district) {
      setSubDistrict(selectedDistrict.sub_district);
    } else {
      // If there are no sub-districts, reset the subDistrict state
      setSubDistrict([]);
    }
  };

  // Define the handleSubDistrict function to set the sub-district ID
  const handleSubDistrict = (e) => {
    const subDistrictId = e.target.value;
    setSubDistrictId(subDistrictId);
  };

  const { user } = useUser();
  // Check if user is available
  let userName;
  let userNumber;
  if (user) {
    // Log the user's name to the console
    userName = user.name;
    userNumber = user.number;
  }

  const userToken = localStorage.getItem("userToken");

  //get value from Form
  const [error, setError] = useState("");
  const [postTitle, setPostTitle] = useState("");
  const [postDetails, setPostDetails] = useState("");

  //files from image
  const [files, setFiles] = useState([]);

  const primaryNumber = userNumber;
  const [secondaryNumber, setSecondaryNumber] = useState("");
  const [price, setPrice] = useState("");
  const [exPrice, setExPrice] = useState("");
  const [size, setSize] = useState("");
  const [landSize, setLandSize] = useState("");

  //dropDown
  const [propertyType, setPropertyType] = useState("Apartment/Flats");
  const [construction, setConstruction] = useState("Ready");
  const [unit, setUnit] = useState("1");
  const [condition, setCondition] = useState("New");
  const [rooms, setRooms] = useState("1");
  const [bedroom, setBedroom] = useState("1");
  const [bathroom, setBathroom] = useState("1");
  const [balconies, setBalconies] = useState("1");
  const [lift, setLift] = useState("No Lift");
  const [drawingRooms, setDrawingRooms] = useState("Yes");
  const [garages, setGarages] = useState("Yes");
  const [totalFloor, setTotalFloor] = useState("1");

  const [furnishing, setFurnishing] = useState("Furnished");
  const [facing, setFacing] = useState("North Facing");

  //MultiOptions
  const [multiOptions, setMultiOptions] = useState([
    "1",
    "2",
    "3",
    "4",
    "5",
    "6",
    "7",
    "8",
    "9",
    "10",
    "11",
    "12",
    "13",
    "14",
    "15",
    "16",
    "17",
    "18",
    "19",
    "20",
    "21",
    "22",
    "23",
    "24",
    "25",
    "26",
    "27",
    "28",
    "29",
    "30",
  ]);
  const [availableFloor, setAvailableFloor] = useState([]);

  const handleSelect = (selected) => {
    // Update the availableFloor array with the selected options
    setAvailableFloor(selected);
  };
  const handleRemove = (removed) => {
    // Update the availableFloor array after removing an option
    const updatedOptions = availableFloor.filter(
      (option) => option !== removed
    );
    setAvailableFloor(updatedOptions);
    // console.log("Selected Options after removal: ", updatedOptions);
  };

  //checkBox
  const [mosque, setMosque] = useState(false);
  const [security, setSecurity] = useState(false);
  const [fireExit, setFireExit] = useState(false);
  const [fireProtection, setFireProtection] = useState(false);
  const [securityAlarm, setSecurityAlarm] = useState(false);
  const [electronicSecurity, setElectronicSecurity] = useState(false);
  const [gymnasium, setGymnasium] = useState(false);
  const [conversation, setConversation] = useState(false);
  const [wasaConnection, setWasaConnection] = useState(false);
  const [hotWater, setHotWater] = useState(false);
  const [cylinderGas, setCylinderGas] = useState(false);
  const [generator, setGenerator] = useState(false);
  const [intercom, setIntercom] = useState(false);
  const [wifiConnection, setWiFiConnection] = useState(false);
  const [satelliteCableTv, setSatelliteCableTv] = useState(false);
  const [solarPanel, setSolarPanel] = useState(false);
  const [ServantRoom, setServantRoom] = useState(false);
  const [pool, setPool] = useState(false);
  const [garden, setGarden] = useState(false);
  const [selfWaterSupply, setSelfWaterSupply] = useState(false);
  const [guestParking, setGuestParking] = useState(false);

  const handleCheckboxChange = (e, checkboxType) => {
    const isChecked = e.target.checked;
    if (checkboxType === "mosque") {
      setMosque(isChecked ? true : false);
    } else if (checkboxType === "security") {
      setSecurity(isChecked ? true : false);
    } else if (checkboxType === "fireExit") {
      setFireExit(isChecked ? true : false);
    } else if (checkboxType === "fireProtection") {
      setFireProtection(isChecked ? true : false);
    } else if (checkboxType === "securityAlarm") {
      setSecurityAlarm(isChecked ? true : false);
    } else if (checkboxType === "electronicSecurity") {
      setElectronicSecurity(isChecked ? true : false);
    } else if (checkboxType === "wasaConnection") {
      setWasaConnection(isChecked ? true : false);
    } else if (checkboxType === "hotWater") {
      setHotWater(isChecked ? true : false);
    } else if (checkboxType === "conversation") {
      setConversation(isChecked ? true : false);
    } else if (checkboxType === "cylinderGas") {
      setCylinderGas(isChecked ? true : false);
    } else if (checkboxType === "generator") {
      setGenerator(isChecked ? true : false);
    } else if (checkboxType === "gymnasium") {
      setGymnasium(isChecked ? true : false);
    } else if (checkboxType === "intercom") {
      setIntercom(isChecked ? true : false);
    } else if (checkboxType === "wifiConnection") {
      setWiFiConnection(isChecked ? true : false);
    } else if (checkboxType === "satelliteCableTv") {
      setSatelliteCableTv(isChecked ? true : false);
    } else if (checkboxType === "solarPanel") {
      setSolarPanel(isChecked ? true : false);
    } else if (checkboxType === "ServantRoom") {
      setServantRoom(isChecked ? true : false);
    } else if (checkboxType === "pool") {
      setPool(isChecked ? true : false);
    } else if (checkboxType === "garden") {
      setGarden(isChecked ? true : false);
    } else if (checkboxType === "selfWaterSupply") {
      setSelfWaterSupply(isChecked ? true : false);
    } else if (checkboxType === "guestParking") {
      setGuestParking(isChecked ? true : false);
    }
  };

  ////Getting image from from ------------------
  const [dragActive, setDragActive] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const inputRef = useRef(null);

  function handleChange(e) {
    e.preventDefault();
    console.log("File has been added");

    const newFiles = [...files];

    if (e.target.files && e.target.files[0]) {
      for (let i = 0; i < e.target.files.length; i++) {
        const file = e.target.files[i];
        if (newFiles.length < 5) {
          newFiles.push(file);
        } else {
          setErrorMessage("Maximum 5 images allowed.");
          setTimeout(() => {
            setErrorMessage("");
          }, 5000);
          break;
        }
      }
    }
    setFiles(newFiles);
  }
  function handleDrop(e) {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);

    const newFiles = [...files];

    if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
      for (let i = 0; i < e.dataTransfer.files.length; i++) {
        const file = e.dataTransfer.files[i];
        if (newFiles.length < 5) {
          newFiles.push(file);
        } else {
          setErrorMessage("Maximum 5 images allowed.");
          setTimeout(() => {
            setErrorMessage("");
          }, 5000);
          break;
        }
      }
      setFiles(newFiles);
    }
  }

  function handleDragLeave(e) {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
  }
  function handleDragOver(e) {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(true);
  }
  function handleDragEnter(e) {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(true);
  }
  function removeFile(fileName, idx) {
    const newArr = [...files];
    newArr.splice(idx, 1);
    setFiles([]);
    setFiles(newArr);
  }
  function openFileExplorer() {
    inputRef.current.value = "";
    inputRef.current.click();
  }

  //Confirmation modal
  const [showConfirmation, setShowConfirmation] = useState(false);
  const handleModalSubmit = (e) => {
    e.preventDefault();
    if (files.length === 0) {
      toast.error("Please select at least one image.");
      setLoading(false); // Stop loading
      return; // Stop the function
    }

    setShowConfirmation(true);
  };
  const handleContinue = () => {
    setShowConfirmation(false);
    handleSubmit();
  };
  const handleCancel = () => {
    setShowConfirmation(false);
  };

  //send to backend
  const handleSubmit = async (e) => {
    //e.preventDefault(); // Prevent the default form submission
    setLoading(true);

    try {
      const formData = new FormData();
      formData.append("postTitle", postTitle);
      formData.append("postDetails", postDetails);
      formData.append("primaryNumber", primaryNumber);
      formData.append("secondaryNumber", secondaryNumber);
      formData.append("price", price);
      formData.append("exPrice", exPrice);

      //formData.append("location", location);
      formData.append("division", divisionId);
      formData.append("district", districtId);
      formData.append("subDistrict", subDistrictId);

      formData.append("size", size);
      formData.append("landSize", landSize);

      //dropdown
      formData.append("propertyType", propertyType);
      formData.append("construction", construction);
      formData.append("condition", condition);
      formData.append("rooms", rooms);
      formData.append("bedroom", bedroom);
      formData.append("bathroom", bathroom);
      formData.append("balconies", balconies);
      formData.append("drawingRooms", drawingRooms);
      formData.append("lift", lift);
      formData.append("garages", garages);
      formData.append("furnishing", furnishing);
      formData.append("facing", facing);
      formData.append("unit", unit);
      formData.append("totalFloor", totalFloor);
      formData.append("availableFloor", availableFloor);

      //checkBox
      formData.append("mosque", mosque);
      formData.append("security", security);
      formData.append("fireExit", fireExit);
      formData.append("fireProtection", fireProtection);
      formData.append("securityAlarm", securityAlarm);
      formData.append("electronicSecurity", electronicSecurity);
      formData.append("conversation", conversation);
      formData.append("wasaConnection", wasaConnection);
      formData.append("selfWaterSupply", selfWaterSupply);
      formData.append("hotWater", hotWater);
      formData.append("cylinderGas", cylinderGas);
      formData.append("gymnasium", gymnasium);
      formData.append("pool", pool);
      formData.append("garden", garden);
      formData.append("generator", generator);
      formData.append("intercom", intercom);
      formData.append("wifiConnection", wifiConnection);
      formData.append("satelliteCableTv", satelliteCableTv);
      formData.append("solarPanel", solarPanel);
      formData.append("servantRoom", ServantRoom);
      formData.append("guestParking", guestParking);

      // Function to resize an image to a maximum size of 1080x1920
      async function resizeImage(imageFile) {
        return new Promise(async (resolve, reject) => {
          const img = new Image();
          img.src = URL.createObjectURL(imageFile);
          img.onload = async () => {
            const maxWidth = 1080;
            const maxHeight = 1920;
            let width = img.width;
            let height = img.height;

            // Check if resizing is needed
            if (width > maxWidth || height > maxHeight) {
              if (width / maxWidth > height / maxHeight) {
                height *= maxWidth / width;
                width = maxWidth;
              } else {
                width *= maxHeight / height;
                height = maxHeight;
              }

              // Create a canvas to resize the image
              const canvas = document.createElement("canvas");
              canvas.width = width;
              canvas.height = height;
              const ctx = canvas.getContext("2d");
              ctx.drawImage(img, 0, 0, width, height);

              // Convert the canvas back to a Blob
              canvas.toBlob((blob) => {
                resolve(blob);
              }, imageFile.type);
            } else {
              resolve(imageFile);
            }
          };

          img.onerror = (error) => {
            reject(error);
          };
        });
      }

      // Append each image individually as binary data
      for (let i = 0; i < files.length; i++) {
        const imageFile = files[i];
        const resizedImageBlob = await resizeImage(imageFile);
        formData.append("images", resizedImageBlob, imageFile.name);
      }

      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/propertyrentpost`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );

      console.log("Response:", response.data);

      // Check if status code is not equal to 200
      if (response.status !== 200) {
        toast.error(response.data.error); // Display the error message
      } else {
        toast.success("Post uploaded");
        setTimeout(() => {
          toast("Please wait for Admin approval", {
            icon: "⏳",
          });
        }, 2000);
        navigate("/Property-Rent");
      }
    } catch (axiosError) {
      if (axiosError.response) {
        toast.error(axiosError.response.data.error);
        console.error("Response error:", axiosError.response.data);
      } else if (axiosError.request) {
        toast.error("No response from the server. Please try again.");
        console.error("Request error:", axiosError.request);
      } else {
        toast.error("An error occurred. Please try again.");
        console.error("Axios error:", axiosError.message);
      }
    }
  };

  return (
    <div
      className={` ${setFont} min-h-[100vh] w-full flex justify-center duration-200 `}
    >
      <div className="h-full  xl:max-w-[95%] 2xl:max-w-[85%] lg:p-0 p-2  duration-200 ">
        <div className="  ">
          <p></p>
        </div>

        <form
          onSubmit={handleModalSubmit}
          encType="multipart/form-data "
          className=" mt-5 "
        >
          <div className=" lg:flex justify-center lg:space-x-10 mt-5 lg:mt-0 ">
            <div className="leftSide  lg:w-[40%] w-full  ">
              {/* Post title*/}
              <div className=" ">
                <p className=" text-xl">
                  Rent Post title
                  <span className="text-red-500 ml-1">*</span>
                </p>
                <textarea
                  className=" border-2 border-gray-300 rounded-xl p-2  w-[330px] lg:w-[530px] "
                  type="text"
                  rows="1"
                  required
                  placeholder="Post Title"
                  onChange={(e) => setPostTitle(e.target.value)}
                ></textarea>
              </div>

              {/* Post details*/}
              <div className="my-3">
                <p className=" text-xl">
                  Post Description
                  <span className="text-red-500 ml-1">*</span>
                </p>

                <textarea
                  className=" border-2 border-gray-300 rounded-xl p-2 w-[330px] lg:w-[530px] "
                  type="text"
                  rows="3"
                  required
                  placeholder="less than 500 characters"
                  onChange={(e) => setPostDetails(e.target.value)}
                ></textarea>
              </div>

              {/* Image upload*/}

              <div className=" mr-2">
                <div
                  className={`${
                    dragActive
                      ? "bg-blue-400  w-[330px] lg:w-[530px] "
                      : "bg-blue-50 w-[330px] lg:w-[530px] border-2 border-dashed border-sky-400"
                  }  p-2 rounded-lg  text-center flex flex-col items-center justify-center`}
                  onDragEnter={handleDragEnter}
                  onSubmit={(e) => e.preventDefault()}
                  onDrop={handleDrop}
                  onDragLeave={handleDragLeave}
                  onDragOver={handleDragOver}
                >
                  {/* this input element allows us to select files for upload. We make it hidden so we can activate it when the user clicks select files */}
                  <input
                    placeholder="fileInput"
                    className="hidden"
                    ref={inputRef}
                    type="file"
                    multiple={true}
                    onChange={handleChange}
                    accept=".jpg, .jpeg, .png"
                  />

                  <p>
                    Drag & Drop Image here or{" "}
                    <span
                      className="font-bold text-blue-600 cursor-pointer"
                      onClick={openFileExplorer}
                    >
                      <u>Select image</u>
                    </span>{" "}
                    to upload <span className="text-gray-400">(max 5)</span>
                  </p>

                  {errorMessage && (
                    <p className="text-red-500">{errorMessage}</p>
                  )}

                  <div className="grid grid-cols-5 gap-1 p-1 ">
                    {files.map((file, idx) => (
                      <div key={idx} className="">
                        <img
                          className=" h-16 w-32"
                          src={URL.createObjectURL(file)}
                          alt={file.name}
                        />
                        <img
                          type="button"
                          onClick={() => removeFile(file.name, idx)}
                          src={remove}
                          className="h-5 w-5 m-auto mt-1 cursor-pointer"
                          alt="remove"
                        ></img>
                      </div>
                    ))}
                  </div>
                </div>
              </div>

              {/* contact number*/}
              <div className="mt-4  ">
                <div>
                  <p>
                    Contact number primary :
                    <span className="text-gray-500"> {userNumber}</span>
                  </p>
                </div>

                <div className="mt-2">
                  <p className=" inline">
                    Contact number Secondary (optional) : +880{" "}
                  </p>
                  <input
                    className="border-2 h-8 w-[165px] rounded-lg border-gray-300 px-1 pl-2"
                    placeholder="1xxxxxxxx"
                    type="number"
                    onChange={(e) => setSecondaryNumber(e.target.value)}
                  ></input>
                </div>
              </div>

              {/* Location*/}
              <div className=" mt-3">
                <p>
                  Select Location:
                  <span className="text-red-500 ml-1">*</span>
                </p>
                <div className="flex lg:space-x-5 mt-2">
                  <div className="relative h-12 lg:w-40 w-32">
                    <select
                      name="Division"
                      required
                      className="peer h-full w-full rounded-[7px] border-2 border-blue-gray-600 border-t-transparent bg-transparent px-1 text-[15px] text-blue-gray-700 outline outline-0 transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200 empty:!bg-red-500 focus:border-2 focus:border-pink-500 focus:border-t-transparent focus:outline-0 disabled:border-0 disabled:bg-blue-gray-50"
                      onChange={(e) => handleDivision(e)}
                    >
                      <option value="" className="text-pink-600">
                        -Select Division-
                      </option>
                      {locationData.map((getDivision, index) => (
                        <option value={getDivision.division_id} key={index}>
                          {getDivision.division_name}
                        </option>
                      ))}
                    </select>
                    <label className="before:content[' '] after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none text-[12px] font-normal leading-tight text-blue-gray-400 transition-all before:pointer-events-none before:mt-[6.5px] before:mr-1 before:box-border before:block before:h-1.5 before:w-2.5 before:rounded-tl-md before:border-t before:border-l before:border-blue-gray-200 before:transition-all after:pointer-events-none after:mt-[6.5px] after:ml-1 after:box-border after:block after:h-1.5 after:w-2.5 after:flex-grow after:rounded-tr-md after:border-t after:border-r after:border-blue-gray-200 after:transition-all peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[3.75] peer-placeholder-shown:text-blue-gray-500 peer-placeholder-shown:before:border-transparent peer-placeholder-shown:after:border-transparent peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-pink-500 peer-focus:before:border-t-2 peer-focus:before:border-l-2 peer-focus:before:border-pink-500 peer-focus:after:border-t-2 peer-focus:after:border-r-2 peer-focus:after:border-pink-500 peer-disabled:text-transparent peer-disabled:before:border-transparent peer-disabled:after:border-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500">
                      Division
                    </label>
                  </div>

                  {district.length > 0 && (
                    <div className="relative h-12 w-40">
                      <select
                        required
                        name="states"
                        className="peer h-full w-full rounded-[7px] border-2 border-blue-gray-600 border-t-transparent bg-transparent px-1 text-[15px] text-blue-gray-700 outline outline-0 transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200 empty:!bg-red-500 focus:border-2 focus:border-pink-500 focus:border-t-transparent focus:outline-0 disabled:border-0 disabled:bg-blue-gray-50"
                        onChange={(e) => handleDistrict(e)}
                      >
                        <option value="" className="text-pink-600">
                          -Select District-
                        </option>
                        {district.map((getDistrict, index) => (
                          <option value={getDistrict.district_id} key={index}>
                            {getDistrict.district_name}
                          </option>
                        ))}
                      </select>

                      <label className="before:content[' '] after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none text-[12px] font-normal leading-tight text-blue-gray-400 transition-all before:pointer-events-none before:mt-[6.5px] before:mr-1 before:box-border before:block before:h-1.5 before:w-2.5 before:rounded-tl-md before:border-t before:border-l before:border-blue-gray-200 before:transition-all after:pointer-events-none after:mt-[6.5px] after:ml-1 after:box-border after:block after:h-1.5 after:w-2.5 after:flex-grow after:rounded-tr-md after:border-t after:border-r after:border-blue-gray-200 after:transition-all peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[3.75] peer-placeholder-shown:text-blue-gray-500 peer-placeholder-shown:before:border-transparent peer-placeholder-shown:after:border-transparent peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-pink-500 peer-focus:before:border-t-2 peer-focus:before:border-l-2 peer-focus:before:border-pink-500 peer-focus:after:border-t-2 peer-focus:after:border-r-2 peer-focus:after:border-pink-500 peer-disabled:text-transparent peer-disabled:before:border-transparent peer-disabled:after:border-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500">
                        District
                      </label>
                    </div>
                  )}

                  {subDistrict.length > 0 && (
                    <div className="relative h-12 w-40">
                      <select
                        name="subDistrict"
                        required
                        className="peer h-full w-full rounded-[7px] border-2 border-blue-gray-600 border-t-transparent bg-transparent px-1 text-[15px] text-blue-gray-700 outline outline-0 transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200 empty:!bg-red-500 focus:border-2 focus:border-pink-500 focus:border-t-transparent focus:outline-0 disabled:border-0 disabled:bg-blue-gray-50"
                        onChange={(e) => handleSubDistrict(e)}
                      >
                        <option value="" className="text-pink-600">
                          -Select Sub-District-
                        </option>
                        {subDistrict.map((getSubDistrict, index) => (
                          <option
                            value={getSubDistrict.sub_district_id}
                            key={index}
                          >
                            {getSubDistrict.sub_district_name}
                          </option>
                        ))}
                      </select>
                      <label className="before:content[' '] after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none text-[12px] font-normal leading-tight text-blue-gray-400 transition-all before:pointer-events-none before:mt-[6.5px] before:mr-1 before:box-border before:block before:h-1.5 before:w-2.5 before:rounded-tl-md before:border-t before:border-l before:border-blue-gray-200 before:transition-all after:pointer-events-none after:mt-[6.5px] after:ml-1 after:box-border after:block after:h-1.5 after:w-2.5 after:flex-grow after:rounded-tr-md after:border-t after:border-r after:border-blue-gray-200 after:transition-all peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[3.75] peer-placeholder-shown:text-blue-gray-500 peer-placeholder-shown:before:border-transparent peer-placeholder-shown:after:border-transparent peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-pink-500 peer-focus:before:border-t-2 peer-focus:before:border-l-2 peer-focus:before:border-pink-500 peer-focus:after:border-t-2 peer-focus:after:border-r-2 peer-focus:after:border-pink-500 peer-disabled:text-transparent peer-disabled:before:border-transparent peer-disabled:after:border-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500">
                        Sub-District
                      </label>
                    </div>
                  )}
                </div>
              </div>

              {/* Price*/}
              <div className="flex  lg:space-x-5 space-x-1 mt-2 ">
                <div className=" ">
                  <p className=" md:text-base text-sm">
                    Price (Tk.)
                    <span className="text-red-500 ml-1">*</span>
                  </p>
                  <input
                    className="h-10 w-40  border-2 border-gray-200 rounded-lg  p-2"
                    type="number"
                    onKeyPress={(e) => {
                      const charCode = e.charCode;
                      if ((charCode < 48 || charCode > 57) && charCode !== 46) {
                        e.preventDefault();
                      }
                    }}
                    required
                    onChange={(e) => setPrice(e.target.value)}
                  />
                </div>

                <div className=" ">
                  <p className=" md:text-base text-sm line-through">
                    Previous Price (Tk.)
                  </p>
                  <input
                    className="h-10 w-40  border-2 border-gray-200 rounded-lg  p-2 "
                    type="number"
                    onKeyPress={(e) => {
                      const charCode = e.charCode;
                      if ((charCode < 48 || charCode > 57) && charCode !== 46) {
                        e.preventDefault();
                      }
                    }}
                    placeholder="Optional"
                    onChange={(e) => setExPrice(e.target.value)}
                  />
                </div>
              </div>

              {/* Land size*/}
              <div className="flex  lg:space-x-5 space-x-1 mt-2 ">
                {propertyType !== "Land" ? (
                  <div className=" ">
                    <p className=" md:text-base text-sm">
                      Size: Sq.Ft
                      <span className="text-red-500 ml-1">*</span>
                    </p>
                    <input
                      placeholder=""
                      className="h-10 lg:w-40 w-28 border-2 border-gray-200 rounded-lg  p-2"
                      type="text"
                      pattern="[0-9.]*"
                      inputMode="decimal"
                      onKeyPress={(e) => {
                        const charCode = e.charCode;
                        if (
                          (charCode < 48 || charCode > 57) &&
                          charCode !== 46 &&
                          charCode !== 190
                        ) {
                          e.preventDefault();
                        }
                      }}
                      required
                      onChange={(e) => setSize(e.target.value)}
                    />
                  </div>
                ) : null}

                <div className="">
                  <p className=" md:text-base text-sm">
                    Land size: Katha
                    <span className="text-red-500 ml-1">*</span>
                  </p>
                  <input
                    placeholder=""
                    className="h-10 lg:w-40 w-28 border-2 border-gray-200 rounded-lg p-2"
                    type="text"
                    pattern="[0-9.]*"
                    inputMode="decimal"
                    onKeyPress={(e) => {
                      const charCode = e.charCode;
                      if (
                        (charCode < 48 || charCode > 57) &&
                        charCode !== 46 &&
                        charCode !== 190
                      ) {
                        e.preventDefault();
                      }
                    }}
                    required
                    onChange={(e) => setLandSize(e.target.value)}
                  />
                </div>
              </div>
            </div>

            {/* Property Specifications*/}
            <div className="rightSide  lg:w-[60%]  ">
              <p className="text-xl">Property Specifications</p>

              <div className="sec1  mt-5 grid lg:grid-cols-5 grid-cols-2 gap-4">
                {/* Property type*/}

                <div className="relative h-10 w-36 ">
                  <select
                    className="peer h-full w-full rounded-[7px] border-2 border-blue-gray-600 border-t-transparent bg-transparent px-3 text-[15px] text-blue-gray-700 outline outline-0 transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200 empty:!bg-red-500 focus:border-2 focus:border-pink-500 focus:border-t-transparent focus:outline-0 disabled:border-0 disabled:bg-blue-gray-50"
                    onChange={(e) => setPropertyType(e.target.value)}
                  >
                    <option value="Apartment/Flats">Apartment/Flats</option>
                    <option value="Land Sharing Flats">
                      Land Sharing Flats
                    </option>
                    <option value="Duplex Home">Duplex Home</option>
                    <option value="Commercial Office">Commercial Office</option>
                    <option value="Commercial Showroom">
                      Commercial Showroom
                    </option>
                    <option value="Commercial Shop">Commercial Shop</option>
                    <option value="Commercial Restaurant">
                      Commercial Restaurant
                    </option>
                    <option value="Commercial Factory">
                      Commercial Factory
                    </option>
                    <option value="Commercial Warehouse">
                      Commercial Warehouse
                    </option>

                    <option value="Land">Land</option>
                  </select>
                  <label className="before:content[' '] after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none text-[12px] font-normal leading-tight text-blue-gray-400 transition-all before:pointer-events-none before:mt-[6.5px] before:mr-1 before:box-border before:block before:h-1.5 before:w-2.5 before:rounded-tl-md before:border-t before:border-l before:border-blue-gray-200 before:transition-all after:pointer-events-none after:mt-[6.5px] after:ml-1 after:box-border after:block after:h-1.5 after:w-2.5 after:flex-grow after:rounded-tr-md after:border-t after:border-r after:border-blue-gray-200 after:transition-all peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[3.75] peer-placeholder-shown:text-blue-gray-500 peer-placeholder-shown:before:border-transparent peer-placeholder-shown:after:border-transparent peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-pink-500 peer-focus:before:border-t-2 peer-focus:before:border-l-2 peer-focus:before:border-pink-500 peer-focus:after:border-t-2 peer-focus:after:border-r-2 peer-focus:after:border-pink-500 peer-disabled:text-transparent peer-disabled:before:border-transparent peer-disabled:after:border-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500">
                    Property type
                  </label>
                </div>

                {propertyType !== "Land" ? (
                  <>
                    {/* Construction Status*/}
                    <div className="relative h-10 w-36 ">
                      <select
                        className="peer h-full w-full rounded-[7px] border-2 border-blue-gray-600 border-t-transparent bg-transparent px-3 text-[15px] text-blue-gray-700 outline outline-0 transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200 empty:!bg-red-500 focus:border-2 focus:border-pink-500 focus:border-t-transparent focus:outline-0 disabled:border-0 disabled:bg-blue-gray-50"
                        onChange={(e) => setConstruction(e.target.value)}
                      >
                        <option value="Ready">Ready</option>
                        <option value="Under Construction">
                          Under Construction
                        </option>
                      </select>
                      <label className="before:content[' '] after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none text-[11px] font-normal leading-tight text-blue-gray-400 transition-all before:pointer-events-none before:mt-[6.5px] before:mr-1 before:box-border before:block before:h-1.5 before:w-2.5 before:rounded-tl-md before:border-t before:border-l before:border-blue-gray-200 before:transition-all after:pointer-events-none after:mt-[6.5px] after:ml-1 after:box-border after:block after:h-1.5 after:w-2.5 after:flex-grow after:rounded-tr-md after:border-t after:border-r after:border-blue-gray-200 after:transition-all peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[3.75] peer-placeholder-shown:text-blue-gray-500 peer-placeholder-shown:before:border-transparent peer-placeholder-shown:after:border-transparent peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-pink-500 peer-focus:before:border-t-2 peer-focus:before:border-l-2 peer-focus:before:border-pink-500 peer-focus:after:border-t-2 peer-focus:after:border-r-2 peer-focus:after:border-pink-500 peer-disabled:text-transparent peer-disabled:before:border-transparent peer-disabled:after:border-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500">
                        Construction Status
                      </label>
                    </div>

                    {/* Condition*/}
                    <div className="relative h-10 w-36">
                      <select
                        className="peer h-full w-full rounded-[7px] border-2 border-blue-gray-600 border-t-transparent bg-transparent px-3 text-[15px] text-blue-gray-700 outline outline-0 transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200 empty:!bg-red-500 focus:border-2 focus:border-pink-500 focus:border-t-transparent focus:outline-0 disabled:border-0 disabled:bg-blue-gray-50"
                        onChange={(e) => setCondition(e.target.value)}
                      >
                        <option value="New">New</option>
                        <option value="Used">Used</option>
                      </select>
                      <label className="before:content[' '] after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none text-[12px] font-normal leading-tight text-blue-gray-400 transition-all before:pointer-events-none before:mt-[6.5px] before:mr-1 before:box-border before:block before:h-1.5 before:w-2.5 before:rounded-tl-md before:border-t before:border-l before:border-blue-gray-200 before:transition-all after:pointer-events-none after:mt-[6.5px] after:ml-1 after:box-border after:block after:h-1.5 after:w-2.5 after:flex-grow after:rounded-tr-md after:border-t after:border-r after:border-blue-gray-200 after:transition-all peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[3.75] peer-placeholder-shown:text-blue-gray-500 peer-placeholder-shown:before:border-transparent peer-placeholder-shown:after:border-transparent peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-pink-500 peer-focus:before:border-t-2 peer-focus:before:border-l-2 peer-focus:before:border-pink-500 peer-focus:after:border-t-2 peer-focus:after:border-r-2 peer-focus:after:border-pink-500 peer-disabled:text-transparent peer-disabled:before:border-transparent peer-disabled:after:border-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500">
                        Condition
                      </label>
                    </div>

                    {/* Rooms*/}
                    {propertyType === "Commercial Office" ||
                    propertyType === "Commercial Restaurant" ? (
                      <div className="relative h-10 w-36">
                        <select
                          className="peer h-full w-full rounded-[7px] border-2 border-blue-gray-600 border-t-transparent bg-transparent px-3 text-[15px] text-blue-gray-700 outline outline-0 transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200 empty:!bg-red-500 focus:border-2 focus:border-pink-500 focus:border-t-transparent focus:outline-0 disabled:border-0 disabled:bg-blue-gray-50"
                          onChange={(e) => setRooms(e.target.value)}
                        >
                          <option value="1">1</option>
                          <option value="2">2</option>
                          <option value="3">3</option>
                          <option value="4">4</option>
                          <option value="5">5</option>
                          <option value="6">6</option>
                          <option value="7">7</option>
                          <option value="8">8</option>
                          <option value="9">9</option>
                          <option value="10">10</option>
                          <option value="11">11</option>
                          <option value="12">12</option>
                          <option value="13">13</option>
                          <option value="14">14</option>
                          <option value="15">15</option>
                        </select>
                        <label className="before:content[' '] after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none text-[12px] font-normal leading-tight text-blue-gray-400 transition-all before:pointer-events-none before:mt-[6.5px] before:mr-1 before:box-border before:block before:h-1.5 before:w-2.5 before:rounded-tl-md before:border-t before:border-l before:border-blue-gray-200 before:transition-all after:pointer-events-none after:mt-[6.5px] after:ml-1 after:box-border after:block after:h-1.5 after:w-2.5 after:flex-grow after:rounded-tr-md after:border-t after:border-r after:border-blue-gray-200 after:transition-all peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[3.75] peer-placeholder-shown:text-blue-gray-500 peer-placeholder-shown:before:border-transparent peer-placeholder-shown:after:border-transparent peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-pink-500 peer-focus:before:border-t-2 peer-focus:before:border-l-2 peer-focus:before:border-pink-500 peer-focus:after:border-t-2 peer-focus:after:border-r-2 peer-focus:after:border-pink-500 peer-disabled:text-transparent peer-disabled:before:border-transparent peer-disabled:after:border-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500">
                          Rooms
                        </label>
                      </div>
                    ) : null}

                    {/* Bedroom*/}
                    {propertyType === "Apartment/Flats" ||
                    propertyType === "Land Sharing Flats" ||
                    propertyType === "Duplex Home" ? (
                      <div className="relative h-10 w-36">
                        <select
                          className="peer h-full w-full rounded-[7px] border-2 border-blue-gray-600 border-t-transparent bg-transparent px-3 text-[15px] text-blue-gray-700 outline outline-0 transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200 empty:!bg-red-500 focus:border-2 focus:border-pink-500 focus:border-t-transparent focus:outline-0 disabled:border-0 disabled:bg-blue-gray-50"
                          onChange={(e) => setBedroom(e.target.value)}
                        >
                          <option value="1">1</option>
                          <option value="2">2</option>
                          <option value="3">3</option>
                          <option value="4">4</option>
                          <option value="5">5</option>
                          <option value="6">6</option>
                          <option value="7">7</option>
                          <option value="8">8</option>
                          <option value="9">9</option>
                          <option value="10">10</option>
                          <option value="11">11</option>
                          <option value="12">12</option>
                          <option value="13">13</option>
                          <option value="14">14</option>
                          <option value="15">15</option>
                          <option value="16">16</option>
                        </select>
                        <label className="before:content[' '] after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none text-[12px] font-normal leading-tight text-blue-gray-400 transition-all before:pointer-events-none before:mt-[6.5px] before:mr-1 before:box-border before:block before:h-1.5 before:w-2.5 before:rounded-tl-md before:border-t before:border-l before:border-blue-gray-200 before:transition-all after:pointer-events-none after:mt-[6.5px] after:ml-1 after:box-border after:block after:h-1.5 after:w-2.5 after:flex-grow after:rounded-tr-md after:border-t after:border-r after:border-blue-gray-200 after:transition-all peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[3.75] peer-placeholder-shown:text-blue-gray-500 peer-placeholder-shown:before:border-transparent peer-placeholder-shown:after:border-transparent peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-pink-500 peer-focus:before:border-t-2 peer-focus:before:border-l-2 peer-focus:before:border-pink-500 peer-focus:after:border-t-2 peer-focus:after:border-r-2 peer-focus:after:border-pink-500 peer-disabled:text-transparent peer-disabled:before:border-transparent peer-disabled:after:border-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500">
                          Bedrooms
                        </label>
                      </div>
                    ) : null}

                    {/* Bathroom*/}
                    {propertyType !== "Commercial Factory" &&
                    propertyType !== "Commercial Warehouse" ? (
                      <div className="relative h-10 w-36">
                        <select
                          className="peer h-full w-full rounded-[7px] border-2 border-blue-gray-600 border-t-transparent bg-transparent px-3 text-[15px] text-blue-gray-700 outline outline-0 transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200 empty:!bg-red-500 focus:border-2 focus:border-pink-500 focus:border-t-transparent focus:outline-0 disabled:border-0 disabled:bg-blue-gray-50"
                          onChange={(e) => setBathroom(e.target.value)}
                        >
                          <option value="1">1</option>
                          <option value="2">2</option>
                          <option value="3">3</option>
                          <option value="4">4</option>
                          <option value="5">5</option>
                          <option value="6">6</option>
                          <option value="7">7</option>
                          <option value="8">8</option>
                        </select>
                        <label className="before:content[' '] after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none text-[12px] font-normal leading-tight text-blue-gray-400 transition-all before:pointer-events-none before:mt-[6.5px] before:mr-1 before:box-border before:block before:h-1.5 before:w-2.5 before:rounded-tl-md before:border-t before:border-l before:border-blue-gray-200 before:transition-all after:pointer-events-none after:mt-[6.5px] after:ml-1 after:box-border after:block after:h-1.5 after:w-2.5 after:flex-grow after:rounded-tr-md after:border-t after:border-r after:border-blue-gray-200 after:transition-all peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[3.75] peer-placeholder-shown:text-blue-gray-500 peer-placeholder-shown:before:border-transparent peer-placeholder-shown:after:border-transparent peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-pink-500 peer-focus:before:border-t-2 peer-focus:before:border-l-2 peer-focus:before:border-pink-500 peer-focus:after:border-t-2 peer-focus:after:border-r-2 peer-focus:after:border-pink-500 peer-disabled:text-transparent peer-disabled:before:border-transparent peer-disabled:after:border-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500">
                          Bathroom
                        </label>
                      </div>
                    ) : null}

                    {/* Balconies*/}
                    {propertyType === "Apartment/Flats" ||
                    propertyType === "Land Sharing Flats" ||
                    propertyType === "Duplex Home" ||
                    propertyType === "Commercial Office" ||
                    propertyType === "Commercial Restaurant" ? (
                      <div className="relative h-10 w-36">
                        <select
                          className="peer h-full w-full rounded-[7px] border-2 border-blue-gray-600 border-t-transparent bg-transparent px-3 text-[15px] text-blue-gray-700 outline outline-0 transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200 empty:!bg-red-500 focus:border-2 focus:border-pink-500 focus:border-t-transparent focus:outline-0 disabled:border-0 disabled:bg-blue-gray-50"
                          onChange={(e) => setBalconies(e.target.value)}
                        >
                          <option value="1">1</option>
                          <option value="2">2</option>
                          <option value="3">3</option>
                          <option value="4">4</option>
                          <option value="5">5</option>
                          <option value="6">6</option>
                          <option value="7">7</option>
                          <option value="8">8</option>
                        </select>
                        <label className="before:content[' '] after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none text-[12px] font-normal leading-tight text-blue-gray-400 transition-all before:pointer-events-none before:mt-[6.5px] before:mr-1 before:box-border before:block before:h-1.5 before:w-2.5 before:rounded-tl-md before:border-t before:border-l before:border-blue-gray-200 before:transition-all after:pointer-events-none after:mt-[6.5px] after:ml-1 after:box-border after:block after:h-1.5 after:w-2.5 after:flex-grow after:rounded-tr-md after:border-t after:border-r after:border-blue-gray-200 after:transition-all peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[3.75] peer-placeholder-shown:text-blue-gray-500 peer-placeholder-shown:before:border-transparent peer-placeholder-shown:after:border-transparent peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-pink-500 peer-focus:before:border-t-2 peer-focus:before:border-l-2 peer-focus:before:border-pink-500 peer-focus:after:border-t-2 peer-focus:after:border-r-2 peer-focus:after:border-pink-500 peer-disabled:text-transparent peer-disabled:before:border-transparent peer-disabled:after:border-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500">
                          Balconies
                        </label>
                      </div>
                    ) : null}

                    {/* Lift*/}
                    {propertyType === "Apartment/Flats" ||
                    propertyType === "Land Sharing Flats" ||
                    propertyType === "Commercial Office" ||
                    propertyType === "Commercial Showroom" ||
                    propertyType === "Commercial Shop" ||
                    propertyType === "Commercial Restaurant" ? (
                      <div className="relative h-10 w-36">
                        <select
                          className="peer h-full w-full rounded-[7px] border-2 border-blue-gray-600 border-t-transparent bg-transparent px-3 text-[15px] text-blue-gray-700 outline outline-0 transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200 empty:!bg-red-500 focus:border-2 focus:border-pink-500 focus:border-t-transparent focus:outline-0 disabled:border-0 disabled:bg-blue-gray-50"
                          onChange={(e) => setLift(e.target.value)}
                        >
                          <option value="No Lift">No Lift</option>
                          <option value="1">1</option>
                          <option value="2">2</option>
                          <option value="3">3</option>
                          <option value="4">4</option>
                          <option value="5">5</option>
                          <option value="6">6</option>
                          <option value="7">7</option>
                          <option value="8">8</option>
                        </select>
                        <label className="before:content[' '] after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none text-[12px] font-normal leading-tight text-blue-gray-400 transition-all before:pointer-events-none before:mt-[6.5px] before:mr-1 before:box-border before:block before:h-1.5 before:w-2.5 before:rounded-tl-md before:border-t before:border-l before:border-blue-gray-200 before:transition-all after:pointer-events-none after:mt-[6.5px] after:ml-1 after:box-border after:block after:h-1.5 after:w-2.5 after:flex-grow after:rounded-tr-md after:border-t after:border-r after:border-blue-gray-200 after:transition-all peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[3.75] peer-placeholder-shown:text-blue-gray-500 peer-placeholder-shown:before:border-transparent peer-placeholder-shown:after:border-transparent peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-pink-500 peer-focus:before:border-t-2 peer-focus:before:border-l-2 peer-focus:before:border-pink-500 peer-focus:after:border-t-2 peer-focus:after:border-r-2 peer-focus:after:border-pink-500 peer-disabled:text-transparent peer-disabled:before:border-transparent peer-disabled:after:border-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500">
                          Lift Service
                        </label>
                      </div>
                    ) : null}

                    {/* Drawing rooms*/}
                    {propertyType === "Apartment/Flats" ||
                    propertyType === "Land Sharing Flats" ? (
                      <div className="relative h-10 w-36">
                        <select
                          className="peer h-full w-full rounded-[7px] border-2 border-blue-gray-600 border-t-transparent bg-transparent px-3 text-[15px] text-blue-gray-700 outline outline-0 transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200 empty:!bg-red-500 focus:border-2 focus:border-pink-500 focus:border-t-transparent focus:outline-0 disabled:border-0 disabled:bg-blue-gray-50"
                          onChange={(e) => setDrawingRooms(e.target.value)}
                        >
                          <option value="Yes">Yes</option>
                          <option value="No">No</option>
                        </select>
                        <label className="before:content[' '] after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none text-[12px] font-normal leading-tight text-blue-gray-400 transition-all before:pointer-events-none before:mt-[6.5px] before:mr-1 before:box-border before:block before:h-1.5 before:w-2.5 before:rounded-tl-md before:border-t before:border-l before:border-blue-gray-200 before:transition-all after:pointer-events-none after:mt-[6.5px] after:ml-1 after:box-border after:block after:h-1.5 after:w-2.5 after:flex-grow after:rounded-tr-md after:border-t after:border-r after:border-blue-gray-200 after:transition-all peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[3.75] peer-placeholder-shown:text-blue-gray-500 peer-placeholder-shown:before:border-transparent peer-placeholder-shown:after:border-transparent peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-pink-500 peer-focus:before:border-t-2 peer-focus:before:border-l-2 peer-focus:before:border-pink-500 peer-focus:after:border-t-2 peer-focus:after:border-r-2 peer-focus:after:border-pink-500 peer-disabled:text-transparent peer-disabled:before:border-transparent peer-disabled:after:border-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500">
                          Drawing rooms
                        </label>
                      </div>
                    ) : null}

                    {/* Garages*/}
                    {propertyType !== "Commercial Factory" &&
                    propertyType !== "Commercial Warehouse" ? (
                      <div className="relative h-10 w-36">
                        <select
                          className="peer h-full w-full rounded-[7px] border-2 border-blue-gray-600 border-t-transparent bg-transparent px-3 text-[15px] text-blue-gray-700 outline outline-0 transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200 empty:!bg-red-500 focus:border-2 focus:border-pink-500 focus:border-t-transparent focus:outline-0 disabled:border-0 disabled:bg-blue-gray-50"
                          onChange={(e) => setGarages(e.target.value)}
                        >
                          <option value="Yes">Yes</option>
                          <option value="No">No</option>
                        </select>
                        <label className="before:content[' '] after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none text-[12px] font-normal leading-tight text-blue-gray-400 transition-all before:pointer-events-none before:mt-[6.5px] before:mr-1 before:box-border before:block before:h-1.5 before:w-2.5 before:rounded-tl-md before:border-t before:border-l before:border-blue-gray-200 before:transition-all after:pointer-events-none after:mt-[6.5px] after:ml-1 after:box-border after:block after:h-1.5 after:w-2.5 after:flex-grow after:rounded-tr-md after:border-t after:border-r after:border-blue-gray-200 after:transition-all peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[3.75] peer-placeholder-shown:text-blue-gray-500 peer-placeholder-shown:before:border-transparent peer-placeholder-shown:after:border-transparent peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-pink-500 peer-focus:before:border-t-2 peer-focus:before:border-l-2 peer-focus:before:border-pink-500 peer-focus:after:border-t-2 peer-focus:after:border-r-2 peer-focus:after:border-pink-500 peer-disabled:text-transparent peer-disabled:before:border-transparent peer-disabled:after:border-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500">
                          Garages
                        </label>
                      </div>
                    ) : null}

                    {/* Furnishing*/}
                    {propertyType !== "Commercial Factory" &&
                    propertyType !== "Commercial Warehouse" ? (
                      <div className="relative h-10 w-36">
                        <select
                          className="peer h-full w-full rounded-[7px] border-2 border-blue-gray-600 border-t-transparent bg-transparent px-3 text-[15px]  text-blue-gray-700 outline outline-0 transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200 empty:!bg-red-500 focus:border-2 focus:border-pink-500 focus:border-t-transparent focus:outline-0 disabled:border-0 disabled:bg-blue-gray-50"
                          onChange={(e) => setFurnishing(e.target.value)}
                        >
                          <option value="Furnished">Furnished</option>
                          <option value="semi-furnished">Semi-Furnished</option>
                          <option value="Unfurnished">Unfurnished</option>
                        </select>
                        <label className="before:content[' '] after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none text-[12px] font-normal leading-tight text-blue-gray-400 transition-all before:pointer-events-none before:mt-[6.5px] before:mr-1 before:box-border before:block before:h-1.5 before:w-2.5 before:rounded-tl-md before:border-t before:border-l before:border-blue-gray-200 before:transition-all after:pointer-events-none after:mt-[6.5px] after:ml-1 after:box-border after:block after:h-1.5 after:w-2.5 after:flex-grow after:rounded-tr-md after:border-t after:border-r after:border-blue-gray-200 after:transition-all peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[3.75] peer-placeholder-shown:text-blue-gray-500 peer-placeholder-shown:before:border-transparent peer-placeholder-shown:after:border-transparent peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-pink-500 peer-focus:before:border-t-2 peer-focus:before:border-l-2 peer-focus:before:border-pink-500 peer-focus:after:border-t-2 peer-focus:after:border-r-2 peer-focus:after:border-pink-500 peer-disabled:text-transparent peer-disabled:before:border-transparent peer-disabled:after:border-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500">
                          Furnishing
                        </label>
                      </div>
                    ) : null}

                    {/* Facing*/}
                    {propertyType !== "Commercial Factory" &&
                    propertyType !== "Commercial Warehouse" ? (
                      <div className="relative h-10 w-36">
                        <select
                          className="peer h-full w-full rounded-[7px] border-2 border-blue-gray-600 border-t-transparent bg-transparent px-1 text-[15px] text-blue-gray-700 outline outline-0 transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200 empty:!bg-red-500 focus:border-2 focus:border-pink-500 focus:border-t-transparent focus:outline-0 disabled:border-0 disabled:bg-blue-gray-50"
                          onChange={(e) => setFacing(e.target.value)}
                        >
                          <option value="North Facing">North Facing</option>
                          <option value="South Facing">South Facing</option>
                          <option value="East Facing">East Facing</option>
                          <option value="West Facing">West Facing</option>
                        </select>
                        <label className="before:content[' '] after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none text-[12px] font-normal leading-tight text-blue-gray-400 transition-all before:pointer-events-none before:mt-[6.5px] before:mr-1 before:box-border before:block before:h-1.5 before:w-2.5 before:rounded-tl-md before:border-t before:border-l before:border-blue-gray-200 before:transition-all after:pointer-events-none after:mt-[6.5px] after:ml-1 after:box-border after:block after:h-1.5 after:w-2.5 after:flex-grow after:rounded-tr-md after:border-t after:border-r after:border-blue-gray-200 after:transition-all peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[3.75] peer-placeholder-shown:text-blue-gray-500 peer-placeholder-shown:before:border-transparent peer-placeholder-shown:after:border-transparent peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-pink-500 peer-focus:before:border-t-2 peer-focus:before:border-l-2 peer-focus:before:border-pink-500 peer-focus:after:border-t-2 peer-focus:after:border-r-2 peer-focus:after:border-pink-500 peer-disabled:text-transparent peer-disabled:before:border-transparent peer-disabled:after:border-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500">
                          Facing
                        </label>
                      </div>
                    ) : null}

                    {/* Total Unit*/}
                    {propertyType === "Apartment/Flats" ||
                    propertyType === "Land Sharing Flats" ||
                    propertyType === "Commercial Office" ||
                    propertyType === "Commercial Showroom" ||
                    propertyType === "Commercial Shop" ||
                    propertyType === "Commercial Restaurant" ? (
                      <div className="relative h-10 w-36">
                        <select
                          className="peer h-full w-full rounded-[7px] border-2 border-blue-gray-600 border-t-transparent bg-transparent px-3 text-[15px] text-blue-gray-700 outline outline-0 transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200 empty:!bg-red-500 focus:border-2 focus:border-pink-500 focus:border-t-transparent focus:outline-0 disabled:border-0 disabled:bg-blue-gray-50"
                          onChange={(e) => setUnit(e.target.value)}
                        >
                          <option value="1">1</option>
                          <option value="2">2</option>
                          <option value="3">3</option>
                          <option value="4">4</option>
                          <option value="5">5</option>
                          <option value="6">6</option>
                          <option value="7">7</option>
                          <option value="8">8</option>
                          <option value="9">9</option>
                          <option value="10">10</option>
                        </select>
                        <label className="before:content[' '] after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none text-[12px] font-normal leading-tight text-blue-gray-400 transition-all before:pointer-events-none before:mt-[6.5px] before:mr-1 before:box-border before:block before:h-1.5 before:w-2.5 before:rounded-tl-md before:border-t before:border-l before:border-blue-gray-200 before:transition-all after:pointer-events-none after:mt-[6.5px] after:ml-1 after:box-border after:block after:h-1.5 after:w-2.5 after:flex-grow after:rounded-tr-md after:border-t after:border-r after:border-blue-gray-200 after:transition-all peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[3.75] peer-placeholder-shown:text-blue-gray-500 peer-placeholder-shown:before:border-transparent peer-placeholder-shown:after:border-transparent peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-pink-500 peer-focus:before:border-t-2 peer-focus:before:border-l-2 peer-focus:before:border-pink-500 peer-focus:after:border-t-2 peer-focus:after:border-r-2 peer-focus:after:border-pink-500 peer-disabled:text-transparent peer-disabled:before:border-transparent peer-disabled:after:border-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500">
                          Total Unit
                        </label>
                      </div>
                    ) : null}

                    {/* Total floor*/}
                    {propertyType !== "Commercial Factory" &&
                    propertyType !== "Commercial Warehouse" ? (
                      <div className="relative h-10 w-36">
                        <select
                          className="peer h-full w-full rounded-[7px] border-2 border-blue-gray-600 border-t-transparent bg-transparent px-3 text-[15px] text-blue-gray-700 outline outline-0 transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200 empty:!bg-red-500 focus:border-2 focus:border-pink-500 focus:border-t-transparent focus:outline-0 disabled:border-0 disabled:bg-blue-gray-50"
                          onChange={(e) => setTotalFloor(e.target.value)}
                        >
                          <option value="1">1</option>
                          <option value="2">2</option>
                          <option value="3">3</option>
                          <option value="4">4</option>
                          <option value="5">5</option>
                          <option value="6">6</option>
                          <option value="7">7</option>
                          <option value="8">8</option>
                          <option value="9">9</option>
                          <option value="10">10</option>
                          <option value="11">11</option>
                          <option value="12">12</option>
                          <option value="13">13</option>
                          <option value="14">14</option>
                          <option value="15">15</option>
                          <option value="16">16</option>
                          <option value="17">17</option>
                          <option value="18">18</option>
                          <option value="19">19</option>
                          <option value="21">21</option>
                          <option value="22">22</option>
                          <option value="23">23</option>
                          <option value="24">24</option>
                          <option value="25">25</option>
                          <option value="26">26</option>
                          <option value="27">27</option>
                          <option value="28">28</option>
                          <option value="29">29</option>
                          <option value="30">30</option>
                        </select>
                        <label className="before:content[' '] after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none text-[12px] font-normal leading-tight text-blue-gray-400 transition-all before:pointer-events-none before:mt-[6.5px] before:mr-1 before:box-border before:block before:h-1.5 before:w-2.5 before:rounded-tl-md before:border-t before:border-l before:border-blue-gray-200 before:transition-all after:pointer-events-none after:mt-[6.5px] after:ml-1 after:box-border after:block after:h-1.5 after:w-2.5 after:flex-grow after:rounded-tr-md after:border-t after:border-r after:border-blue-gray-200 after:transition-all peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[3.75] peer-placeholder-shown:text-blue-gray-500 peer-placeholder-shown:before:border-transparent peer-placeholder-shown:after:border-transparent peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-pink-500 peer-focus:before:border-t-2 peer-focus:before:border-l-2 peer-focus:before:border-pink-500 peer-focus:after:border-t-2 peer-focus:after:border-r-2 peer-focus:after:border-pink-500 peer-disabled:text-transparent peer-disabled:before:border-transparent peer-disabled:after:border-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500">
                          Total floor
                        </label>
                      </div>
                    ) : null}

                    {/* Total available floor*/}
                    {propertyType === "Apartment/Flats" ||
                    propertyType === "Land Sharing Flats" ||
                    propertyType === "Commercial Office" ||
                    propertyType === "Commercial Showroom" ||
                    propertyType === "Commercial Shop" ||
                    propertyType === "Commercial Restaurant" ? (
                      <div className="relative lg:h-12 lg:w-[305px] w-[96%] col-span-2 ">
                        <Multiselect
                          className="peer h-full w-full rounded-[7px] border-2 border-blue-gray-600 border-t-transparent bg-transparent px-0 text-[15px] text-blue-gray-700 outline outline-0 transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200 empty:!bg-red-500 focus:border-2 focus:border-pink-500 focus:border-t-transparent focus:outline-0 disabled:border-0 disabled:bg-blue-gray-50"
                          isObject={false}
                          options={multiOptions}
                          onSelect={handleSelect}
                          onRemove={handleRemove}
                          placeholder="Select floor"
                          style={{
                            chips: {
                              background: "blue",
                              padding: "2px 8px",
                            },
                            searchBox: {
                              border: "none",
                            },
                          }}
                          showCheckbox
                        />
                        <label className="before:content[' '] after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none text-[12px] font-normal leading-tight text-blue-gray-400 transition-all before:pointer-events-none before:mt-[6.5px] before:mr-1 before:box-border before:block before:h-1.5 before:w-2.5 before:rounded-tl-md before:border-t before:border-l before:border-blue-gray-200 before:transition-all after:pointer-events-none after:mt-[6.5px] after:ml-1 after:box-border after:block after:h-1.5 after:w-2.5 after:flex-grow after:rounded-tr-md after:border-t after:border-r after:border-blue-gray-200 after:transition-all peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[3.75] peer-placeholder-shown:text-blue-gray-500 peer-placeholder-shown:before:border-transparent peer-placeholder-shown:after:border-transparent peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-pink-500 peer-focus:before:border-t-2 peer-focus:before:border-l-2 peer-focus:before:border-pink-500 peer-focus:after:border-t-2 peer-focus:after:border-r-2 peer-focus:after:border-pink-500 peer-disabled:text-transparent peer-disabled:before:border-transparent peer-disabled:after:border-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500">
                          Available floor on
                        </label>
                      </div>
                    ) : null}
                  </>
                ) : null}
              </div>

              {propertyType !== "Land" &&
              propertyType !== "Commercial Warehouse" &&
              propertyType !== "Commercial Factory" ? (
                <p className="lg:mt-24 mt-5 text-xl">Others facility</p>
              ) : null}

              {/* Conditional checkbox*/}
              {propertyType !== "Commercial Factory" &&
              propertyType !== "Commercial Warehouse" &&
              propertyType !== "Land" ? (
                <div className="sec2 mt-5 grid lg:grid-cols-4 grid-cols-2 gap-4">
                  {/* check boxxxxxxxxxxxxxxxxxes */}

                  <div className="flex items-center mb-4">
                    <input
                      id="Mosque-checkbox"
                      type="checkbox"
                      value="Mosque/Prayer room"
                      checked={mosque === true}
                      onChange={(e) => handleCheckboxChange(e, "mosque")}
                      className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                    />
                    <label
                      htmlFor="Mosque-checkbox"
                      className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-900"
                    >
                      Mosque/Prayer room
                    </label>
                  </div>

                  <div className="flex items-center mb-4">
                    <input
                      id="Security-checkbox"
                      type="checkbox"
                      value=""
                      checked={security === true}
                      onChange={(e) => handleCheckboxChange(e, "security")}
                      className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                    />
                    <label
                      htmlFor="Security-checkbox"
                      className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-900"
                    >
                      Security
                    </label>
                  </div>

                  <div className="flex items-center mb-4">
                    <input
                      id="Fire-exit-checkbox"
                      type="checkbox"
                      value=""
                      checked={fireExit === true}
                      onChange={(e) => handleCheckboxChange(e, "fireExit")}
                      className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                    />
                    <label
                      htmlFor="Fire-exit-checkbox"
                      className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-900"
                    >
                      Fire exit
                    </label>
                  </div>

                  <div className="flex items-center mb-4">
                    <input
                      id="Fire-protection-checkbox"
                      type="checkbox"
                      value=""
                      checked={fireProtection === true}
                      onChange={(e) =>
                        handleCheckboxChange(e, "fireProtection")
                      }
                      className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                    />
                    <label
                      htmlFor="Fire-protection-checkbox"
                      className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-900"
                    >
                      Fire protection
                    </label>
                  </div>

                  <div className="flex items-center mb-4">
                    <input
                      id="Security-Alarm-checkbox"
                      type="checkbox"
                      value=""
                      checked={securityAlarm === true}
                      onChange={(e) => handleCheckboxChange(e, "securityAlarm")}
                      className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                    />
                    <label
                      htmlFor="Security-Alarm-checkbox"
                      className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-900"
                    >
                      Security Alarm system
                    </label>
                  </div>

                  <div className="flex items-center mb-4">
                    <input
                      id="Electronic-security-checkbox"
                      type="checkbox"
                      value=""
                      checked={electronicSecurity === true}
                      onChange={(e) =>
                        handleCheckboxChange(e, "electronicSecurity")
                      }
                      className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                    />
                    <label
                      htmlFor="Electronic-security-checkbox"
                      className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-900"
                    >
                      Electronic security
                    </label>
                  </div>

                  <div className="flex items-center mb-4">
                    <input
                      id="Conversation-checkbox"
                      type="checkbox"
                      value=""
                      checked={conversation === true}
                      onChange={(e) => handleCheckboxChange(e, "conversation")}
                      className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                    />
                    <label
                      htmlFor="Conversation-checkbox"
                      className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-900"
                    >
                      Conversation Room
                    </label>
                  </div>

                  {/* Conditional checkbox*/}
                  {propertyType === "Apartment/Flats" ||
                  propertyType === "Land Sharing Flats" ||
                  propertyType === "Commercial Office" ||
                  propertyType === "Duplex Home" ||
                  propertyType === "Commercial Restaurant" ? (
                    <>
                      <div className="flex items-center mb-4">
                        <input
                          id="Wasa-checkbox"
                          type="checkbox"
                          value=""
                          checked={wasaConnection === true}
                          onChange={(e) =>
                            handleCheckboxChange(e, "wasaConnection")
                          }
                          className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                        />
                        <label
                          htmlFor="Wasa-checkbox"
                          className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-900"
                        >
                          Wasa connection
                        </label>
                      </div>

                      <div className="flex items-center mb-4">
                        <input
                          id="Hot-water-checkbox"
                          type="checkbox"
                          value=""
                          checked={hotWater === true}
                          onChange={(e) => handleCheckboxChange(e, "hotWater")}
                          className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                        />
                        <label
                          htmlFor="Hot-water-checkbox"
                          className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-900"
                        >
                          Hot water
                        </label>
                      </div>

                      <div className="flex items-center mb-4">
                        <input
                          id="Cylinder-checkbox"
                          type="checkbox"
                          value=""
                          checked={cylinderGas === true}
                          onChange={(e) =>
                            handleCheckboxChange(e, "cylinderGas")
                          }
                          className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                        />
                        <label
                          htmlFor="Cylinder-checkbox"
                          className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-900"
                        >
                          Cylinder gas
                        </label>
                      </div>

                      <div className="flex items-center mb-4">
                        <input
                          id="Gymnasium-checkbox"
                          type="checkbox"
                          value=""
                          checked={gymnasium === true}
                          onChange={(e) => handleCheckboxChange(e, "gymnasium")}
                          className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                        />
                        <label
                          htmlFor="Gymnasium-checkbox"
                          className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-900"
                        >
                          Gymnasium
                        </label>
                      </div>

                      <div className="flex items-center mb-4">
                        <input
                          id="Pool-checkbox"
                          type="checkbox"
                          value=""
                          checked={pool === true}
                          onChange={(e) => handleCheckboxChange(e, "pool")}
                          className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                        />
                        <label
                          htmlFor="Pool-checkbox"
                          className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-900"
                        >
                          Pool
                        </label>
                      </div>

                      <div className="flex items-center mb-4">
                        <input
                          id="Garden-checkbox"
                          type="checkbox"
                          value=""
                          checked={garden === true}
                          onChange={(e) => handleCheckboxChange(e, "garden")}
                          className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                        />
                        <label
                          htmlFor="Garden-checkbox"
                          className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-900"
                        >
                          Garden
                        </label>
                      </div>

                      <div className="flex items-center mb-4">
                        <input
                          id="Self-water-supply-checkbox"
                          type="checkbox"
                          value=""
                          checked={selfWaterSupply === true}
                          onChange={(e) =>
                            handleCheckboxChange(e, "selfWaterSupply")
                          }
                          className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                        />
                        <label
                          htmlFor="Self-water-supply-checkbox"
                          className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-900"
                        >
                          Self water supply
                        </label>
                      </div>
                    </>
                  ) : null}

                  <div className="flex items-center mb-4">
                    <input
                      id="Generator-checkbox"
                      type="checkbox"
                      value=""
                      checked={generator === true}
                      onChange={(e) => handleCheckboxChange(e, "generator")}
                      className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                    />
                    <label
                      htmlFor="Generator-checkbox"
                      className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-900"
                    >
                      Generator
                    </label>
                  </div>

                  <div className="flex items-center mb-4">
                    <input
                      id="Intercom-checkbox"
                      type="checkbox"
                      value=""
                      checked={intercom === true}
                      onChange={(e) => handleCheckboxChange(e, "intercom")}
                      className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                    />
                    <label
                      htmlFor="Intercom-checkbox"
                      className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-900"
                    >
                      Intercom
                    </label>
                  </div>

                  <div className="flex items-center mb-4">
                    <input
                      id="Wi-Fi-connection-checkbox"
                      type="checkbox"
                      value=""
                      checked={wifiConnection === true}
                      onChange={(e) =>
                        handleCheckboxChange(e, "wifiConnection")
                      }
                      className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                    />
                    <label
                      htmlFor="Wi-Fi-connection-checkbox"
                      className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-900"
                    >
                      Wi-Fi connection
                    </label>
                  </div>

                  <div className="flex items-center mb-4">
                    <input
                      id="Satellite-tv-checkbox"
                      type="checkbox"
                      value=""
                      checked={satelliteCableTv === true}
                      onChange={(e) =>
                        handleCheckboxChange(e, "satelliteCableTv")
                      }
                      className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                    />
                    <label
                      htmlFor="Satellite-tv-checkbox"
                      className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-900"
                    >
                      Satellite or cable TV
                    </label>
                  </div>

                  <div className="flex items-center mb-4">
                    <input
                      id="Solar-panel-checkbox"
                      type="checkbox"
                      value=""
                      checked={solarPanel === true}
                      onChange={(e) => handleCheckboxChange(e, "solarPanel")}
                      className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                    />
                    <label
                      htmlFor="Solar-panel-checkbox"
                      className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-900"
                    >
                      Solar panel
                    </label>
                  </div>

                  <div className="flex items-center mb-4">
                    <input
                      id="Servant-room-checkbox"
                      type="checkbox"
                      value=""
                      checked={ServantRoom === true}
                      onChange={(e) => handleCheckboxChange(e, "ServantRoom")}
                      className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                    />
                    <label
                      htmlFor="Servant-room-checkbox"
                      className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-900"
                    >
                      Servant room
                    </label>
                  </div>

                  <div className="flex items-center mb-4">
                    <input
                      id="Guest-parking-checkbox"
                      type="checkbox"
                      value=""
                      checked={guestParking === true}
                      onChange={(e) => handleCheckboxChange(e, "guestParking")}
                      className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                    />
                    <label
                      htmlFor="Guest-parking-checkbox"
                      className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-900"
                    >
                      Guest parking
                    </label>
                  </div>
                </div>
              ) : null}
            </div>
          </div>

          <button
            type="submit"
            className="w-[250px] m-auto lg:mt-3 mt-5 flex gap-1 items-center justify-center py-2.5 text-white rounded-xl bg-[#F59E0B] hover:bg-[#fd7200] duration-200"
          >
            {loading && <CgSpinner size={20} className="mt-1 animate-spin" />}
            <span className="text-md">Submit</span>
          </button>
        </form>
      </div>

      {/* Confirmation Overlay Modal*/}

      {showConfirmation && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white h-[350px] w-[700px] p-6 rounded-2xl  relative">
            <h3 className="text-2xl  mb-4 text-green-500 font-bold">
              Confirmation
            </h3>

            {price >= 100000 && price < 500000 ? (
              <div>
                <span>আপনার মালের দাম বেশি </span>
                <span className="text-red-500 font-bold">BDT {price}</span>
                <p>
                  আপনার পোস্টটি কন্টিনিউ করার জন্য আপনাকে ১৫০ টাকা খরচ বহন করতে
                  হবে ।
                </p>
                <p>আপনার পোস্ট এডমিনদের আলোচনা সাপেক্ষে এপ্রভ করা হবে । </p>
              </div>
            ) : price >= 500000 && price < 1000000 ? (
              <div>
                <span>আপনার মালের দাম বেশি </span>
                <span className="text-red-500 font-bold">BDT {price}</span>
                <p>
                  আপনার পোস্টটি কন্টিনিউ করার জন্য আপনাকে ২৫০ টাকা খরচ বহন করতে
                  হবে ।
                </p>
                <p>আপনার পোস্ট এডমিনদের আলোচনা সাপেক্ষে এপ্রভ করা হবে । </p>
              </div>
            ) : price >= 1000000 && price < 1500000 ? (
              <div>
                <span>আপনার মালের দাম বেশি </span>
                <span className="text-red-500 font-bold">BDT {price}</span>
                <p>
                  আপনার পোস্টটি কন্টিনিউ করার জন্য আপনাকে ৪০০ টাকা খরচ বহন করতে
                  হবে ।
                </p>
                <p>আপনার পোস্ট এডমিনদের আলোচনা সাপেক্ষে এপ্রভ করা হবে । </p>
              </div>
            ) : price >= 1500000 && price < 3000000 ? (
              <div>
                <span>আপনার মালের দাম বেশি </span>
                <span className="text-red-500 font-bold">BDT {price}</span>
                <p>
                  আপনার পোস্টটি কন্টিনিউ করার জন্য আপনাকে ৫০০ টাকা খরচ বহন করতে
                  হবে ।
                </p>
                <p>আপনার পোস্ট এডমিনদের আলোচনা সাপেক্ষে এপ্রভ করা হবে । </p>
              </div>
            ) : price >= 3000000 && price < 10000000 ? (
              <div>
                <span>আপনার মালের দাম বেশি </span>
                <span className="text-red-500 font-bold">BDT {price}</span>
                <p>
                  আপনার পোস্টটি কন্টিনিউ করার জন্য আপনাকে ৮০০ টাকা খরচ বহন করতে
                  হবে ।
                </p>
                <p>আপনার পোস্ট এডমিনদের আলোচনা সাপেক্ষে এপ্রভ করা হবে । </p>
              </div>
            ) : price >= 10000000 && price < 20000000 ? (
              <div>
                <span>আপনার মালের দাম বেশি </span>
                <span className="text-red-500 font-bold">BDT {price}</span>
                <p>
                  আপনার পোস্টটি কন্টিনিউ করার জন্য আপনাকে ৯০০ টাকা খরচ বহন করতে
                  হবে ।
                </p>
                <p>আপনার পোস্ট এডমিনদের আলোচনা সাপেক্ষে এপ্রভ করা হবে । </p>
              </div>
            ) : price >= 20000000 ? (
              <div>
                <span>আপনার মালের দাম বেশি </span>
                <span className="text-red-500 font-bold">BDT {price}</span>
                <p>
                  আপনার পোস্টটি কন্টিনিউ করার জন্য আপনাকে ৯৯৯ টাকা খরচ বহন করতে
                  হবে ।
                </p>
                <p>আপনার পোস্ট এডমিনদের আলোচনা সাপেক্ষে এপ্রভ করা হবে । </p>
              </div>
            ) : (
              <div>
                <p>
                  আপনি আমাদের ফ্রী মেম্বেরশিপ এর অধিনে থাকার কারনে আপনার পোস্ট ১
                  মাস আমাদের ওয়েবসাইটে থাকবে।
                </p>
                <p className="mt-6">
                  দীর্ঘ সময় রাখার জন্য আপনার প্ল্যান আপগ্রেড করার অনুরোধ রইল ।
                </p>
                <p>আপনি কি সব পরেছেন ? </p>
              </div>
            )}

            <div className="mt-4 flex absolute bottom-0 right-0 p-5">
              <button
                className="mr-2 px-4 py-2 bg-green-500 text-white rounded-md"
                onClick={handleContinue}
              >
                Continue
              </button>
              <button
                className="px-4 py-2 bg-red-300 rounded-md"
                onClick={handleCancel}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default RentPost;
