import { useNavigate, useLocation } from "react-router-dom";
import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import DOMPurify from "dompurify";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useUser } from "../../../context/UserContext";
import ContactModal from "../../../components/LocalUi/ContactModal";
import { toast } from "react-hot-toast";
import PostLoading from "../../../components/LocalUi/PostLoading";
import { Helmet } from "react-helmet";

//icons
import icn_user from "../../../img/icn/icn_user.png";
import check from "../../../img/icn/check.png";
import rent from "../../../img/icn/for_rent.png";
import sell from "../../../img/icn/for_sell.png";
import call from "../../../img/icn/call.png";
import save from "../../../img/icn/love.png";
import unsave from "../../../img/icn/unsave.png";
import share from "../../../img/icn/share.png";
import loc from "../../../img/icn/location.png";
import bed from "../../../img/icn/bed.png";
import bath from "../../../img/icn/bath.png";
import area from "../../../img/icn/area.png";
import garage from "../../../img/icn/garage.png";

const PropertyRentDetails = () => {
  const { id } = useParams(); // Get the product ID from the URL
  const [propertyData, setPropertyData] = useState(null);
  const [propertyRenterData, setPropertyRenterData] = useState(null);
  const [propertyImages, setPropertyImages] = useState([]); // Initialize as an empty array
  const [img, setImg] = useState(null); // Initialize as null
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const currentUser = localStorage.getItem("userToken");
  const location = useLocation();
  const navigate = useNavigate();

  ///Language
  const { t, i18n } = useTranslation();




  //get current user data
  const { user, error, isLoading } = useUser();
  const userToken = localStorage.getItem("userToken");

  //get user saved post
  const [savedPosts, setSavedPosts] = useState([]);
  const userID = user?.id; // Use optional chaining to prevent errors if user is undefined

  //get user SavePost
  useEffect(() => {
    // Check if user exists before making the Axios request
    if (user) {
      const handleFetchSavedPosts = async () => {
        try {
          const response = await axios.get(
            `${process.env.REACT_APP_API_URL}/api/profile/getSavePost`,
            {
              headers: {
                Authorization: `Bearer ${userToken}`, // Ensure userToken is defined
              },
            }
          );
          setSavedPosts(response.data.SavedPosts);
        } catch (error) {
          console.error("Error making Axios request:", error);
        }
      };

      handleFetchSavedPosts();
    }
  }, [user]); // Run when the user changes

  //popup modal
  const [isModalOpen, setIsModalOpen] = useState(false);
  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    // Define the URL with the dynamic ID
    const apiUrl = `${process.env.REACT_APP_API_URL}/api/propertyrentdetails/${id}`;
    // Make the Axios GET request
    axios
      .get(apiUrl)
      .then((response) => {
        // Set the retrieved data in the state
        setPropertyData(response.data.propertyRentPost);
        setPropertyImages(response.data.propertyRentPost.imageUrls);
        setPropertyRenterData(response.data.user);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, [id]); // Re-run the effect when the ID changes

  useEffect(() => {
    const interval = setInterval(() => {
      // Change image every 10 seconds
      setCurrentImageIndex(
        (prevIndex) => (prevIndex + 1) % propertyImages.length
      );
    }, 10000);

    return () => clearInterval(interval); // Cleanup interval on component unmount
  }, [propertyImages.length]);

  const changeImage = (direction) => {
    // Change image based on direction (prev or next)
    if (direction === "prev") {
      setCurrentImageIndex(
        (prevIndex) =>
          (prevIndex - 1 + propertyImages.length) % propertyImages.length
      );
    } else {
      setCurrentImageIndex(
        (prevIndex) => (prevIndex + 1) % propertyImages.length
      );
    }
  };

  const handleThumbnailClick = (index) => {
    // Handle thumbnail click to change main image
    setCurrentImageIndex(index);
  };

  //Save Un-save Optimistic UI Update
  const handleSaveUnSavePost = async (event, postId) => {
    event.stopPropagation();

    if (!currentUser) {
      localStorage.setItem("redirectUrl", location.pathname);
      toast.error("You have to login first");
      navigate("/SignIn");
    } else {
      const userID = user.id;
      const PostID = postId;
      const isPostSaved = savedPosts.some(
        (savedPost) => savedPost._id === PostID
      );

      try {
        if (isPostSaved) {
          // Optimistically update the UI
          setSavedPosts((prevPosts) =>
            prevPosts.filter((post) => post._id !== PostID)
          );
          toast.success("Post Unsaved");

          // Make the network request in the background
          await axios.delete(
            `${process.env.REACT_APP_API_URL}/api/profile/deleteUserSavedPost/${PostID}`,
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${userToken}`,
              },
            }
          );
        } else {
          // Optimistically update the UI
          const newSavedPost = { _id: PostID }; // Create a minimal representation of the saved post
          setSavedPosts((prevPosts) => [...prevPosts, newSavedPost]);
          toast.success("Post Saved");

          // Make the network request in the background
          const response = await axios.post(
            `${process.env.REACT_APP_API_URL}/api/profile/setSavePost`,
            { userID, PostID },
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${userToken}`,
              },
            }
          );

          // Update the UI with the full post details from the server response
          const savedPost = response.data.SavedPosts.find(
            (post) => post._id === PostID
          );
          setSavedPosts((prevPosts) =>
            prevPosts.map((post) => (post._id === PostID ? savedPost : post))
          );
        }
      } catch (error) {
        // Revert the optimistic UI update on error
        if (isPostSaved) {
          setSavedPosts((prevPosts) => [...prevPosts, { _id: PostID }]);
        } else {
          setSavedPosts((prevPosts) =>
            prevPosts.filter((post) => post._id !== PostID)
          );
        }

        console.error("Error saving/unsaving post:", error);
        if (error.response && error.response.data) {
          const errorMessage = error.response.data.error;
          console.error("Server error message:", errorMessage);
          //toast.error(`Error: ${errorMessage}`);
        } else {
          //toast.error("Error saving/unsaving post");
        }
      }
    }
  };

  const hasOtherFacility =
    propertyData &&
    Object.keys(propertyData).some(
      (key) =>
        [
          "mosque",
          "security",
          "fireExit",
          "fireProtection",
          "securityAlarm",
          "electronicSecurity",
          "conversation",
          "wasaConnection",
          "selfWaterSupply",
          "hotWater",
          "cylinderGas",
          "gymnasium",
          "pool",
          "garden",
          "generator",
          "intercom",
          "wifiConnection",
          "satelliteCableTv",
          "solarPanel",
          "servantRoom",
          "guestParking",
        ].includes(key) && propertyData[key] === true
    );

  function getRelativeTime(postDate) {
    const post = new Date(postDate);
    const now = new Date();
    const diffInSeconds = Math.floor((now - post) / 1000);

    if (diffInSeconds < 3600) {
      const minutes = Math.floor(diffInSeconds / 60);
      return `${minutes} minute${minutes !== 1 ? "s" : ""} ago`;
    } else if (diffInSeconds < 86400) {
      const hours = Math.floor(diffInSeconds / 3600);
      return `${hours} hour${hours !== 1 ? "s" : ""} ago`;
    } else {
      const days = Math.floor(diffInSeconds / 86400);
      return `${days} day${days !== 1 ? "s" : ""} ago`;
    }
  }

  const handleOpenDetail = (id) => {
    navigate(`/ProfileDetails/${id}`);
  };

  const sanitizeHTML = (html) => ({
    __html: DOMPurify.sanitize(html.replace(/\n/g, "<br>")),
  });

  return (
    <div className={`   `}>
      <Helmet>
        <title>Property Details - Loko Property</title>
      </Helmet>
      {propertyData ? (
        <div>
          {/* Image slider section is here*/}

          <div
            className={`mt-4 w-full flex justify-center items-center duration-200`}
          >
            <div className="w-[98%] md:w-[90%] xl:w-[85%] duration-200 ">
              <div className="image-slider-container w-full bg-slate-50 p-5 rounded-2xl overflow-hidden">
                <div className="main-image-container relative overflow-hidden">
                  <div className="absolute bg-cover blur-md  scale-[5]">
                    <img
                      src={propertyImages[currentImageIndex]}
                      className="m-auto block bg-cover "
                    />
                  </div>

                  <img
                    className="m-auto block 2xl:h-[500px] xl:h-[400px] h-[300px] rounded-xl object-contain relative"
                    src={propertyImages[currentImageIndex]}
                    alt={`property-${currentImageIndex}`}
                  />

                  <div className="h-[40px] flex justify-between w-full top-0 absolute duration-200 ">
                    {propertyData.type === "rent" ? (
                      <div>
                        <img src={rent} alt="camera" className="h-24 w-24" />
                      </div>
                    ) : (
                      <div>
                        <img
                          src={rent}
                          alt="camera"
                          className="h-[85px] w-[85px]"
                        />
                      </div>
                    )}
                  </div>

                  {propertyImages.length > 1 && (
                    <>
                      <div className="absolute top-1/2 left-0 transform -translate-y-1/2 z-20">
                        <button
                          className="h-8 w-5 hover:w-10 duration-200 text-center items-center bg-[#0000008f] text-white font-bold rounded-[100%]"
                          onClick={() => changeImage("prev")}
                        >
                          ◀
                        </button>
                      </div>

                      <div className="absolute top-1/2 right-0 transform -translate-y-1/2 z-20">
                        <button
                          className="h-8 w-5 hover:w-10 duration-200 text-center items-center bg-[#0000008f] text-white font-bold rounded-[100%]"
                          onClick={() => changeImage("next")}
                        >
                          ▶
                        </button>
                      </div>
                    </>
                  )}

                  <div className="lg:px-3 px-1 h-[50px] w-full  absolute m-auto bottom-0  flex justify-between items-center  bg-gradient-to-b from-transparent to-[#000000]  duration-200">
                    <div
                      className=" flex cursor-pointer "
                      onClick={() => handleOpenDetail(propertyRenterData._id)}
                    >
                      <img
                        src={
                          propertyRenterData.proPic
                            ? propertyRenterData.proPic
                            : icn_user
                        }
                        alt="User Profile"
                        className="lg:h-10 h-8 lg:w-10 w-8 rounded-full "
                      />
                      <h2 className="text-white p-2 lg:text-base text-xs ">
                        {propertyRenterData.username}
                      </h2>
                    </div>

                    <div className="lg:px-3 px-1 h-[45px]  duration-200">
                      <div className=" text-white p-1  flex flex-col justify-center lg:text-sm text-xs text-end">
                        <p>{getRelativeTime(propertyData.postDate)}</p>

                        {propertyData.lastUpdateDate ? (
                          <p>
                            {"Last Update "}
                            {getRelativeTime(propertyData.lastUpdateDate)}
                          </p>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="thumbnail-container mt-3 flex justify-between items-center ">
                  <div>
                    {propertyImages.map((image, index) => (
                      <button
                        className="focus:outline-none"
                        key={index}
                        onClick={() => handleThumbnailClick(index)}
                      >
                        <img
                          className="mr-2 block h-10 lg:h-12 xl:h-16 2xl:h-20 w-16 lg:w-20 xl:w-28 2xl:w-36 rounded-md duration-200 hover:translate-y-[-3px] ease-in-out hover:shadow-xl"
                          src={image}
                          alt={`thumbnail-${index}`}
                        />
                      </button>
                    ))}
                  </div>

                  <div className="md:flex md:space-y-0 space-y-1 justify-center items-center md:space-x-1 duration-300 ">
                    <div>
                      <div
                        onClick={openModal}
                        className="m-auto flex items-center justify-evenly lg:h-10 h-8 lg:w-[110px] w-8 px-1 border border-gray-400 cursor-pointer rounded-lg hover:scale-105 hover:bg-red-200 hover:shadow-lg duration-200"
                      >
                        <img
                          src={call}
                          className="lg:h-6 h-5 lg:w-6 w-5 duration-200"
                          alt="name"
                        />

                        <div className="lg:text-[16px] hidden lg:block ">
                          {"Contact"}
                        </div>
                      </div>
                    </div>

                    <button
                      onClick={(event) =>
                        handleSaveUnSavePost(event, propertyData._id)
                      }
                    >
                      <div className="m-auto flex items-center justify-evenly lg:h-10 h-8 lg:w-[100px] w-8 px-1 border border-gray-400 cursor-pointer rounded-lg hover:scale-105 hover:bg-red-200 hover:shadow-lg duration-200">
                        {savedPosts.some(
                          (savedPost) => savedPost._id === propertyData._id
                        ) ? (
                          <div className="flex lg:space-x-2">
                            <img
                              src={save}
                              className="lg:h-6 h-5 lg:w-6 w-5 duration-200"
                              alt="Filled Love Icon"
                            />
                            <div className="lg:text-[16px] hidden lg:block">
                              {"Saved"}
                            </div>
                          </div>
                        ) : (
                          <div className="flex lg:space-x-2">
                            <img
                              src={unsave}
                              className="lg:h-6 h-5 lg:w-6 w-5 duration-200"
                              alt="Empty Love Icon"
                            />
                            <div className="lg:text-[16px] hidden lg:block">
                              {"Save"}
                            </div>
                          </div>
                        )}
                      </div>
                    </button>

                    <div className="m-auto flex items-center justify-evenly lg:h-10 h-8 lg:w-[100px] w-8 px-1 border border-gray-400 cursor-pointer rounded-lg hover:scale-105 hover:bg-green-200 hover:shadow-lg duration-200">
                      <img
                        src={share}
                        className="lg:h-6 h-5 lg:w-6 w-6 duration-200"
                        alt="name"
                      />
                      <div className="lg:text-[16px] hidden lg:block ">
                        {"Share"}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Display property details here using propertyData */}
          <div
            className={` w-full flex  justify-center items-center duration-200  `}
          >
            <div className=" w-[98%] md:w-[90%] xl:w-[85%]  duration-200  ">
              <div className="w-full bg-slate-100 lg:p-5 p-3 rounded-2xl mt-4">
                <div className="Title font-bold lg:text-3xl text-xl">
                  <p>{propertyData.postTitle}</p>
                </div>

                <div className="mt-2">
                  <p className="text-sm md:text-lg font-semibold text-[#666666] ">
                    Property Type : {propertyData.propertyType}{" "}
                  </p>
                </div>

                <div className="  grid  grid-cols-2  lg:grid-cols-4 xl:grid-cols-5 gap-2   ">
                  <div className="Price flex items-center rounded-lg font-bold lg:text-xl text-sm mt-4 lg:h-14 h-10 px-2 bg-white">
                    <p className="text-green-700 ">
                      Total Price : {propertyData.price} Tk.
                    </p>
                  </div>

                  <div className="division flex items-center  rounded-lg  text-md mt-4 lg:h-14 h-10 px-2 bg-white">
                    <img src={loc} alt="" className="h-5 w-5 mr-2"></img>
                    <p className="lg:text-base text-sm">
                      {propertyData.subDistrict}, {propertyData.district},{" "}
                      {propertyData.division}{" "}
                    </p>
                  </div>

                  {propertyData.propertyType === "Apartment/Flats" ||
                  propertyData.propertyType === "Land Sharing Flats" ||
                  propertyData.propertyType === "Duplex Home" ? (
                    <div className="Bedroom flex items-center  rounded-lg  text-md mt-4 lg:h-14 h-10 px-2 bg-white">
                      <img src={bed} alt="" className="h-5 w-5 mr-2"></img>
                      <p className="text-black lg:text-base text-xs">
                        {propertyData.bedroom}
                        {" Bedrooms"}
                      </p>
                    </div>
                  ) : null}

                  {propertyData.propertyType === "Apartment/Flats" ||
                  propertyData.propertyType === "Land Sharing Flats" ||
                  propertyData.propertyType === "Duplex Home" ? (
                    <div className="Bathroom flex items-center  rounded-lg  text-md mt-4 lg:h-14 h-10 px-2 bg-white">
                      <img src={bath} alt="" className="h-5 w-5 mr-2"></img>
                      <p className="text-black lg:text-base text-xs">
                        {propertyData.bathroom}
                        {" Bathrooms"}
                      </p>
                    </div>
                  ) : null}

                  {propertyData.propertyType === "Apartment/Flats" ||
                  propertyData.propertyType === "Land Sharing Flats" ||
                  propertyData.propertyType === "Duplex Home" ? (
                    <div className="Garage flex items-center  rounded-lg  text-md mt-4 lg:h-14 h-10 px-2 bg-white">
                      <img src={garage} alt="" className="h-5 w-5 mr-2"></img>
                      <p className="text-black lg:text-base text-xs">
                        {" Garage : "} {propertyData.garages}
                      </p>
                    </div>
                  ) : null}

                  {propertyData.propertyType === "Apartment/Flats" ||
                  propertyData.propertyType === "Land Sharing Flats" ||
                  propertyData.propertyType === "Duplex Home" ? (
                    <div className="size flex items-center  rounded-lg  text-md mt-4 lg:h-14 h-10 px-2 bg-white">
                      <img src={area} alt="" className="h-5 w-5 mr-2"></img>
                      <p className="text-black lg:text-base text-xs">
                        {"Flat Size "}
                        {propertyData.size}
                        {" ft²"}
                      </p>
                    </div>
                  ) : null}

                  {propertyData.propertyType === "Apartment/Flats" ||
                  propertyData.propertyType === "Land Sharing Flats" ||
                  propertyData.propertyType === "Duplex Home" ? (
                    <div className="landSize flex items-center  rounded-lg  text-md mt-4 lg:h-14 h-10 px-2 bg-white">
                      <img src={area} alt="" className="h-5 w-5 mr-2"></img>
                      <p className="text-black lg:text-base text-xs">
                        {"Land Size "}
                        {propertyData.landSize}
                        {" Katha"}
                      </p>
                    </div>
                  ) : null}

                  {propertyData.propertyType !== "Apartment/Flats" &&
                  propertyData.propertyType !== "Land Sharing Flats" &&
                  propertyData.propertyType !== "Duplex Home" ? (
                    <div className="Bathroom flex items-center  rounded-lg  text-md mt-4 lg:h-14 h-10 px-2 bg-white">
                      <img src={area} alt="" className="h-5 w-5 mr-2"></img>
                      <p className=" lg:text-base text-xs">
                        {propertyData.size} {" ft² Size"}
                      </p>
                    </div>
                  ) : null}
                  {propertyData.propertyType !== "Apartment/Flats" &&
                  propertyData.propertyType !== "Land Sharing Flats" &&
                  propertyData.propertyType !== "Duplex Home" ? (
                    <div className="Bathroom flex items-center  rounded-lg  text-md mt-4 lg:h-14 h-10 px-2 bg-white">
                      <img src={area} alt="" className="h-5 w-5 mr-2"></img>
                      <p className=" lg:text-base text-xs">
                        {propertyData.landSize}
                        {" Katha"}
                      </p>
                    </div>
                  ) : null}
                </div>

                <div className="mt-10">
                  <h2 className="2xl:text-3xl xl:text-xl text-lg font-bold">
                    Description
                  </h2>
                  <div
                    className="mt-4 "
                    dangerouslySetInnerHTML={sanitizeHTML(
                      propertyData.postDetails
                    )}
                  />
                </div>
              </div>
            </div>
          </div>

          {/*Property Specifications*/}
          <div
            className={` w-full flex justify-center items-center duration-200  `}
          >
            <div className=" w-[98%] md:w-[90%] xl:w-[85%]  duration-200  ">
              <div className="mt-4 bg-slate-100 p-5 rounded-2xl">
                <p className="font-bold 2xl:text-3xl xl:text-xl text-lg">
                  Property Specifications
                </p>

                {propertyData.propertyType !== "Land" ? (
                  <div className=" grid  xl:grid-cols-5 grid-cols-2 lg:grid-cols-4 gap-2 ">
                    {/* property Type*/}
                    <div className=" flex items-center  rounded-lg  text-md mt-4 lg:h-14 h-10 px-2 bg-amber-100">
                      <img src={area} alt="" className="h-5 w-5 mr-2"></img>
                      <p className="text-black lg:text-base text-xs ">
                        {"Property Type : "}
                        {propertyData.propertyType}
                      </p>
                    </div>

                    {/* Construction Status*/}
                    <div className=" flex items-center  rounded-lg  text-md mt-4 lg:h-14 h-10 px-2 bg-fuchsia-100">
                      <img src={area} alt="" className="h-5 w-5 mr-2"></img>
                      <p className="text-black lg:text-base text-xs">
                        {"Construction Status: "}
                        {propertyData.construction}
                        {""}
                      </p>
                    </div>

                    {/* Condition*/}
                    <div className=" flex items-center  rounded-lg  text-md mt-4 lg:h-14 h-10 px-2  bg-purple-200">
                      <img src={area} alt="" className="h-5 w-5 mr-2"></img>
                      <p className="text-black lg:text-base text-xs">
                        {"Condition : "}
                        {propertyData.condition}
                      </p>
                    </div>

                    {/* Rooms*/}
                    {propertyData.propertyType === "Commercial Office" ||
                    propertyData.propertyType === "Commercial Restaurant" ? (
                      <div className=" flex items-center  rounded-lg  text-md mt-4 lg:h-14 h-10 px-2  bg-purple-200">
                        <img src={area} alt="" className="h-5 w-5 mr-2"></img>
                        <p className="text-black lg:text-base text-xs">
                          {"Condition : "}
                          {propertyData.rooms}
                        </p>
                      </div>
                    ) : null}

                    {/* Bedroom*/}
                    {propertyData.propertyType === "Apartment/Flats" ||
                    propertyData.propertyType === "Land Sharing Flats" ||
                    propertyData.propertyType === "Duplex Home" ? (
                      <div className=" flex items-center  rounded-lg  text-md mt-4 lg:h-14 h-10 px-2  bg-violet-100">
                        <img src={bed} alt="" className="h-5 w-5 mr-2"></img>
                        <p className="text-black lg:text-base text-xs">
                          {"Bedrooms : "}
                          {propertyData.bedroom}
                        </p>
                      </div>
                    ) : null}

                    {/* Bathroom*/}
                    {propertyData.propertyType !== "Commercial Factory" &&
                    propertyData.propertyType !== "Commercial Warehouse" ? (
                      <div className=" flex items-center  rounded-lg  text-md mt-4 lg:h-14 h-10 px-2 bg-indigo-100">
                        <img src={bath} alt="" className="h-5 w-5 mr-2"></img>
                        <p className="text-black lg:text-base text-xs">
                          {"Bathrooms : "}
                          {propertyData.bathroom}
                        </p>
                      </div>
                    ) : null}

                    {/* Balconies*/}
                    {propertyData.propertyType === "Apartment/Flats" ||
                    propertyData.propertyType === "Land Sharing Flats" ||
                    propertyData.propertyType === "Duplex Home" ||
                    propertyData.propertyType === "Commercial Office" ||
                    propertyData.propertyType === "Commercial Restaurant" ? (
                      <div className=" flex items-center  rounded-lg  text-md mt-4 lg:h-14 h-10 px-2 bg-emerald-100">
                        <img src={area} alt="" className="h-5 w-5 mr-2"></img>
                        <p className="text-black lg:text-base text-xs">
                          {"Balconies : "}
                          {propertyData.balconies}
                          {""}
                        </p>
                      </div>
                    ) : null}

                    {/* Lift*/}
                    {propertyData.propertyType === "Apartment/Flats" ||
                    propertyData.propertyType === "Land Sharing Flats" ||
                    propertyData.propertyType === "Commercial Office" ||
                    propertyData.propertyType === "Commercial Showroom" ||
                    propertyData.propertyType === "Commercial Shop" ||
                    propertyData.propertyType === "Commercial Restaurant" ? (
                      <div className=" flex items-center  rounded-lg  text-md mt-4 lg:h-14 h-10 px-2 bg-yellow-100">
                        <img src={area} alt="" className="h-5 w-5 mr-2"></img>
                        <p className="text-black lg:text-base text-xs">
                          {"Lift : "}
                          {propertyData.lift}
                          {""}
                        </p>
                      </div>
                    ) : null}

                    {/* Drawing rooms*/}
                    {propertyData.propertyType === "Apartment/Flats" ||
                    propertyData.propertyType === "Land Sharing Flats" ? (
                      <div className=" flex items-center  rounded-lg  text-md mt-4 lg:h-14 h-10 px-2  bg-amber-100">
                        <img src={area} alt="" className="h-5 w-5 mr-2"></img>
                        <p className="text-black lg:text-base text-xs">
                          {"Drawing Rooms : "}
                          {propertyData.drawingRooms}
                          {""}
                        </p>
                      </div>
                    ) : null}

                    {/* Garages*/}
                    {propertyData.propertyType !== "Commercial Factory" &&
                    propertyData.propertyType !== "Commercial Warehouse" ? (
                      <div className=" flex items-center  rounded-lg  text-md mt-4 lg:h-14 h-10 px-2 bg-cyan-200">
                        <img src={garage} alt="" className="h-5 w-5 mr-2"></img>
                        <p className="text-black lg:text-base text-xs">
                          {"Garage : "}
                          {propertyData.garages}
                        </p>
                      </div>
                    ) : null}

                    {/* Furnishing*/}
                    {propertyData.propertyType !== "Commercial Factory" &&
                    propertyData.propertyType !== "Commercial Warehouse" ? (
                      <div className=" flex items-center  rounded-lg  text-md mt-4 lg:h-14 h-10 px-2  bg-purple-100">
                        <img src={area} alt="" className="h-5 w-5 mr-2"></img>
                        <p className="text-black lg:text-base text-xs">
                          {"Furnishing : "}
                          {propertyData.furnishing}
                          {""}
                        </p>
                      </div>
                    ) : null}

                    {/* Facing*/}
                    {propertyData.propertyType !== "Commercial Factory" &&
                    propertyData.propertyType !== "Commercial Warehouse" ? (
                      <div className=" flex items-center  rounded-lg  text-md mt-4 lg:h-14 h-10 px-2  bg-orange-100">
                        <img src={area} alt="" className="h-5 w-5 mr-2"></img>
                        <p className="text-black lg:text-base text-xs">
                          {"Facing : "}
                          {propertyData.facing}
                          {""}
                        </p>
                      </div>
                    ) : null}

                    {/* Total Unit*/}
                    {propertyData.propertyType === "Apartment/Flats" ||
                    propertyData.propertyType === "Land Sharing Flats" ||
                    propertyData.propertyType === "Commercial Office" ||
                    propertyData.propertyType === "Commercial Showroom" ||
                    propertyData.propertyType === "Commercial Shop" ||
                    propertyData.propertyType === "Commercial Restaurant" ? (
                      <div className=" flex items-center  rounded-lg  text-md mt-4 lg:h-14 h-10 px-2 bg-blue-200">
                        <img src={area} alt="" className="h-5 w-5 mr-2"></img>
                        <p className="text-black lg:text-base text-xs">
                          {"Unit : "}
                          {propertyData.unit}
                          {""}
                        </p>
                      </div>
                    ) : null}

                    {/* Total floor*/}
                    {propertyData.propertyType !== "Commercial Factory" &&
                    propertyData.propertyType !== "Commercial Warehouse" ? (
                      <div className=" flex items-center  rounded-lg  text-md mt-4 lg:h-14 h-10 px-2 bg-green-200">
                        <img src={area} alt="" className="h-5 w-5 mr-2"></img>
                        <p className="text-black lg:text-base text-xs">
                          {"Total Floor : "}
                          {propertyData.totalFloor}
                          {""}
                        </p>
                      </div>
                    ) : null}

                    {/* Total available floor*/}
                    {propertyData.availableFloor ? (
                      <>
                        {propertyData.propertyType === "Apartment/Flats" ||
                        propertyData.propertyType === "Land Sharing Flats" ||
                        propertyData.propertyType === "Commercial Office" ||
                        propertyData.propertyType === "Commercial Showroom" ||
                        propertyData.propertyType === "Commercial Shop" ||
                        propertyData.propertyType ===
                          "Commercial Restaurant" ? (
                          <div
                            className={`flex items-center rounded-lg text-md mt-4 lg:h-14 h-10 px-2 bg-red-200 relative ${
                              propertyData.availableFloor.split(",").length > 5
                                ? "group"
                                : ""
                            }`}
                            title={
                              propertyData.availableFloor.split(",").length > 5
                                ? propertyData.availableFloor
                                : ""
                            }
                          >
                            <img src={area} alt="" className="h-5 w-5 mr-2" />
                            <p className="text-black lg:text-base text-xs truncate">
                              {"Available Floor : "}
                              {propertyData.availableFloor.split(",").length > 5
                                ? propertyData.availableFloor
                                    .split(",")
                                    .slice(0, 5)
                                    .join(",") + "..."
                                : propertyData.availableFloor}
                            </p>
                            {propertyData.availableFloor.split(",").length >
                              5 && (
                              <div className="absolute left-0 bottom-full mt-2 p-2 bg-red-200 shadow-lg rounded-lg hidden group-hover:block z-10">
                                {propertyData.availableFloor}
                              </div>
                            )}
                          </div>
                        ) : null}
                      </>
                    ) : null}
                  </div>
                ) : (
                  <div className="flex flex-wrap space-x-4">
                    <div className="Bathroom flex items-center  rounded-lg  text-md mt-4 lg:h-14 h-10 px-2 bg-white">
                      <img src={area} alt="" className="h-5 w-5 mr-2"></img>
                      <p className="text-blue-800 lg:text-base text-xs">
                        {propertyData.size} {" ft² Size"}
                      </p>
                    </div>

                    <div className="Bathroom flex items-center  rounded-lg  text-md mt-4 lg:h-14 h-10 px-2 bg-white">
                      <img src={area} alt="" className="h-5 w-5 mr-2"></img>
                      <p className="text-blue-800 lg:text-base text-xs">
                        {propertyData.landSize}
                        {" Land Size"}
                      </p>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>

          {/* Other facility 2.0*/}
          <div
            className={` w-full flex justify-center items-center duration-200  `}
          >
            <div className=" w-[98%] md:w-[90%] xl:w-[85%]  duration-200 ">
              <div className="mt-3 bg-slate-100 p-5  rounded-2xl">
                <div className="">
                  <p className="2xl:text-3xl xl:text-xl text-lg font-bold">
                    {" "}
                    {hasOtherFacility ? <div>Others facility</div> : null}
                  </p>

                  <div className="grid  xl:grid-cols-6 grid-cols-2 lg:grid-cols-4 gap-2 ">
                    {propertyData.propertyType !== "Commercial Factory" &&
                    propertyData.propertyType !== "Commercial Warehouse" &&
                    propertyData.propertyType !== "Land" ? (
                      <>
                        {propertyData.mosque === true ? (
                          <div className="construction mr-2 flex items-center  rounded-lg text-md mt-4 lg:h-14 h-10 px-2 bg-white">
                            <img
                              src={check}
                              className="lg:h-5 h-3 lg:w-5 w-3 mr-2"
                              alt="Mosque"
                            ></img>
                            <p className="text-black lg:text-base text-xs">
                              Mosque
                            </p>
                          </div>
                        ) : null}

                        {propertyData.security === true ? (
                          <div className="construction mr-2 flex items-center  rounded-lg text-md mt-4 lg:h-14 h-10 px-2 bg-white">
                            <img
                              src={check}
                              className="lg:h-5 h-3 lg:w-5 w-3 mr-2"
                              alt="security"
                            ></img>
                            <p className="text-black lg:text-base text-xs">
                              Security
                            </p>
                          </div>
                        ) : null}

                        {propertyData.fireExit === true ? (
                          <div className="construction mr-2 flex items-center  rounded-lg text-md mt-4 lg:h-14 h-10 px-2 bg-white">
                            <img
                              src={check}
                              className="lg:h-5 h-3 lg:w-5 w-3 mr-2"
                              alt="fireExit"
                            ></img>
                            <p className="text-black lg:text-base text-xs">
                              Fire Exit
                            </p>
                          </div>
                        ) : null}

                        {propertyData.fireProtection === true ? (
                          <div className="construction mr-2 flex items-center  rounded-lg text-md mt-4 lg:h-14 h-10 px-2 bg-white">
                            <img
                              src={check}
                              className="lg:h-5 h-3 lg:w-5 w-3 mr-2"
                              alt="fireProtection"
                            ></img>
                            <p className="text-black lg:text-base text-xs">
                              Fire protection
                            </p>
                          </div>
                        ) : null}

                        {propertyData.securityAlarm === true ? (
                          <div className="construction mr-2 flex items-center  rounded-lg text-md mt-4 lg:h-14 h-10 px-2 bg-white">
                            <img
                              src={check}
                              className="lg:h-5 h-3 lg:w-5 w-3 mr-2"
                              alt=" Security Alarm system"
                            ></img>
                            <p className="text-black lg:text-base text-xs">
                              {" "}
                              Security Alarm system
                            </p>
                          </div>
                        ) : null}

                        {propertyData.electronicSecurity === true ? (
                          <div className="construction mr-2 flex items-center  rounded-lg text-md mt-4 lg:h-14 h-10 px-2 bg-white">
                            <img
                              src={check}
                              className="lg:h-5 h-3 lg:w-5 w-3 mr-2"
                              alt="Electronic security"
                            ></img>
                            <p className="text-black lg:text-base text-xs">
                              Electronic security
                            </p>
                          </div>
                        ) : null}

                        {propertyData.conversation === true ? (
                          <div className="construction mr-2 flex items-center  rounded-lg text-md mt-4 lg:h-14 h-10 px-2 bg-white">
                            <img
                              src={check}
                              className="lg:h-5 h-3 lg:w-5 w-3 mr-2"
                              alt="Conversation Room"
                            ></img>
                            <p className="text-black lg:text-base text-xs">
                              Conversation Room
                            </p>
                          </div>
                        ) : null}

                        {propertyData.propertyType === "Apartment/Flats" ||
                        propertyData.propertyType === "Land Sharing Flats" ||
                        propertyData.propertyType === "Commercial Office" ||
                        propertyData.propertyType === "Duplex Home" ||
                        propertyData.propertyType ===
                          "Commercial Restaurant" ? (
                          <>
                            {propertyData.wasaConnection === true ? (
                              <div className="construction mr-2 flex items-center  rounded-lg text-md mt-4 lg:h-14 h-10 px-2 bg-white">
                                <img
                                  src={check}
                                  className="lg:h-5 h-3 lg:w-5 w-3 mr-2"
                                  alt=" Wasa connection"
                                ></img>
                                <p className="text-black lg:text-base text-xs">
                                  {" "}
                                  Wasa connection
                                </p>
                              </div>
                            ) : null}

                            {propertyData.hotWater === true ? (
                              <div className="construction mr-2 flex items-center  rounded-lg text-md mt-4 lg:h-14 h-10 px-2 bg-white">
                                <img
                                  src={check}
                                  className="lg:h-5 h-3 lg:w-5 w-3 mr-2"
                                  alt="Hot water"
                                ></img>
                                <p className="text-black lg:text-base text-xs">
                                  Hot water
                                </p>
                              </div>
                            ) : null}

                            {propertyData.selfWaterSupply === true ? (
                              <div className="construction mr-2 flex items-center  rounded-lg text-md mt-4 lg:h-14 h-10 px-2 bg-white">
                                <img
                                  src={check}
                                  className="lg:h-5 h-3 lg:w-5 w-3 mr-2"
                                  alt="selfWaterSupply"
                                ></img>
                                <p className="text-black lg:text-base text-xs">
                                  Self Water Supply
                                </p>
                              </div>
                            ) : null}

                            {propertyData.cylinderGas === true ? (
                              <div className="construction mr-2 flex items-center  rounded-lg text-md mt-4 lg:h-14 h-10 px-2 bg-white">
                                <img
                                  src={check}
                                  className="lg:h-5 h-3 lg:w-5 w-3 mr-2"
                                  alt="Cylinder gas"
                                ></img>
                                <p className="text-black lg:text-base text-xs">
                                  Cylinder gas
                                </p>
                              </div>
                            ) : null}

                            {propertyData.gymnasium === true ? (
                              <div className="construction mr-2 flex items-center  rounded-lg text-md mt-4 lg:h-14 h-10 px-2 bg-white">
                                <img
                                  src={check}
                                  className="lg:h-5 h-3 lg:w-5 w-3 mr-2"
                                  alt="Gymnasium"
                                ></img>
                                <p className="text-black lg:text-base text-xs">
                                  Gymnasium
                                </p>
                              </div>
                            ) : null}

                            {propertyData.pool === true ? (
                              <div className="construction mr-2 flex items-center  rounded-lg text-md mt-4 lg:h-14 h-10 px-2 bg-white">
                                <img
                                  src={check}
                                  className="lg:h-5 h-3 lg:w-5 w-3 mr-2"
                                  alt="Pool"
                                ></img>
                                <p className="text-black lg:text-base text-xs">
                                  Pool
                                </p>
                              </div>
                            ) : null}

                            {propertyData.garden === true ? (
                              <div className="construction mr-2 flex items-center  rounded-lg text-md mt-4 lg:h-14 h-10 px-2 bg-white">
                                <img
                                  src={check}
                                  className="lg:h-5 h-3 lg:w-5 w-3 mr-2"
                                  alt="Garden"
                                ></img>
                                <p className="text-black lg:text-base text-xs">
                                  Garden
                                </p>
                              </div>
                            ) : null}
                          </>
                        ) : null}

                        {propertyData.generator === true ? (
                          <div className="construction mr-2 flex items-center  rounded-lg text-md mt-4 lg:h-14 h-10 px-2 bg-white">
                            <img
                              src={check}
                              className="lg:h-5 h-3 lg:w-5 w-3 mr-2"
                              alt="Generator"
                            ></img>
                            <p className="text-black lg:text-base text-xs">
                              Generator
                            </p>
                          </div>
                        ) : null}

                        {propertyData.intercom === true ? (
                          <div className="construction mr-2 flex items-center  rounded-lg text-md mt-4 lg:h-14 h-10 px-2 bg-white">
                            <img
                              src={check}
                              className="lg:h-5 h-3 lg:w-5 w-3 mr-2"
                              alt="Intercom"
                            ></img>
                            <p className="text-black lg:text-base text-xs">
                              Intercom
                            </p>
                          </div>
                        ) : null}

                        {propertyData.wifiConnection === true ? (
                          <div className="construction mr-2 flex items-center  rounded-lg text-md mt-4 lg:h-14 h-10 px-2 bg-white">
                            <img
                              src={check}
                              className="lg:h-5 h-3 lg:w-5 w-3 mr-2"
                              alt="Wi-Fi connection"
                            ></img>
                            <p className="text-black lg:text-base text-xs">
                              Wi-Fi connection
                            </p>
                          </div>
                        ) : null}

                        {propertyData.satelliteCableTv === true ? (
                          <div className="construction mr-2 flex items-center  rounded-lg text-md mt-4 lg:h-14 h-10 px-2 bg-white">
                            <img
                              src={check}
                              className="lg:h-5 h-3 lg:w-5 w-3 mr-2"
                              alt=" Satellite or cable TV"
                            ></img>
                            <p className="text-black lg:text-base text-xs">
                              {" "}
                              Satellite or cable TV
                            </p>
                          </div>
                        ) : null}

                        {propertyData.solarPanel === true ? (
                          <div className="construction mr-2 flex items-center  rounded-lg text-md mt-4 lg:h-14 h-10 px-2 bg-white">
                            <img
                              src={check}
                              className="lg:h-5 h-3 lg:w-5 w-3 mr-2"
                              alt="Solar panel"
                            ></img>
                            <p className="text-black lg:text-base text-xs">
                              Solar panel
                            </p>
                          </div>
                        ) : null}

                        {propertyData.servantRoom === true ? (
                          <div className="construction mr-2 flex items-center  rounded-lg text-md mt-4 lg:h-14 h-10 px-2 bg-white">
                            <img
                              src={check}
                              className="lg:h-5 h-3 lg:w-5 w-3 mr-2"
                              alt="Servant room"
                            ></img>
                            <p className="text-black lg:text-base text-xs">
                              Servant room
                            </p>
                          </div>
                        ) : null}

                        {propertyData.guestParking === true ? (
                          <div className="construction mr-2 flex items-center  rounded-lg text-md  mt-4 lg:h-14 h-10 px-2 bg-white">
                            <img
                              src={check}
                              className="lg:h-5 h-3 lg:w-5 w-3 mr-2"
                              alt="Guest parking"
                            ></img>
                            <p className="text-black lg:text-base text-xs">
                              Guest parking
                            </p>
                          </div>
                        ) : null}
                      </>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="w-full h-[90vh] flex justify-center">
          <div className="w-[70%]">
            <PostLoading />
          </div>
        </div>
      )}
    </div>
  );
};

export default PropertyRentDetails;
